<template>
  <div class="my-1">
    <el-select
      id="select-collaborator"
      :modelValue="collaborator"
      value-key="id"
      @update:modelValue="collaboratorChange"
      class="select-info col-md-5 px-1 select-one-line-per-item"
      size="large"
      placeholder="Collaborateur"
      filterable
    >
      <el-option
        v-for="c in collaboratorsName"
        :key="c.id"
        :label="`${c.lastname} ${c.firstname}`"
        :value="c"
      >
        <el-tooltip v-if="!c.isActive" content="Inactif" placement="bottom">
          <i class="fa fa-user-times"></i>
        </el-tooltip>
        <span> {{ c.lastname }} {{ c.firstname }}</span>
      </el-option>
    </el-select>
    <el-select
      id="select-access"
      :modelValue="accessRight"
      @update:modelValue="accessChange"
      class="select-info col-md-5 px-1 select-one-line-per-item"
      size="large"
      placeholder="Droit d'accès"
      filterable
    >
      <el-option key="read" label="Lecture" value="READ" />
      <el-option key="modification" label="Modification" value="MODIFICATION" />
    </el-select>
    <button
      class="my-0 btn btn-icon btn-round btn-danger btn-crud-select"
      type="button"
      title="Supprimer l'accès"
      @click="$emit('deleteAccess', position)"
    >
      <i class="fa fa-trash" />
    </button>
  </div>
</template>

<script>
export default {
  props: {
    collaboratorsName: {
      type: Array,
      required: true,
    },
    position: {
      type: Number,
      required: true,
    },
    accessRight: {
      type: String,
      required: true,
    },
    collaborator: {
      type: Object,
    },
  },
  methods: {
    collaboratorChange(value) {
      this.$emit('collaboratorChange', {
        value,
        position: this.position,
      });
    },
    accessChange(value) {
      this.$emit('accessChange', {
        value,
        position: this.position,
      });
    },
  },
};
</script>
