<template>
  <div class="my-1">
    <el-select
      id="select-distributionList"
      :modelValue="distributionList"
      value-key="id"
      @update:modelValue="distributionListChange"
      class="select-info col-md-5 px-1 select-one-line-per-item"
      size="large"
      placeholder="Dossier"
      :disabled="disabled"
      filterable
    >
      <el-option
        v-for="dl in distributionLists"
        :key="dl.id"
        :label="`${dl.name}`"
        :value="dl"
      />
    </el-select>
    <button
      class="my-0 btn btn-icon btn-round btn-danger btn-crud-select"
      type="button"
      title="Supprimer la liste de distribution"
      :disabled="disabled"
      @click="$emit('deleteDistributionList', position)"
    >
      <i class="fa fa-trash" />
    </button>
  </div>
</template>

<script>
export default {
  props: {
    position: {
      type: Number,
      required: true,
    },
    distributionList: {
      type: Object,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    distributionLists: {
      type: Array,
      required: true,
    },
  },
  methods: {
    distributionListChange(value) {
      this.$emit('distributionListChange', {
        value,
        position: this.position,
      });
    },
  },
};
</script>
