<template>
  <div>
    <h4>
      {{ faqItem.question }}
    </h4>
    {{ faqItem.answer }}
  </div>
</template>

<script>
export default {
  props: {
    faqItem: Object,
  },
};
</script>
