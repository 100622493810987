<template>
  <div>
    <div v-if="loading">
      <Loader />
    </div>
    <div v-else>
      <div class="row">
        <div class="col-md-12 top-buttons action-container">
          <button
            v-if="isAdmin"
            type="submit"
            :class="buttonClassObject"
            class="btn btn-fill btn-wd"
            @click.prevent="editButtonClicked"
          >
            <a v-if="editingDisabled">Editer</a>
            <a v-else>Enregister</a>
          </button>
          <button
            v-show="!editingDisabled"
            type="warning"
            class="btn btn-warning btn-fill btn-wd"
            @click.prevent="cancelButtonClicked"
          >
            Annuler
          </button>
          <button
            v-show="!editingDisabled"
            type="danger"
            class="btn btn-danger btn-fill btn-wd"
            @click.prevent="deleteMaterial"
          >
            Effacer
          </button>
          <div class="navigation-container">
            <button
              class="btn btn-icon btn-round mr-1"
              type="button"
              title="prevCollaborator"
              :disabled="!hasPrevMaterial"
              @click.prevent="goPrevMaterial"
            >
              <i class="fa fa-chevron-left" />
            </button>
            <button
              class="btn btn-icon btn-round ml-1"
              type="button"
              :disabled="!hasNextMaterial"
              title="nextCollaborator"
              @click.prevent="goNextMaterial"
            >
              <i class="fa fa-chevron-right" />
            </button>
          </div>
          <p class="error-message" v-if="errorMessage">
            {{ errorMessage }}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 action-container mt-2 mb-3">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ name: this.path }"
              >Matériaux
            </el-breadcrumb-item>
            <el-breadcrumb-item> {{ material.id }}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5 class="title">Données du matériel</h5>
            </div>
            <div class="card-body">
              <Form :validation-schema="validationSchema" validate-on-mount>
                <div class="row">
                  <div class="form-group col-md-6">
                    <label class="control-label">*Identifiant</label>
                    <Field
                      name="identifier"
                      v-model="material.identifier"
                      v-slot="{ field, errorMessage }"
                    >
                      <input
                        v-bind="field"
                        class="form-control"
                        type="text"
                        :disabled="editingDisabled"
                      />
                      <small v-show="errorMessage" class="text-danger">
                        L'identifiant est requis.
                      </small>
                    </Field>
                  </div>
                  <div class="col-md-6">
                    <label class="control-label">Responsable</label>
                    <el-select
                      id="select-owners"
                      v-model="material.collaboratorId"
                      class="select-info col-md-12 select-one-line-per-item"
                      size="large"
                      placeholder="Sélectionner"
                      @change="handleOwnerChange"
                      :disabled="editingDisabled"
                    >
                      <el-option
                        v-for="c in collaborators"
                        :key="c.id"
                        :label="`${c.lastname} ${c.firstname}`"
                        :value="c.id"
                      />
                    </el-select>
                  </div>
                  <div class="col-md-6 file-input-container">
                    <label class="control-label">Mode d'emploi</label>
                    <div class="manual-actions">
                      <input
                        type="file"
                        name="identifier"
                        class="file-input"
                        v-if="!editingDisabled"
                        accept="application/pdf"
                        @change="setManual($event)"
                      />
                      <el-button
                        v-if="editingDisabled"
                        :disabled="!haveManual"
                        type="primary"
                        :icon="ElIconDownload"
                        @click="downloadManual($event)"
                      >
                        Télécharger
                      </el-button>
                    </div>
                  </div>
                  <div v-if="isComputer" class="col-md-6">
                    <label class="control-label">*Type</label>
                    <el-select
                      id="select-type"
                      v-model="material.subType"
                      class="select-info col-md-12 select-one-line-per-item"
                      size="large"
                      placeholder="Sélectionner"
                      required="true"
                      name="typeInput"
                      :disabled="editingDisabled"
                    >
                      <el-option
                        v-for="t in subTypes"
                        :key="t.value"
                        :label="`${t.name}`"
                        :value="t.value"
                      />
                    </el-select>
                    <small v-show="typeInput.invalid" class="text-danger">
                      Le type est requis.
                    </small>
                  </div>
                  <div class="form-group col-md-6">
                    <label class="control-label">Modèle</label>
                    <input
                      v-model="material.modal"
                      class="form-control"
                      type="text"
                      name="modal"
                      :disabled="editingDisabled"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label class="control-label">Marque</label>
                    <input
                      v-model="material.brand"
                      class="form-control"
                      type="text"
                      name="brand"
                      :disabled="editingDisabled"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label class="control-label">Prix (unitaire)</label>
                    <el-input-number
                      v-model="material.unitPrice"
                      :disabled="editingDisabled"
                      :precision="2"
                      :min="0"
                    ></el-input-number>
                  </div>
                  <div class="form-group col-md-6">
                    <label class="control-label">Date d'achat</label>
                    <el-date-picker
                      id="purchaseDate"
                      v-model="material.purchaseDate"
                      type="date"
                      placeholder="Choisissez un jour"
                      :disabled="editingDisabled"
                    />
                  </div>
                  <div v-if="isComputerOrServer" class="form-group col-md-6">
                    <label class="control-label">Système d'exploitation</label>
                    <input
                      v-model="material.operatingSystem"
                      class="form-control"
                      type="text"
                      name="operatingSystem"
                      :disabled="editingDisabled"
                    />
                  </div>
                  <div
                    v-if="!isHeadsetOrDictaphoneOrDevice"
                    class="form-group col-md-6"
                  >
                    <label class="control-label">Addresse IP</label>
                    <input
                      v-model="material.ip"
                      class="form-control"
                      type="text"
                      name="ip"
                      :disabled="editingDisabled"
                    />
                  </div>
                  <div v-if="isComputer" class="col-md-6">
                    <label class="control-label">Statut</label>
                    <el-select
                      id="select-type"
                      v-model="material.status"
                      class="select-info col-md-12 select-one-line-per-item"
                      size="large"
                      placeholder="Sélectionner"
                      required="true"
                      name="status"
                      :disabled="editingDisabled"
                      @input="handleStatusChange"
                    >
                      <el-option
                        v-for="s in status"
                        :key="s.value"
                        :label="`${s.name}`"
                        :value="s.value"
                      />
                    </el-select>
                    <small v-show="statusInput.invalid" class="text-danger">
                      Il n'est pas possible d'avoir un propriétaire et de ne pas
                      être assigné.
                    </small>
                  </div>
                  <div class="form-group col-md-6">
                    <label class="control-label">Remarque</label>
                    <textarea
                      v-model="material.remark"
                      class="form-control"
                      type="textarea"
                      name="remark"
                      :disabled="editingDisabled"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label class="control-label">Description</label>
                    <textarea
                      v-model="material.description"
                      class="form-control"
                      name="description"
                      :disabled="editingDisabled"
                    />
                  </div>

                  <div class="form-group col-md-6">
                    <label class="control-label">Localisation</label>
                    <input
                      v-model="material.location"
                      class="form-control"
                      type="text"
                      name="location"
                      :disabled="editingDisabled"
                    />
                  </div>
                  <div class="col-md-6">
                    <label class="control-label">Fournisseur</label>
                    <el-select
                      id="select-type"
                      v-model="material.providerId"
                      class="select-info col-md-12 select-one-line-per-item"
                      size="large"
                      placeholder="Sélectionner"
                      required="true"
                      name="provider"
                      filterable
                      clearable
                      :disabled="editingDisabled"
                    >
                      <el-option
                        v-for="c in providers"
                        :key="c.id"
                        :label="`${c.name}`"
                        :value="c.id"
                      />
                    </el-select>
                  </div>
                </div>
                <p><label>* champs obligatoires</label></p>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Download as ElIconDownload } from '@element-plus/icons-vue';
import { mapGetters } from 'vuex';
import {
  ElDatePicker as DatePicker,
  ElOptionGroup as OptionGroup,
  ElRow as Row,
  ElCol as Col,
  ElButton as Button,
  ElBreadcrumb as Breadcrumb,
  ElBreadcrumbItem as BreadcrumbItem,
  ElInputNumber as InputNumber,
} from 'element-plus';
import { Form, Field } from 'vee-validate';
import Swal from 'sweetalert2';
import * as yup from 'yup';
import MixinFetchData from '@/mixins/mixinFetchData';
import MixinProcessImage from '@/mixins/mixinProcessImage';
import {
  MATERIALS_SUBTYPES_ARRAY,
  MATERIALS_STATUS_ARRAY,
  MATERIALS_STATUS,
  MATERIALS_TYPES,
} from '@/enums/MaterialsTypes';
import ROLES from '@/services/Roles';
import Loader from './UIComponents/Loader.vue';

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [OptionGroup.name]: OptionGroup,
    [Row.name]: Row,
    [Col.name]: Col,
    [Button.name]: Button,
    [Breadcrumb.name]: Breadcrumb,
    [BreadcrumbItem.name]: BreadcrumbItem,
    [InputNumber.name]: InputNumber,
    Loader,
    Form,
    Field,
  },
  mixins: [MixinFetchData, MixinProcessImage],
  data() {
    return {
      material: {},
      collaborators: [],
      materialId: 0,
      editingDisabled: true,
      errorMessage: '',
      validationSchema: {
        identifier: yup.string().required(),
      },
      subTypes: MATERIALS_SUBTYPES_ARRAY,
      status: MATERIALS_STATUS_ARRAY,
      typeInput: {
        invalid: false,
      },
      statusInput: {
        invalid: false,
      },
      type: '',
      nextMaterial: null,
      prevMaterial: null,
      loading: false,
      providers: [],
      ElIconDownload,
    };
  },
  created() {
    this.initData();
  },
  watch: {
    $route: 'initData',
  },
  computed: {
    ...mapGetters('materials', ['page']),
    buttonClassObject() {
      return {
        'btn-warning': this.editingDisabled,
        'btn-success': !this.editingDisabled,
      };
    },
    isAdmin() {
      return (
        this.$store.getters['account/roles'].includes(ROLES.ADMINISTRATOR)
        || this.$store.getters['account/roles'].includes(ROLES.POWER_USER)
      );
    },
    isComputer() {
      return this.type === MATERIALS_TYPES.computer.value;
    },
    isServer() {
      return this.type === MATERIALS_TYPES.server.value;
    },
    isRouter() {
      return this.type === MATERIALS_TYPES.router.value;
    },
    isComputerOrServer() {
      return this.isComputer || this.isServer;
    },
    isServerOrRouter() {
      return this.isRouter || this.isServer;
    },
    isDictaphone() {
      return this.type === MATERIALS_TYPES.dictaphone.value;
    },
    isHeadsetOrDictaphoneOrDevice() {
      return (
        this.type === MATERIALS_TYPES.headset.value
        || this.type === MATERIALS_TYPES.device.value
        || this.isDictaphone
      );
    },
    haveManual() {
      return this.material.manual;
    },
    hasNextMaterial() {
      return this.nextMaterial
        ? this.nextMaterial.id !== this.materialId
        : false;
    },
    hasPrevMaterial() {
      return this.prevMaterial
        ? this.prevMaterial.id !== this.materialId
        : false;
    },
    path() {
      return this.$route.query.path;
    },
    urlNextMaterial() {
      return `/materials/${this.nextMaterial.id}?type=${this.type}&path=${this.path}`;
    },
    urlPrevMaterial() {
      return `/materials/${this.prevMaterial.id}?type=${this.type}&path=${this.path}`;
    },
  },
  methods: {
    async initData() {
      this.type = this.$route.query.type;
      this.materialId = parseInt(this.$route.params.id, 10);
      this.fetchData();
      this.$store
        .dispatch('materials/getNextMaterial', {
          materialId: this.materialId,
          type: this.type,
          projection: 'materialId',
        })
        .then((nextMat) => {
          this.nextMaterial = nextMat;
        });
      this.$store
        .dispatch('materials/getPrevMaterial', {
          materialId: this.materialId,
          type: this.type,
          projection: 'materialId',
        })
        .then((prevMat) => {
          this.prevMaterial = prevMat;
        });
    },
    fetchData() {
      this.fetchMaterialById();
      this.fetchProviders();
      this.fetchCollaborators();
    },
    fetchMaterialById() {
      this.loading = true;
      this.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/materials/${this.materialId}?projection=materialSummary`,
        )
        .then(async (response) => {
          if (response.data) {
            this.material = {
              ...response.data,
              collaboratorId: response.data.collaborator
                ? response.data.collaborator.id
                : null,
              providerId: response.data.provider
                ? response.data.provider.id
                : null,
            };
          }
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false;
        });
    },
    fetchCollaborators() {
      this.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/collaborators/search/findAllOrderByLastAndFirstname?projection=collaboratorName`,
        )
        .then((response) => {
          if (response.data) {
            this.collaborators = response.data._embedded.collaborators;
          }
        })
        .catch((error) => {});
    },
    editButtonClicked() {
      if (!this.editingDisabled) {
        this.updateMaterial();
      } else {
        this.editingDisabled = false;
      }
    },
    cancelButtonClicked() {
      this.editingDisabled = true;
      this.fetchMaterialById();
    },
    async updateMaterial() {
      // Validate each field with vee-validate, and update the material.
      const validationSchemaObject = yup.object(this.validationSchema);
      const isMaterialValid = await validationSchemaObject.isValid(
        this.material,
      );

      if (isMaterialValid && this.validateCustum()) {
        this.errorMessage = null;

        const formData = new FormData();
        formData.append('manual', this.material.manual);
        // Add the properties as application/json content type in order to unserialize them in the server.
        formData.append(
          'properties',
          new Blob(
            [
              JSON.stringify({
                ...this.material,
              }),
            ],
            {
              type: 'application/json',
            },
          ),
        );

        this.$http
          .patch(
            `${process.env.VUE_APP_INTRANET_API_URL}/commands/materials/${this.material.id}`,
            formData,
          )
          .then(() => {
            this.editingDisabled = true;
            this.fetchData();
          })
          .catch((error) => {
            this.errorMessage = 'Une erreur est survenue lors de la modification du matériel, veuillez réessayer ou contacter le webmaster.';
          });
      } else {
        this.errorMessage = 'Un ou plusieurs champs sont invalides.';
      }
    },
    deleteMaterial() {
      this.errorMessage = null;

      Swal.fire({
        title: 'Êtes-vous sûr de vouloir supprimer ce matériel ?',
        text: "Il ne sera pas possible d'annuler cette suppression !",
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill',
        },
        confirmButtonText: 'Oui, effacer',
        cancelButtonText: 'Non, conserver',
        buttonsStyling: false,
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.$http
              .delete(
                `${process.env.VUE_APP_INTRANET_API_URL}/commands/materials/${this.material.id}`,
              )
              .then(() => {
                this.$notify({
                  title: 'Suppression',
                  text: 'Suppression du matériel avec succès',
                  type: 'success',
                });
                this.$router.push({ name: this.path });
              })
              .catch((error) => {
                this.errorMessage = 'Une erreur est survenue lors de la suppression du matériel, veuillez réessayer ou contacter le webmaster.';
              });
          }
        })
        .catch(() => {});
    },
    validateCustum() {
      let error = false;
      if (this.isComputer) {
        // subType obligatory
        if (!this.material.subType) {
          this.typeInput.invalid = true;
          error = true;
        }
        if (
          this.material.collaboratorId
          && this.material.status !== MATERIALS_STATUS.assigned.value
        ) {
          this.statusInput.invalid = true;
          error = true;
        }
        if (error) {
          this.errorMessage = 'Un ou plusieurs champs sont invalides.';
          return false;
        }
      }
      this.typeInput.invalid = false;
      this.typeInput.invalid = false;
      return true;
    },
    setSelectedTypeId(typeId) {
      this.material.typeId = typeId;
    },
    fetchTypes() {
      return MATERIALS_SUBTYPES_ARRAY;
    },
    createType(data) {
      return this.$http.post(
        `${process.env.VUE_APP_INTRANET_API_URL}/materialsTypes`,
        {
          name: data,
        },
      );
    },
    deleteType(typeId) {
      return this.$http.delete(
        `${process.env.VUE_APP_INTRANET_API_URL}/materialsTypes/${typeId}`,
      );
    },
    handleOwnerChange(event) {
      if (this.isComputer && this.material.collaboratorId) {
        this.material.status = MATERIALS_STATUS.assigned.value;
      }
    },
    handleStatusChange(status) {
      if (
        (status === MATERIALS_STATUS.not_assigned.value
          || status === MATERIALS_STATUS.reservable.value)
        && this.material.collaboratorId !== null
      ) {
        this.material.collaboratorId = null;
      }
    },
    setManual(event) {
      if (event.target.files[0]) {
        [this.material.manual] = event.target.files;
      }
    },
    downloadManual(event) {
      this.downloadDocument('materials/manuals/', this.material.manual, event);
    },
    async goPrevMaterial() {
      if (this.prevMaterial.isOnNewPage) {
        await this.$store.dispatch('materials/fetchMaterialsPage', {
          pageNumber: this.page.number - 1,
          pageSize: this.page.size,
          type: this.type,
          projection: 'materialId',
        });
      }
      this.$router.push(this.urlPrevMaterial);
    },
    async goNextMaterial() {
      if (this.nextMaterial.isOnNewPage) {
        await this.$store.dispatch('materials/fetchMaterialsPage', {
          pageNumber: this.page.number + 1,
          pageSize: this.page.size,
          type: this.type,
          projection: 'materialId',
        });
      }
      this.$router.push(this.urlNextMaterial);
    },
    fetchProviders() {
      this.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/enterprises/search/findAll`,
        )
        .then((response) => {
          this.providers = response.data._embedded.enterprises
            ? response.data._embedded.enterprises
            : [];
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.top-buttons {
  display: flex;
  margin-top: -30px;
  margin-bottom: 0px;
}

.navigation-container {
  margin: 0 1rem;
}

@media screen and (max-width: $mobile-max-width) {
  .top-buttons {
    flex-direction: column;
  }
  .action-container {
    margin: 0.5em $mobile-button-margin-x;

    button {
      margin: 0.25em 0;
    }
  }
  .navigation-container {
    margin-top: 0.25rem;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }
}
</style>

<style lang="scss">
.file-input-container {
  display: flex;
  flex-direction: column;
}

.manual-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.birthday-input.is-disabled input,
.birthday-input.is-disabled i {
  background-color: #e3e3e3 !important;
  color: #66615b !important;
}
</style>
