<template>
  <el-row :gutter="20">
    <!-- morning -->
    <el-col :span="24" :md="12">
      <el-row>
        <el-col :span="24">
          <label>Matin</label>
        </el-col>
        <!-- if FREE -->
        <el-col v-if="morningFree && editingDisabled" :span="24">
          Congé
        </el-col>
        <!-- HAVE A SCHEDULE -->
        <el-col v-else :span="24">
          <el-row>
            <el-col :span="24">
              <el-time-picker
                is-range
                :disabled="editingDisabled || morningFree"
                class="time-picker"
                v-model="schedule.morning"
                :picker-options="{
                  format: 'HH:mm',
                  step: '00:15',
                }"
                range-separator="à"
                start-placeholder="Heure de début"
                end-placeholder="Heure de fin"
                @change="handleScheduleChangeMorning"
              >
              </el-time-picker>
            </el-col>
            <el-col :span="24">
              <el-switch
                v-if="!editingDisabled"
                v-model="morningFree"
                active-text="Congé"
                @change="handleMorningFreeChange"
              >
              </el-switch>
            </el-col>
            <el-col :span="24">
              <fg-input
                v-model="schedule.morningComment"
                :disabled="editingDisabled"
                type="text"
                label="Commentaire - matin"
                @change="handleScheduleCommentChangeMorning"
              />
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-col>
    <!-- afernoon -->
    <el-col :span="24" :md="12">
      <el-row>
        <el-col :span="24">
          <label>Après-midi</label>
        </el-col>
        <!-- if FREE -->
        <el-col v-if="afternoonFree && editingDisabled" :span="24">
          Congé
        </el-col>
        <!-- if have Schedule -->
        <el-col v-else :span="24">
          <el-row>
            <el-col :span="24">
              <el-time-picker
                is-range
                :disabled="editingDisabled || afternoonFree"
                v-model="schedule.afternoon"
                class="time-picker"
                :picker-options="{
                  format: 'HH:mm',
                  step: '00:15',
                }"
                range-separator="à"
                start-placeholder="Heure de début"
                end-placeholder="Heure de fin"
                @change="handleScheduleChangeAfternoon"
              >
              </el-time-picker>
            </el-col>
            <el-col :span="24">
              <el-switch
                v-if="!editingDisabled"
                v-model="afternoonFree"
                active-text="Congé"
                @change="handleAfternoonFreeChange"
              >
              </el-switch>
            </el-col>
            <el-col :span="24">
              <fg-input
                v-model="schedule.afternoonComment"
                :disabled="editingDisabled"
                type="text"
                label="Commentaire - après-midi"
                @change="handleScheduleCommentChangeAfternoon"
              />
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>

<script>
import {
  ElTimePicker as TimePicker,
  ElRow as Row,
  ElCol as Col,
  ElSwitch as Switch,
} from 'element-plus';
import moment from 'moment';

export default {
  components: {
    [TimePicker.name]: TimePicker,
    [Row.name]: Row,
    [Col.name]: Col,
    [Switch.name]: Switch,
  },
  props: ['editingDisabled', 'scheduleProps', 'day'],
  watch: {
    scheduleProps(newVal, oldVal) {
      if (newVal) {
        this.schedule = this.formatScheduleIntoArray(newVal);
        this.morningFree = newVal.morningStart == null || newVal.morningEnd == null;
        this.afternoonFree = newVal.afternoonStart == null || newVal.afternoonEnd == null;
      }
    },
  },
  data() {
    return {
      schedule: this.formatScheduleIntoArray(this.scheduleProps),
      morningFree: this.scheduleProps.morningStart == null,
      afternoonFree: this.scheduleProps.afternoonStart == null,
    };
  },
  methods: {
    handleScheduleChangeMorning() {
      const scheduleReturn = this.scheduleProps;
      scheduleReturn.morningStart = this.schedule.morning
        ? this.fromDateJsToMoment(this.schedule.morning[0])
        : null;
      scheduleReturn.morningEnd = this.schedule.morning
        ? this.fromDateJsToMoment(this.schedule.morning[1])
        : null;
      this.$emit('scheduleChange', this.day, scheduleReturn);
    },
    handleScheduleCommentChangeMorning() {
      const scheduleReturn = this.scheduleProps;
      scheduleReturn.morningComment = this.schedule.morningComment;
      this.$emit('scheduleChange', this.day, scheduleReturn);
    },
    handleScheduleCommentChangeAfternoon() {
      const scheduleReturn = this.scheduleProps;
      scheduleReturn.afternoonComment = this.schedule.afternoonComment;
      this.$emit('scheduleChange', this.day, scheduleReturn);
    },
    handleScheduleChangeAfternoon() {
      const scheduleReturn = this.scheduleProps;
      scheduleReturn.afternoonStart = this.schedule.afternoon
        ? this.fromDateJsToMoment(this.schedule.afternoon[0])
        : null;
      scheduleReturn.afternoonEnd = this.schedule.afternoon
        ? this.fromDateJsToMoment(this.schedule.afternoon[1])
        : null;
      this.$emit('scheduleChange', this.day, scheduleReturn);
    },
    fromDateJsToMoment(date) {
      const hours = date.getHours().toString();
      const minutes = date.getMinutes().toString();
      const stringTime = hours.concat(':').concat(minutes);
      return moment(stringTime, 'HH:mm:ss');
    },
    formatScheduleIntoArray(schedule) {
      return {
        morning: schedule.morningStart
          ? [schedule.morningStart, schedule.morningEnd]
          : null,
        afternoon: schedule.afternoonStart
          ? [schedule.afternoonStart, schedule.afternoonEnd]
          : null,
        morningComment: schedule.morningComment,
        afternoonComment: schedule.afternoonComment,
      };
    },
    handleMorningFreeChange(isFree) {
      const scheduleReturn = this.scheduleProps;
      if (isFree) {
        scheduleReturn.morningStart = null;
        scheduleReturn.morningEnd = null;
        this.schedule.morning = null;
      } else {
        scheduleReturn.morningStart = moment('08:00:00', 'HH:mm:ss');
        scheduleReturn.morningEnd = moment('12:00:00', 'HH:mm:ss');
        this.schedule.morning = [
          scheduleReturn.morningStart,
          scheduleReturn.morningEnd,
        ];
      }
      this.$emit('scheduleChange', this.day, scheduleReturn);
    },
    handleAfternoonFreeChange(isFree) {
      const scheduleReturn = this.scheduleProps;
      if (isFree) {
        scheduleReturn.afternoonStart = null;
        scheduleReturn.afternoonEnd = null;
        this.schedule.afternoon = null;
      } else {
        scheduleReturn.afternoonStart = moment('13:00:00', 'HH:mm:ss');
        scheduleReturn.afternoonEnd = moment('18:00:00', 'HH:mm:ss');
        this.schedule.afternoon = [
          scheduleReturn.afternoonStart,
          scheduleReturn.afternoonEnd,
        ];
      }
      this.$emit('scheduleChange', this.day, scheduleReturn);
    },
  },
};
</script>

<style lang="scss" scroped>
.time-picker .el-range-input,
.time-picker .el-range__icon,
.time-picker .el-range-separator {
  color: #66615b !important;
}

.time-picker .el-range-separator {
  min-width: 1em;
}
</style>
