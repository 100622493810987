<template>
  <div>
    <el-row type="flex" justify="center">
      <el-col :xs="24" :sm="18" :md="16" :lg="12" :xl="12">
        <h2 class="title">{{ title }}</h2>
        <div v-for="faqItem in FAQ" :key="faqItem.question">
          <faq-item :faqItem="faqItem" />
          <hr />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { ElRow, ElCol } from 'element-plus';
import { faqInterflex } from '@/enums/FAQInterflex';
import FAQItem from '@/components/ApplicationViews/UIComponents/FAQItem.vue';

export default {
  components: {
    faqItem: FAQItem,
    [ElRow.name]: ElRow,
    [ElCol.name]: ElCol,
  },
  data() {
    return {
      title: 'FAQ Interflex',
      FAQ: faqInterflex,
    };
  },
};
</script>

<style lang="scss" scoped>
.title {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 40px;
}
</style>
