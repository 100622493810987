<template>
  <div v-if="!isNotRegister">
    <div v-if="schedule">
      <el-tabs v-model="activeDay">
        <el-tab-pane label="Lundi" name="first">
          <schedule-input
            :editingDisabled="editingDisabled"
            :scheduleProps="schedule.monday"
            day="monday"
            @scheduleChange="scheduleChange"
          />
        </el-tab-pane>
        <el-tab-pane label="Mardi" name="second">
          <schedule-input
            :editingDisabled="editingDisabled"
            :scheduleProps="schedule.tuesday"
            day="tuesday"
            @scheduleChange="scheduleChange"
          />
        </el-tab-pane>
        <el-tab-pane label="Mercredi" name="third">
          <schedule-input
            :editingDisabled="editingDisabled"
            :scheduleProps="schedule.wednesday"
            day="wednesday"
            @scheduleChange="scheduleChange"
          />
        </el-tab-pane>
        <el-tab-pane label="Jeudi" name="fourth">
          <schedule-input
            :editingDisabled="editingDisabled"
            :scheduleProps="schedule.thursday"
            day="thursday"
            @scheduleChange="scheduleChange"
          />
        </el-tab-pane>
        <el-tab-pane label="Vendredi" name="fifth">
          <schedule-input
            :editingDisabled="editingDisabled"
            :scheduleProps="schedule.friday"
            day="friday"
            @scheduleChange="scheduleChange"
          />
        </el-tab-pane>
      </el-tabs>
    </div>
    <div v-else>Chargement...</div>
  </div>
  <div v-else>
    <el-row>
      <el-col :span="24">
        <i>Aucun horaire enregistré</i>
      </el-col>
      <el-col :span="24" v-if="!editingDisabled">
        <p-button
          class="btn-round btn-add-schedule"
          type="primary"
          @click.prevent="addNewSchedule"
        >
          Ajouter un horaire
        </p-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {
  ElTabs as Tabs,
  ElTabPane as TabPane,
  ElRow as Row,
  ElCol as Col,
} from 'element-plus';
import ScheduleInput from './ScheduleInput.vue';

export default {
  components: {
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [Row.name]: Row,
    [Col.name]: Col,
    ScheduleInput,
  },
  props: ['editingDisabled', 'scheduleProps', 'isNotRegisterProps'],
  data() {
    return {
      activeDay: 'first',
      schedule: this.scheduleProps,
      isNotRegister: this.isNotRegisterProps,
    };
  },
  watch: {
    scheduleProps(newSchedule, oldSchedule) {
      this.schedule = newSchedule;
    },
    isNotRegisterProps(newIsNotRegister, oldIsNotRegister) {
      this.isNotRegister = newIsNotRegister;
    },
  },
  methods: {
    scheduleChange(day, scheduleDay) {
      this.schedule[day] = scheduleDay;
      this.$emit('scheduleChange', this.schedule);
    },
    addNewSchedule() {
      this.$emit('addNewSchedule');
    },
  },
};
</script>

<style lang="scss">
@media screen and (max-width: $mobile-max-width) {
  .btn-add-schedule {
    width: 100%;
  }
}
</style>
