<template>
  <div
    class="slide-container"
    :style="`background-image: url('${imageUrlComputed}')`"
  >
    <div class="slide-dark-background"></div>
    <div class="slide-content d-flex flex-column justify-content-center">
      <h6 class="slide-title">
        {{ title }}
      </h6>
      <p class="slide-description" v-html="description" />
      <a
        v-if="link"
        class="btn btn-slide"
        :href="link"
        target="_blank"
        rel="noopener"
      >
        Plus d'informations
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imageUrl: String,
    title: String,
    description: String,
    link: String,
  },
  data() {
    return {
      numberOfSlidesBackgroundImages: 139,
    };
  },
  computed: {
    imageUrlComputed() {
      return (
        this.imageUrl
        || `/static/img/slides/IMG (${Math.ceil(
          Math.random() * this.numberOfSlidesBackgroundImages,
        )}).jpg`
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.slide-container {
  background-position-y: center;
  background-size: cover;
  height: $slide-height;
  position: relative;
  width: 100%;
}

.slide-dark-background {
  background-color: rgba(0, 0, 0, 0.65);
  height: $slide-height;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.slide-title {
  margin-bottom: 1em;
}

.slide-content {
  color: white;
  font-weight: bold;
  font-size: 1.2em;
  height: $slide-height;
  left: 50%;
  position: absolute;
  text-align: left;
  top: 0;
  transform: translateX(-50%);

  .btn-slide {
    text-shadow: none;
  }
}

.slide-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 9; /* number of lines to show before adding "..." if the text is too long */
  -webkit-box-orient: vertical;
  max-height: 255px;
}

.btn-slide {
  max-width: 240px;
}

@media screen and (max-width: $mobile-max-width) {
  .slide-dark-background {
    height: $mobile-slide-height;
  }

  .slide-content {
    height: $mobile-slide-height;
    left: 0 !important;
    overflow: scroll;
    padding: 1.5em;
    top: 0;
    transform: none !important;
  }

  .slide-title {
    margin-top: 0;
  }

  .btn-slide {
    max-width: 100%;
  }
}

// Make the slides' height the same as the mobile screen's height when the screen's height is lesser than the slides' height.
@media screen and (max-height: calc(#{$mobile-slide-height} + #{$mobile-header-navbar-height})) {
  .slide-dark-background {
    height: $mobile-slide-height-small-screen;
  }

  .slide-content {
    height: $mobile-slide-height-small-screen;
  }
}
</style>

<style lang="scss">
.slide-description * {
  background-color: transparent !important;
  color: white !important;
}

.slide-description > p {
  margin: 0;
}
</style>
