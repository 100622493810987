<template>
  <div>
    <div class="tag-container">
      <label for="crud-tag">{{ label }}</label>
      <div class="d-flex">
        <el-select
          id="crud-tag"
          class="select-info select-one-line-per-item crud-tag"
          size="large"
          placeholder="Sélectionner"
          name="recordValue"
          :modelValue="modelValue"
          :multiple="multiple"
          @update:modelValue="$emit('update:modelValue', $event)"
        >
          <el-option
            v-for="record in records"
            :key="record.id"
            :label="record.value"
            :value="record.id"
          />
        </el-select>
        <button
          v-if="isAdmin"
          class="btn btn-icon btn-round btn-danger btn-crud-select"
          type="button"
          :title="
            multiple
              ? 'Supprimer les éléments sélectionnés'
              : 'Supprimer l\'élément sélectionné'
          "
          :disabled="multiple ? !modelValue.length : !modelValue"
          @click="deleteSelectedRecord"
        >
          <i class="fa fa-trash" />
        </button>
        <button
          v-if="isAdmin"
          class="btn btn-icon btn-round btn-success btn-crud-select"
          type="button"
          title="Ajouter un élément"
          :disabled="showRecordCreation"
          @click="showRecordCreationFunction"
        >
          <i class="fa fa-plus" />
        </button>
      </div>
      <div v-if="showRecordCreation" class="d-flex crud-select-input-container">
        <input
          v-model="newRecordValue"
          class="form-control"
          type="text"
          name="newRecordValue"
          placeholder="Nom de l'élément"
        />
        <button
          class="btn btn-icon btn-round btn-danger btn-crud-select"
          type="button"
          title="Annuler"
          @click="hideRecordCreation"
        >
          <i class="fa fa-times" />
        </button>
        <button
          class="btn btn-icon btn-round btn-success btn-crud-select"
          type="button"
          title="Ajouter l'élément"
          @click="createRecordFunction"
        >
          <i class="fa fa-check" />
        </button>
      </div>
      <small v-show="required && recordValue.invalid" class="text-danger">
        Le type est requis.
      </small>
      <div v-if="errorMessage" class="error-message">
        {{ errorMessage }}
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import ROLES from '@/services/Roles';

export default {
  props: [
    'modelValue',
    'label',
    'multiple',
    'required',
    'setSelectedRecordId',
    'fetchRecords',
    'createRecord',
    'deleteRecord',
  ],
  data() {
    return {
      showRecordCreation: false,
      records: [],
      newRecordValue: null,
      errorMessage: null,
    };
  },
  computed: {
    isAdmin() {
      return (
        this.$store.getters['account/roles'].includes(ROLES.ADMINISTRATOR)
        || this.$store.getters['account/roles'].includes(ROLES.POWER_USER)
      );
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.setSelectedRecordId(this.multiple ? [] : null);

      return this.fetchRecords().then((response) => {
        this.records = response.records;
      });
    },
    deleteSelectedRecord() {
      this.errorMessage = null;

      if (this.modelValue) {
        Swal.fire({
          title: this.multiple
            ? 'Êtes-vous sûr de vouloir supprimer ces éléments ?'
            : 'Êtes-vous sûr de vouloir supprimer cet élément ?',
          text: "Il ne sera pas possible d'annuler cette suppression !",
          icon: 'warning',
          showCancelButton: true,
          customClass: {
            confirmButton: 'btn btn-success btn-fill',
            cancelButton: 'btn btn-danger btn-fill',
          },
          confirmButtonText: 'Oui, effacer',
          cancelButtonText: 'Non, conserver',
          buttonsStyling: false,
        })
          .then((result) => {
            if (result.isConfirmed) {
              const deletions = [];

              if (this.multiple) {
                this.modelValue.forEach((v) => {
                  deletions.push(this.deleteRecord(v));
                });
              } else {
                deletions.push(this.deleteRecord(this.modelValue));
              }

              Promise.all(deletions)
                .then(() => {
                  this.fetch();
                })
                .catch((error) => {
                  this.errorMessage = error.response.data.message;
                });
            }
          })
          .catch(() => {});
      } else {
        this.errorMessage = 'Veuillez sélectionner un élément.';
      }
    },
    showRecordCreationFunction() {
      this.errorMessage = null;
      this.showRecordCreation = true;
    },
    hideRecordCreation() {
      this.errorMessage = null;
      this.showRecordCreation = false;
    },
    createRecordFunction() {
      this.errorMessage = null;

      if (this.newRecordValue) {
        const selectionBeforeFetch = this.modelValue;

        return this.createRecord(this.newRecordValue)
          .then(() => {
            this.fetch().then(() => {
              // Select the new-created element in the elements list.
              const newInsertedId = this.records.filter(
                (r) => r.value === this.newRecordValue,
              )[0].id;
              this.setSelectedRecordId(
                this.multiple
                  ? selectionBeforeFetch.concat([newInsertedId])
                  : newInsertedId,
              );
              this.hideRecordCreation();
              this.newRecordValue = null;
            });
          })
          .catch((error) => {
            this.errorMessage = error.response.data.message;
          });
      }
      this.errorMessage = "Veuillez entrer une valeur pour l'élément.";
      return null;
    },
  },
};
</script>
