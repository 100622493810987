const APPLICATION_CATEGORIES = [
  {
    value: 'ANALYSE_STATISTIC',
    name: 'Analyse statistique',
  },
  {
    value: 'BUREAUTIC',
    name: 'Bureautique',
  },
  {
    value: 'COLLABORATION_VIRTUALISATION',
    name: 'Collaboration et virtualisation',
  },
  {
    value: 'GRAMMAR_CHECKER',
    name: 'Correction grammaticale',
  },
  {
    value: 'DB_MANAGER',
    name: 'Gestion de base de données',
  },
  {
    value: 'MAIL_MANAGEMENT',
    name: 'Gestion du courrier',
  },
  {
    value: 'EXPERIENCE_MANAGEMENT',
    name: 'Gestion d’expérience',
  },
  {
    value: 'HOSTING_DEVELOPEMENT_MANAGER',
    name: 'Hébergement et gestion de développement',
  },
  {
    value: 'EMAILS_CONTACTS_CALENDAR',
    name: 'Mails, contacts et agendas',
  },
  {
    value: 'FILE_SHARING',
    name: 'Partage de fichiers',
  },
  {
    value: 'BUG_TRACKER',
    name: 'Suivi de bugs',
  },
  {
    value: 'UNIFIED_PHONE',
    name: 'Téléphonie unifiée',
  },
  {
    value: 'TRACKING_STATISTIC_CONSULTATION',
    name: 'Tracking et statistiques de consultation',
  },
  {
    value: 'TEAMWORK',
    name: 'Travail collaboratif',
  },
];

export default APPLICATION_CATEGORIES;
