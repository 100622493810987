<template>
  <div>
    <documents-crud-list title="Infrastructures" category="Infrastructures" />

    <p>Les réservations se font via les calendriers Outlook RM CIIP - …</p>

    <create-room-modal
      :show="newRoomModal"
      :current-operation="currentRoomOperation"
      :room="room"
      @close="closeRoomModal"
    />

    <div class="row rooms-list-container">
      <div class="col-md-12">
        <crud-list
          ref="crudList"
          title="Salles"
          :fetch-data="fetchRooms"
          @create="showNewRoomModal"
          @row-clicked="rowClicked"
        >
          <button-column
            type="edit"
            button-title="Modifier la salle"
            :show-on-mobile="true"
            :update-function="rowClicked"
          />
          <el-table-column label="Nom" min-width="150">
            <template #default="slotProps">
              <div class="table-items-list-item">
                {{ slotProps.row.name }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Capacité" min-width="110">
            <template #default="slotProps">
              <div class="table-items-list-item">
                {{ slotProps.row.capacity }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Description" min-width="300">
            <template #default="slotProps">
              <div class="table-items-list-item">
                {{ slotProps.row.description }}
              </div>
            </template>
          </el-table-column>
          <button-column
            type="edit"
            button-title="Modifier la salle"
            :show-on-mobile="false"
            :update-function="rowClicked"
          />
        </crud-list>
      </div>
    </div>
  </div>
</template>

<script>
import DocumentsCrudList from './UIComponents/DocumentsCrudList.vue';
import CrudList from './UIComponents/CrudList.vue';
import ButtonColumn from './UIComponents/ButtonColumn.vue';
import CreateRoomModal from './Modals/CreateRoomModal.vue';

export default {
  components: {
    DocumentsCrudList,
    CrudList,
    ButtonColumn,
    CreateRoomModal,
  },
  data() {
    return {
      newRoomModal: false,
      currentRoomOperation: 'create',
      room: {},
    };
  },
  methods: {
    fetchRooms() {
      return this.$http
        .get(`${process.env.VUE_APP_INTRANET_API_URL}/rooms?sort=name,asc`)
        .then((response) => ({
          records: response.data._embedded.rooms
            ? response.data._embedded.rooms
            : [],
          page: 0,
        }));
    },
    showNewRoomModal() {
      this.currentRoomOperation = 'create';
      this.room = {};
      this.newRoomModal = true;
    },
    closeRoomModal() {
      this.newRoomModal = false;
      // Refresh the crud-list component's data.
      this.$refs.crudList.fetch();
    },
    rowClicked(row) {
      this.currentRoomOperation = 'edit';
      this.room = { ...row };
      this.newRoomModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: $mobile-max-width) {
  .rooms-list-container {
    margin-left: -30px;
    margin-right: -30px;
  }
}
</style>
