<template>
  <div>
    <button class="btn btn-export" @click="exportLetterboxToXlsx">
      Exporter la liste au format Excel
    </button>
    <div class="row">
      <div class="col-md-12">
        <letterbox-modal
          :show="showModal"
          :letterboxProps="letterboxSelected"
          :currentOperation="currentOperation"
          @close="closeModal"
        />
        <crud-list
          ref="crudList"
          :fetch-data="fetchLetterbox"
          title="Boîtes aux lettres"
          @create="createLetterbox"
        >
          <button-column
            type="edit"
            button-title="Modifier la boîte aux lettres"
            :show-on-mobile="true"
            :update-function="updateLetterbox"
          />
          <el-table-column label="Nom" min-width="200" prop="name">
            <template #default="slotProps">
              <div class="table-items-list-item">
                {{ `${slotProps.row.name}` }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Type" min-width="200" prop="type">
            <template #default="slotProps">
              <div class="table-items-list-item">
                {{ `${slotProps.row.type}` }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Utilisateur(s) autorisé(s)" min-width="180">
            <template #default="slotProps">
              <div class="table-items-list-item">
                <div
                  v-for="access in slotProps.row.access"
                  :key="access.id"
                  class="table-double-cells"
                >
                  <span v-if="access.collaborator !== null">
                    {{
                      `${access.collaborator.firstname} ${access.collaborator.lastname}`
                    }}
                    <el-tag type="info">{{ access.accessRightStr }}</el-tag>
                  </span>
                  <br />
                </div>
              </div>
            </template>
          </el-table-column>
          <button-column
            type="edit"
            button-title="Modifier la boîte aux lettres"
            :show-on-mobile="false"
            :update-function="updateLetterbox"
          />
        </crud-list>
      </div>
    </div>
  </div>
</template>

<script>
import { ElTag } from 'element-plus';
import XLSX from 'xlsx';
import CrudList from './UIComponents/CrudList.vue';
import ButtonColumn from './UIComponents/ButtonColumn.vue';
import LetterboxModal from './Modals/LetterboxModal.vue';
import LETTERBOX_ACCESS from '../../enums/LetterboxAccess';

export default {
  components: {
    CrudList,
    ButtonColumn,
    LetterboxModal,
    [ElTag.name]: ElTag,
  },
  data() {
    return {
      showModal: false,
      letterboxSelected: null,
      currentOperation: 'create',
    };
  },
  methods: {
    fetchLetterbox(pageNumber, pageSize) {
      return this.$http
        .get(
          `${
            process.env.VUE_APP_INTRANET_API_URL
          }/letterbox/search/findAllPage?projection=letterboxProjection&page=${
            pageNumber - 1
          }&size=${pageSize}`,
        )
        .then((response) => {
          let { letterbox } = response.data._embedded;
          letterbox = letterbox.map((_letterbox) => ({
            ..._letterbox,
            access: _letterbox.access.map((access) => ({
              ...access,
              accessRightStr: LETTERBOX_ACCESS.get(access.accessRight),
            })),
          }));
          return {
            records: letterbox,
            page: response.data.page ? response.data.page : 0,
          };
        });
    },
    fetchAllLetterbox() {
      return this.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/letterbox/search/findAll?projection=letterboxProjection`,
        )
        .then((response) => {
          let { letterbox } = response.data._embedded;
          letterbox = letterbox.map((_letterbox) => ({
            ..._letterbox,
            access: _letterbox.access.map((access) => ({
              ...access,
              accessRightStr: LETTERBOX_ACCESS.get(access.accessRight),
            })),
          }));
          return letterbox;
        });
    },
    updateLetterbox(row, event) {
      event.stopPropagation();

      this.currentOperation = 'edit';
      this.showModal = true;

      this.letterboxSelected = { ...row };
    },
    createLetterbox() {
      this.currentOperation = 'create';
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.$refs.crudList.fetch();
    },
    exportLetterboxToXlsx() {
      const wsData = [['Nom', 'Type', 'Utilisateur(s) autorisé(s)']];

      this.fetchAllLetterbox().then((letterboxList) => {
        letterboxList.forEach((letterbox) => {
          const collaboratorsStr = letterbox.access
            .map(
              (access) => `${access.collaborator.firstname} ${access.collaborator.lastname} (${access.accessRightStr})`,
            )
            .join(', ');
          wsData.push([letterbox.name, letterbox.type, collaboratorsStr]);
        });

        const ws = XLSX.utils.aoa_to_sheet(wsData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'BoitesAuxLettres');
        XLSX.writeFile(wb, 'ciip_boites_aux_lettres.xlsx');
        this.$notify({
          title: 'Téléchargement',
          text: 'Téléchargement du fichier contenant les boîtes aux lettres',
          type: 'success',
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.access-right {
  font-size: 0.8em;
  font-style: italic;
}
</style>
