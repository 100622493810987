<template>
  <div>
    <details-modal
      :show="show"
      :current-operation="currentOperation"
      :loading="loading"
      @close="closeModal"
      @cancel="closeModal"
      @create="createMaterial"
      @update="updateMaterial"
      @delete="deleteMaterial"
    >
      <Form :validation-schema="validationSchema" validate-on-mount>
        <div class="form-group">
          <label class="control-label">*Identifiant</label>
          <Field
            name="identifier"
            v-model="material.identifier"
            v-slot="{ field, errorMessage }"
          >
            <input v-bind="field" class="form-control" type="text" />
            <small v-show="errorMessage" class="text-danger">
              L'identifiant est requis.
            </small>
          </Field>
        </div>
        <div>
          <label class="control-label">Responsable</label>
          <el-select
            id="select-owners"
            v-model="material.collaboratorId"
            class="select-info col-md-12 select-one-line-per-item"
            size="large"
            placeholder="Sélectionner"
            @change="handleOwnerChange"
            filterable
            clearable
          >
            <el-option
              v-for="c in collaboratorsName"
              :key="c.id"
              :label="`${c.lastname} ${c.firstname}`"
              :value="c.id"
            />
          </el-select>
        </div>
        <div>
          <label class="control-label">Mode d'emploi</label>
          <p>
            <input
              id="manual"
              type="file"
              name="manual"
              accept="application/pdf"
              @change="setManual($event)"
            /><br />
          </p>
        </div>
        <div v-if="isComputer">
          <label class="control-label">*Type</label>
          <el-select
            id="select-type"
            v-model="material.subType"
            class="select-info col-md-12 select-one-line-per-item"
            size="large"
            placeholder="Sélectionner"
            required="true"
            name="typeInput"
          >
            <el-option
              v-for="t in subTypes"
              :key="t.value"
              :label="`${t.name}`"
              :value="t.value"
            />
          </el-select>
          <small v-show="typeInput.invalid" class="text-danger">
            Le type est requis.
          </small>
        </div>
        <div class="form-group">
          <label class="control-label">Modèle</label>
          <input
            v-model="material.modal"
            class="form-control"
            type="text"
            name="modal"
          />
        </div>
        <div class="form-group">
          <label class="control-label">Marque</label>
          <input
            v-model="material.brand"
            class="form-control"
            type="text"
            name="brand"
          />
        </div>
        <div v-if="isComputerOrServer" class="form-group">
          <label class="control-label">Système d'exploitation</label>
          <input
            v-model="material.operatingSystem"
            class="form-control"
            type="text"
            name="operatingSystem"
          />
        </div>
        <div v-if="!isHeadsetOrDictaphoneOrDevice" class="form-group">
          <label class="control-label">Addresse IP</label>
          <input
            v-model="material.ip"
            class="form-control"
            type="text"
            name="ip"
          />
        </div>
        <div class="form-group">
          <label class="control-label">Date d'achat</label>
          <el-date-picker
            id="purchaseDate"
            v-model="material.purchaseDate"
            type="date"
            placeholder="Choisissez un jour"
          />
        </div>
        <div class="form-group">
          <label class="control-label">Prix (unitaire)</label>
          <el-input-number
            v-model="material.unitPrice"
            :precision="2"
            :min="0"
          ></el-input-number>
        </div>
        <div v-if="isComputer">
          <label class="control-label">Statut</label>
          <el-select
            id="select-type"
            v-model="material.status"
            class="select-info col-md-12 select-one-line-per-item"
            size="large"
            placeholder="Sélectionner"
            required="true"
            name="status"
            @input="handleStatusChange"
          >
            <el-option
              v-for="s in status"
              :key="s.value"
              :label="s.name"
              :value="s.value"
            />
          </el-select>
          <small v-show="statusInput.invalid" class="text-danger">
            Il n'est pas possible d'avoir un propriétaire et de ne pas être
            assigné.
          </small>
        </div>
        <div class="form-group">
          <label class="control-label">Localisation</label>
          <input
            v-model="material.location"
            class="form-control"
            type="text"
            name="location"
          />
        </div>
        <div>
          <label class="control-label">Fournisseur</label>
          <el-select
            id="select-type"
            v-model="material.providerId"
            class="select-info col-md-12 select-one-line-per-item"
            size="large"
            placeholder="Sélectionner"
            required="true"
            name="provider"
            filterable
            clearable
          >
            <el-option
              v-for="c in providers"
              :key="c.id"
              :label="`${c.name}`"
              :value="c.id"
            />
          </el-select>
        </div>
        <div class="form-group">
          <label class="control-label">Description</label>
          <textarea
            v-model="material.description"
            class="form-control"
            name="description"
          />
        </div>
        <div class="form-group">
          <label class="control-label">Remarque</label>
          <textarea
            v-model="material.remark"
            class="form-control"
            name="remark"
          />
        </div>
        <p><label>* champs obligatoires</label></p>
      </Form>
      <p v-if="errorMessage" class="error-message-in-modal">
        {{ errorMessage }}
      </p>
    </details-modal>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import {
  ElDatePicker as DatePicker,
  ElInputNumber as InputNumber,
} from 'element-plus';
import { Form, Field } from 'vee-validate';
import moment from 'moment';
import * as yup from 'yup';
import MixinFetchData from '@/mixins/mixinFetchData';
import MATERIALS_TYPES_ARRAY, {
  MATERIALS_SUBTYPES_ARRAY,
  MATERIALS_STATUS_ARRAY,
  MATERIALS_STATUS,
  MATERIALS_TYPES,
} from '@/enums/MaterialsTypes';
import DetailsModal from './DetailsModal.vue';

export default {
  components: {
    DetailsModal,
    [DatePicker.name]: DatePicker,
    [InputNumber.name]: InputNumber,
    Form,
    Field,
  },
  props: ['show', 'currentOperation', 'materialProps', 'type'],
  data() {
    return {
      errorMessage: '',
      validationSchema: {
        identifier: yup.string().required(),
      },
      material: {},
      subTypes: MATERIALS_SUBTYPES_ARRAY,
      status: MATERIALS_STATUS_ARRAY,
      typeInput: {
        invalid: false,
      },
      statusInput: {
        invalid: false,
      },
      loading: false,
      providers: [],
    };
  },
  watch: {
    show: {
      handler(newVal) {
        if (newVal) {
          this.fetchData();
        }
      },
      type: {
        handler(newVal) {
          if (
            MATERIALS_TYPES_ARRAY.filter(
              (material) => material.value === newVal,
            ).length === 1
          ) {
            this.material.type = newVal;
          } else {
            this.$notify({
              title: 'Error',
              text: 'Mauvais type donné à la pop-up, veuillez contacter le webmaster',
              type: 'danger',
            });
          }
        },
      },
    },
    materialProps: {
      handler(newVal) {
        this.material = {
          ...newVal,
          purchaseDate: newVal.purchaseDate
            ? moment(newVal.purchaseDate, 'DD.MM.YYYY')
            : null,
          collaboratorId: newVal.collaborator ? newVal.collaborator.id : null,
          type: this.type,
        };
        delete this.material.collaborator;
      },
    },
  },
  computed: {
    isComputer() {
      return this.material.type === MATERIALS_TYPES.computer.value;
    },
    isComputerOrServer() {
      return (
        this.isComputer || this.material.type === MATERIALS_TYPES.server.value
      );
    },
    isHeadsetOrDictaphoneOrDevice() {
      return (
        this.type === MATERIALS_TYPES.headset.value
        || this.type === MATERIALS_TYPES.device.value
        || this.isDictaphone
      );
    },
  },
  mounted() {
    if (
      MATERIALS_TYPES_ARRAY.filter((material) => material.value === this.type)
        .length === 1
    ) {
      this.material.type = this.type;
    } else {
      this.$notify({
        title: 'Error',
        text: 'Mauvais type donné à la pop-up, veuillez contacter le webmaster',
        type: 'danger',
      });
    }
  },
  mixins: [MixinFetchData],
  methods: {
    async createMaterial() {
      // Validate each field with vee-validate, and post the new material.
      const validationSchemaObject = yup.object(this.validationSchema);
      const isMaterialValid = await validationSchemaObject.isValid(
        this.material,
      );

      if (isMaterialValid && this.validateCustum()) {
        this.errorMessage = null;

        const formData = new FormData();
        formData.append('manual', this.material.manual);
        // Add the properties as application/json content type in order to unserialize them in the server.
        formData.append(
          'properties',
          new Blob(
            [
              JSON.stringify({
                ...this.material,
              }),
            ],
            {
              type: 'application/json',
            },
          ),
        );

        this.loading = true;

        this.$http
          .post(
            `${process.env.VUE_APP_INTRANET_API_URL}/commands/materials/`,
            formData,
          )
          .then(() => {
            this.closeModal();
          })
          .catch((error) => {
            this.errorMessage = "Une erreur est survenue lors de l'ajout du matériel des données, veuillez réessayer ou contacter le webmaster.";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.errorMessage = 'Un ou plusieurs champs sont invalides.';
      }
    },
    async updateMaterial() {
      // Validate each field with vee-validate, and update the material.
      const validationSchemaObject = yup.object(this.validationSchema);
      const isMaterialValid = await validationSchemaObject.isValid(
        this.material,
      );

      if (isMaterialValid && this.validateCustum()) {
        this.errorMessage = null;

        this.loading = true;

        this.$http
          .patch(
            `${process.env.VUE_APP_INTRANET_API_URL}/commands/materials/${this.material.id}`,
            {
              ...this.material,
            },
          )
          .then(() => {
            this.closeModal();
          })
          .catch((error) => {
            this.errorMessage = 'Une erreur est survenue lors de la modification du matériel, veuillez réessayer ou contacter le webmaster.';
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.errorMessage = 'Un ou plusieurs champs sont invalides.';
      }
    },
    deleteMaterial() {
      this.errorMessage = null;

      Swal.fire({
        title: 'Êtes-vous sûr de vouloir supprimer ce matériel ?',
        text: "Il ne sera pas possible d'annuler cette suppression !",
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill',
        },
        confirmButtonText: 'Oui, effacer',
        cancelButtonText: 'Non, conserver',
        buttonsStyling: false,
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.$http
              .delete(
                `${process.env.VUE_APP_INTRANET_API_URL}/materials/${this.material.id}`,
              )
              .then(() => {
                this.closeModal();
              })
              .catch((error) => {
                this.errorMessage = 'Une erreur est survenue lors de la suppression du matériel, veuillez réessayer ou contacter le webmaster.';
              });
          }
        })
        .catch(() => {});
    },
    validateCustum() {
      let error = false;
      if (this.isComputer) {
        // subType obligatory
        if (!this.material.subType) {
          this.typeInput.invalid = true;
          error = true;
        }
        if (
          this.material.collaboratorId
          && this.material.status !== MATERIALS_STATUS.assigned.value
        ) {
          this.statusInput.invalid = true;
          error = true;
        }
        if (
          !this.material.collaboratorId
          && this.material.status === MATERIALS_STATUS.assigned.value
        ) {
          this.statusInput.invalid = true;
          error = true;
        }
        if (error) {
          this.errorMessage = 'Un ou plusieurs champs sont invalides.';
          return false;
        }
      }
      this.typeInput.invalid = false;
      return true;
    },
    setSelectedTypeId(typeId) {
      this.material.typeId = typeId;
    },
    setManual(event) {
      if (event.target.files[0]) {
        [this.material.manual] = event.target.files;
      }
    },
    fetchTypes() {
      return MATERIALS_SUBTYPES_ARRAY;
    },
    createType(data) {
      return this.$http.post(
        `${process.env.VUE_APP_INTRANET_API_URL}/materialsTypes`,
        {
          name: data,
        },
      );
    },
    deleteType(typeId) {
      return this.$http.delete(
        `${process.env.VUE_APP_INTRANET_API_URL}/materialsTypes/${typeId}`,
      );
    },
    closeModal() {
      this.$emit('close');
      this.material.typeId = null;
      this.errorMessage = '';
    },
    fetchData() {
      // This function are coming from the "mixinFetchData" mixin.
      this.fetchCollaboratorsNames();

      this.fetchProviders();
    },
    handleOwnerChange(event) {
      if (this.isComputer && this.material.collaboratorId) {
        this.material.status = MATERIALS_STATUS.assigned.value;
      }
    },
    handleStatusChange(status) {
      if (
        (status === MATERIALS_STATUS.not_assigned.value
          || status === MATERIALS_STATUS.reservable.value)
        && this.material.collaboratorId !== null
      ) {
        this.material.collaboratorId = null;
      }
    },
    fetchProviders() {
      this.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/enterprises/search/findAll`,
        )
        .then((response) => {
          this.providers = response.data._embedded.enterprises
            ? response.data._embedded.enterprises
            : [];
        });
    },
  },
};
</script>
