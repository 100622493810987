<template>
  <div>
    <div class="tag-container">
      <label for="select-tag">Tag</label>
      <div class="d-flex">
        <el-select
          id="select-tag"
          class="select-info select-one-line-per-item crud-tag"
          size="large"
          filterable
          placeholder="Sélectionner"
          :modelValue="tagId"
          @update:modelValue="selectTagId"
          clearable
        >
          <el-option
            v-for="tag in tags"
            :key="tag.id"
            :label="tag.name"
            :value="tag.id"
          />
        </el-select>
        <button
          class="btn btn-icon btn-round btn-danger btn-crud-select"
          type="button"
          title="Supprimer le tag sélectionné"
          :disabled="!tagId"
          @click="deleteSelectedTag"
        >
          <i class="fa fa-trash" />
        </button>
        <button
          class="btn btn-icon btn-round btn-success btn-crud-select"
          type="button"
          title="Ajouter un tag à la catégorie sélectionnée"
          :disabled="showTagCreation || !categoryId"
          @click="showTagCreationFunction"
        >
          <i class="fa fa-plus" />
        </button>
      </div>
      <div v-if="showTagCreation" class="d-flex crud-select-input-container">
        <input
          v-model="newTagName"
          class="form-control"
          type="text"
          name="newTagName"
          placeholder="Nom du tag"
        />
        <button
          class="btn btn-icon btn-round btn-danger btn-crud-select"
          type="button"
          title="Annuler"
          @click="hideTagCreation"
        >
          <i class="fa fa-times" />
        </button>
        <button
          class="btn btn-icon btn-round btn-success btn-crud-select"
          type="button"
          title="Ajouter le tag"
          @click="createTag"
        >
          <i class="fa fa-check" />
        </button>
      </div>
      <div v-if="errorMessage" class="error-message">
        {{ errorMessage }}
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';

export default {
  props: ['tagId', 'categoryId'],
  data() {
    return {
      showTagCreation: false,
      tags: [],
      newTagName: null,
      errorMessage: null,
    };
  },
  watch: {
    categoryId: {
      handler(newVal) {
        if (newVal) {
          this.fetchTags(newVal, 0);
        }
      },
    },
  },
  methods: {
    fetchTags(categoryId) {
      this.tags = [];

      return this.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/documentsTags/search/byCategory?categoryId=${categoryId}`,
        )
        .then((payload) => {
          this.tags = this.tags.concat(payload.data._embedded.documentsTags);
          this.resetTagIfNotInCategoryTags();
        })
        .catch((error) => {
          this.errorMessage = error.response.data.message;
        });
    },
    deleteSelectedTag() {
      this.errorMessage = null;

      if (this.tagId) {
        Swal.fire({
          title: 'Êtes-vous sûr de vouloir supprimer ce tag ?',
          text: "Il ne sera pas possible d'annuler cette suppression !",
          icon: 'warning',
          showCancelButton: true,
          customClass: {
            confirmButton: 'btn btn-success btn-fill',
            cancelButton: 'btn btn-danger btn-fill',
          },
          confirmButtonText: 'Oui, effacer',
          cancelButtonText: 'Non, conserver',
          buttonsStyling: false,
        })
          .then((result) => {
            if (result.isConfirmed) {
              this.$http
                .delete(
                  `${process.env.VUE_APP_INTRANET_API_URL}/documentsTags/${this.tagId}`,
                )
                .then(() => {
                  this.fetchTags(this.categoryId, 0);
                })
                .catch((error) => {
                  this.errorMessage = error.response.data.message;
                });
            }
          })
          .catch(() => {});
      } else {
        this.errorMessage = 'Veuillez sélectionner un tag.';
      }
    },
    showTagCreationFunction() {
      this.errorMessage = null;
      this.showTagCreation = true;
    },
    hideTagCreation() {
      this.errorMessage = null;
      this.showTagCreation = false;
    },
    createTag() {
      this.errorMessage = null;

      if (this.newTagName) {
        return this.$http
          .post(
            `${process.env.VUE_APP_INTRANET_API_URL}/commands/documentsTags/`,
            {
              name: this.newTagName,
              categoryId: this.categoryId,
            },
          )
          .then(() => {
            this.fetchTags(this.categoryId, 0).then(() => {
              // Select the new-created tag in the tags list.
              this.$emit(
                'setSelectedTagId',
                this.tags.filter((t) => t.name === this.newTagName)[0].id,
              );
              this.hideTagCreation();
              this.newTagName = null;
            });
          })
          .catch((error) => {
            this.errorMessage = error.response.data.message;
          });
      }
      this.errorMessage = 'Veuillez entrer un nom de tag.';
      return null;
    },
    selectTagId(tagId) {
      this.$emit('setSelectedTagId', tagId);
    },
    resetTagIfNotInCategoryTags() {
      const tagInPossibleTags = this.tags.filter((tag) => tag.id === this.tagId).length > 0;
      if (!tagInPossibleTags) {
        this.$emit('setSelectedTagId', null);
      }
    },
  },
};
</script>
