<template>
  <div>
    <details-modal
      :show="show"
      :current-operation="currentOperation"
      :loading="loading"
      @close="closeModal"
      @cancel="closeModal"
      @create="createCar"
      @update="updateCar"
      @delete="deleteCar"
    >
      <Form :validation-schema="validationSchema" validate-on-mount>
        <div class="form-group">
          <label class="control-label">*N° de plaque</label>
          <Field
            name="licensePlateNumber"
            v-model="car.licensePlateNumber"
            v-slot="{ field, errorMessage }"
          >
            <input v-bind="field" class="form-control" type="text" />
            <small v-show="errorMessage" class="text-danger">
              Le numéro de plaque est requis.
            </small>
          </Field>
        </div>
        <p>
          <label for="select-owners">Propriétaire(s)</label>
          <el-select
            id="select-owners"
            v-model="car.ownersIds"
            class="select-info col-md-12 select-one-line-per-item"
            size="large"
            placeholder="Sélectionner"
            :multiple="true"
            filterable
          >
            <el-option
              v-for="c in collaboratorsName"
              :key="c.id"
              :label="`${c.lastname} ${c.firstname}`"
              :value="c.id"
            />
          </el-select>
        </p>
        <p><label>* champs obligatoires</label></p>
      </Form>
      <p v-if="errorMessage" class="error-message-in-modal">
        {{ errorMessage }}
      </p>
    </details-modal>
  </div>
</template>

<script>
import { ElDatePicker as DatePicker } from 'element-plus';
import { Form, Field } from 'vee-validate';
import Swal from 'sweetalert2';
import * as yup from 'yup';
import MixinFetchData from '@/mixins/mixinFetchData';
import MixinProcessImage from '@/mixins/mixinProcessImage';
import DetailsModal from './DetailsModal.vue';

export default {
  components: {
    DetailsModal,
    [DatePicker.name]: DatePicker,
    Form,
    Field,
  },
  mixins: [MixinFetchData, MixinProcessImage],
  props: ['show', 'currentOperation', 'carProps'],
  data() {
    return {
      car: {
        licensePlateNumber: null,
        collaboratorsIds: [],
      },
      errorMessage: '',
      validationSchema: {
        licensePlateNumber: yup.string().required(),
      },
      loading: false,
    };
  },
  watch: {
    show: {
      handler(newVal) {
        if (newVal) {
          this.fetchData();
        }
      },
    },
    carProps: {
      handler(newVal) {
        if (newVal) {
          this.car = newVal;
        }
      },
    },
  },
  methods: {
    async createCar() {
      // Validate each field with vee-validate, then post the new car.
      const validationSchemaObject = yup.object(this.validationSchema);
      const isCarValid = await validationSchemaObject.isValid(this.car);

      if (isCarValid) {
        this.errorMessage = null;
        this.loading = false;
        this.$http
          .post(`${process.env.VUE_APP_INTRANET_API_URL}/commands/cars/`, {
            licensePlateNumber: this.car.licensePlateNumber,
            collaboratorsIds: this.car.ownersIds,
          })
          .then((response) => {
            this.closeModal();
          })
          .catch((error) => {
            this.errorMessage = 'Une erreur est survenue lors du chargement des données, veuillez réessayer ou contacter le webmaster.';
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.errorMessage = 'Un ou plusieurs champs sont invalides.';
      }
    },
    async updateCar() {
      const validationSchemaObject = yup.object(this.validationSchema);
      const isCarValid = await validationSchemaObject.isValid(this.car);

      if (isCarValid) {
        this.errorMessage = null;

        this.loading = true;

        this.$http
          .patch(
            `${process.env.VUE_APP_INTRANET_API_URL}/commands/cars/${this.car.id}`,
            {
              licensePlateNumber: this.car.licensePlateNumber,
              collaboratorsIds: this.car.ownersIds,
            },
          )
          .then(() => {
            this.closeModal();
          })
          .catch((error) => {
            this.errorMessage = 'Une erreur est survenue lors de la modification du véhicule, veuillez réessayer ou contacter le webmaster.';
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.errorMessage = 'Un ou plusieurs champs sont invalides.';
      }
    },
    deleteCar() {
      this.errorMessage = null;

      Swal.fire({
        title: 'Êtes-vous sûr de vouloir supprimer ce véhicule ?',
        text: "Il ne sera pas possible d'annuler cette suppression !",
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill',
        },
        confirmButtonText: 'Oui, effacer',
        cancelButtonText: 'Non, conserver',
        buttonsStyling: false,
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.$http
              .delete(
                `${process.env.VUE_APP_INTRANET_API_URL}/commands/cars/${this.car.id}`,
              )
              .then(() => {
                this.closeModal();
              })
              .catch((error) => {
                this.errorMessage = 'Une erreur est survenue lors de la suppression du véhicule, veuillez réessayer ou contacter le webmaster.';
              });
          }
        })
        .catch(() => {});
    },
    closeModal() {
      this.$emit('close');
      this.resetData();
      this.errorMessage = '';
    },
    resetData() {
      this.car = {
        licensePlateNumber: null,
        collaboratorsIds: [],
      };
    },
    fetchData() {
      // These functions are coming from the "mixinFetchData" mixin.
      this.fetchCollaboratorsNames();
    },
  },
};
</script>
