<template>
  <div class="my-1">
    <el-select
      id="select-directory"
      :modelValue="directory"
      value-key="id"
      @update:modelValue="directoryChange"
      class="select-info col-md-5 px-1 select-one-line-per-item"
      size="large"
      placeholder="Dossier"
      :disabled="disabled"
      filterable
    >
      <el-option
        v-for="d in directories"
        :key="d.id"
        :label="`${d.path}`"
        :value="d"
      />
    </el-select>
    <el-select
      id="select-access"
      :modelValue="accessRight"
      @update:modelValue="accessChange"
      class="select-info col-md-5 px-1 select-one-line-per-item"
      size="large"
      placeholder="Droit d'accès"
      :disabled="disabled"
      filterable
    >
      <el-option key="read" label="Lecture" value="READ" />
      <el-option key="modification" label="Modification" value="MODIFICATION" />
    </el-select>
    <button
      class="my-0 btn btn-icon btn-round btn-danger btn-crud-select"
      type="button"
      title="Supprimer l'accès"
      :disabled="disabled"
      @click="$emit('deleteAccess', position)"
    >
      <i class="fa fa-trash" />
    </button>
  </div>
</template>

<script>
export default {
  props: {
    position: {
      type: Number,
      required: true,
    },
    accessRight: {
      type: String,
      required: true,
    },
    directory: {
      type: Object,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    directories: {
      type: Array,
      required: true,
    },
  },
  methods: {
    directoryChange(value) {
      this.$emit('directoryChange', {
        value,
        position: this.position,
      });
    },
    accessChange(value) {
      this.$emit('accessChange', {
        value,
        position: this.position,
      });
    },
  },
};
</script>
