<template>
  <nav :class="classes" class="navbar navbar-expand-lg">
    <div class="container-fluid">
      <slot />

      <CollapseTransition>
        <div
          v-show="showNavbar"
          id="navigation"
          :class="navbarMenuClasses"
          class="collapse navbar-collapse justify-content-end show"
        >
          <ul class="navbar-nav">
            <slot name="navbar-menu" />
          </ul>
        </div>
      </CollapseTransition>
    </div>
  </nav>
</template>

<script>
import { CollapseTransition } from '@noction/vue-bezier';

export default {
  name: 'Navbar',
  components: {
    CollapseTransition,
  },
  props: {
    showNavbar: {
      type: Boolean,
      description: 'Whether navbar is visible',
    },
    navbarMenuClasses: {
      type: [String, Object],
      description: 'Navbar menu css classes',
    },
    transparent: {
      type: Boolean,
      default: true,
      description: 'Whether navbar is transparent',
    },
    position: {
      type: String,
      default: 'absolute',
      description: 'Navbar position (absolute|fixed|relative)',
    },
    type: {
      type: String,
      default: 'white',
      validator(value) {
        return [
          'white',
          'default',
          'primary',
          'danger',
          'success',
          'warning',
          'info',
        ].includes(value);
      },
      description: 'Navbar type (primary|info|danger|default|warning|success)',
    },
  },
  computed: {
    classes() {
      const color = `bg-${this.type}`;
      const navPosition = `navbar-${this.position}`;
      return [
        { 'navbar-transparent': !this.showNavbar && this.transparent },
        { [color]: this.showNavbar || !this.transparent },
        navPosition,
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar-relative {
  position: relative;
}
</style>
