import jwt from 'jsonwebtoken';
import axios from 'axios';
import router from '@/router/router';
import ROLES from '@/services/Roles';
import { isAllowed } from '@/services/AuthService';
import { app } from '../../main';

export default {
  namespaced: true,
  state: {
    userId: null,
    accountId: null,
    email: null,
    firstName: null,
    lastName: null,
    organization: null,
    token: null,
    expiration: null,
    roles: [],
  },
  mutations: {
    setToken(state, token) {
      // Either update token from database or set it
      if (token != null) {
        // Check validity
        const decoded = jwt.decode(token);
        const expiration = decoded.exp * 1000;
        if (new Date().getTime() < expiration) {
          state.accountId = decoded.sub;
          state.email = decoded.accountEmail;
          state.firstName = decoded.firstName;
          state.lastName = decoded.lastName;
          state.organization = decoded.organization;
          state.userId = decoded.userId;
          state.expiration = expiration;
          state.token = token;
          const roles = JSON.parse(decoded.roles);
          if (roles[process.env.VUE_APP_NAME]) {
            state.roles = roles[process.env.VUE_APP_NAME].map((role) => Object.values(ROLES).find((appRole) => appRole.name === role));
          }
        }
      }
    },
    clear(state) {
      state.email = null;
      state.token = null;
      state.userId = null;
      state.accountId = null;
      state.firstName = null;
      state.lastName = null;
      state.organization = null;
      state.roles = [];
      state.expiration = null;
    },
  },
  actions: {
    init({ commit, dispatch }) {},
    login({ commit, dispatch }) {
      // Await the token from IDM
      return axios
        .create()
        .get(`${process.env.VUE_APP_IDM_API_URL}/auth/jwt`, {
          withCredentials: true,
          headers: { Accept: 'application/json' },
        })
        .then((response) => {
          if (response.data.token) {
            commit('setToken', response.data.token);
            app.config.globalProperties.$notify({
              message: 'La connexion a réussi!',
              title: 'Succès',
              horizontalAlign: 'right',
              verticalAlign: 'bottom',
              type: 'success',
              timeout: 5000,
            });
          } else {
            throw new Error('Token not received');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log('Shib session is not available', error);
          // Ignore, as we are not authentified
        });
    },
    logout({ commit }) {
      commit('clear');
      app.config.globalProperties.$notify({
        message: 'La déconnexion a réussi!',
        title: 'Succès',
        horizontalAlign: 'right',
        verticalAlign: 'bottom',
        type: 'success',
        timeout: 5000,
      });
      axios
        .create()
        .get(`${process.env.VUE_APP_SP_URL}/Shibboleth.sso/Logout`, {
          withCredentials: true,
        })
        .then(() => {
          // eslint-disable-next-line no-console
          console.log('Logged out from Shibboleth too');
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error.response);
          // We don't need to notify because even when we have a CORS error, the shibboleth session is destroyed (apparently)
        })
        .finally(() => {
          // Check if the user still has access to the current route otherwise go to login page
          if (!isAllowed(router.currentRoute.value)) {
            window.location = `${process.env.VUE_APP_IDM_WEBAPP_URL}/login?appURL=${window.location.href}`;
          }
          return null;
        });
    },
  },
  getters: {
    email: (state) => state.email,
    firstName: (state) => state.firstName,
    lastName: (state) => state.lastName,
    organization: (state) => state.organization,
    accountId: (state) => state.accountId,
    userId: (state) => state.userId,
    roles: (state) => state.roles,
    token: (state) => state.token,
    expiration: (state) => state.expiration,
  },
};
