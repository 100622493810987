import { app } from '../../main';

export default {
  namespaced: true,
  actions: {
    init() {},
    fetchImageAsBlob({ dispatch }, { imagePath, imageName }) {
      if (imageName === null) {
        return dispatch('fetchImageAsBlob', {
          imagePath,
          imageName: 'default.png',
        });
      }
      // Fetch the image as a blob object, then return it. If an error is thrown, fetch the default image.
      return app.config.globalProperties.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/commands/documents?path=${imagePath}&name=${imageName}`,
          {
            responseType: 'blob',
          },
        )
        .then((response) => response.data)
        .catch((error) => {
          if (imageName !== 'default.png') {
            return dispatch('fetchImageAsBlob', {
              imagePath,
              imageName: 'default.png',
            });
          }
          return null;
        });
    },
  },
};
