<template>
  <div>
    <create-material-modal
      :show="newMaterialModal"
      :current-operation="currentMaterialOperation"
      :materialProps="material"
      @close="closeMaterialModal"
      :type="type"
    />
    <div class="action-container">
      <button class="btn btn-round btn-export" @click="exportToXslx">
        Exporter la liste au format Excel
      </button>
      <div class="details-container">
        <el-switch
          v-model="showDetails"
          active-color="#13ce66"
          @change="showDetailsChange"
        >
        </el-switch>
        <span class="details-span">Plus de détails</span>
      </div>
    </div>
    <div>
      <p v-if="isDictaphone">
        <a
          href="#"
          @click="
            downloadDocument(
              'docs/xlsx/',
              'dictaphone_reservationl.xlsx',
              $event
            )
          "
        >
          Emprunt et réservation des dictaphones / enregistreurs
        </a>
      </p>
      <p>Les réservations se font via les calendriers Outlook EM CIIP - …</p>
    </div>
    <div class="row">
      <div class="col-md-12">
        <crud-list
          ref="crudList"
          :title="title"
          :fetch-data="fetchMaterial"
          @create="showNewMaterialModal"
          :defaultSort="sort"
          @sort-change="sortChange"
          @row-clicked="rowClicked"
          :pageNumber="page.number"
        >
          <el-table-column
            label="Identifiant"
            min-width="155"
            prop="identifier"
            sortable="custom"
          >
            <template #default="slotProps">
              <div class="table-items-list-item">
                {{ slotProps.row.identifier ? slotProps.row.identifier : '-' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-if="isComputer"
            label="Type"
            min-width="100"
            prop="subType"
            sortable="custom"
          >
            <template #default="slotProps">
              <div class="table-items-list-item">
                {{
                  slotProps.row.subTypeString
                    ? slotProps.row.subTypeString
                    : '-'
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Responsable" min-width="190">
            <template #default="slotProps">
              <div class="table-items-list-item">
                {{
                  slotProps.row.collaborator
                    ? slotProps.row.collaborator.firstname +
                      ' ' +
                      slotProps.row.collaborator.lastname
                    : '-'
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="Marque"
            min-width="150"
            prop="brand"
            sortable="custom"
          >
            <template #default="slotProps">
              <div class="table-items-list-item">
                {{ slotProps.row.brand ? slotProps.row.brand : '-' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="Modèle"
            min-width="150"
            prop="modal"
            sortable="custom"
          >
            <template #default="slotProps">
              <div class="table-items-list-item">
                {{ slotProps.row.modal ? slotProps.row.modal : '-' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-if="isComputer && showDetails"
            label="Statut"
            min-width="135"
            prop="status"
            sortable="custom"
          >
            <template #default="slotProps">
              <div class="table-items-list-item">
                {{
                  slotProps.row.statusString ? slotProps.row.statusString : '-'
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-if="showDetails"
            label="Date d'achat"
            min-width="170"
            prop="purchaseDate"
            sortable="custom"
          >
            <template #default="slotProps">
              <div class="table-items-list-item">
                {{
                  slotProps.row.purchaseDate ? slotProps.row.purchaseDate : '-'
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-if="showDetails"
            label="Prix (unitaire)"
            min-width="170"
            prop="unitPrice"
          >
            <template #default="slotProps">
              <div class="table-items-list-item">
                {{
                  slotProps.row.unitPrice
                    ? slotProps.row.unitPrice.toFixed(2) + ' CHF'
                    : '-'
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-if="showDetails"
            label="Fournisseur"
            min-width="170"
          >
            <template #default="slotProps">
              <div class="table-items-list-item">
                <span v-if="slotProps.row.provider">
                  {{ `${slotProps.row.provider.name}` }}
                </span>
                <span v-else> - </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Mode d'emploi" min-width="170" prop="manual">
            <template #default="slotProps">
              <div class="table-items-list-item">
                <span v-if="slotProps.row.manual">
                  <a href="#" @click="manualClick(slotProps.row.manual, $event)"
                    >Mode d'emploi</a
                  >
                </span>
                <span v-else> - </span>
              </div>
            </template>
          </el-table-column>
        </crud-list>
      </div>
    </div>
  </div>
</template>

<script>
import XLSX from 'xlsx';
import { mapGetters } from 'vuex';
import { ElSwitch as Switch } from 'element-plus';
import MixinFetchData from '@/mixins/mixinFetchData';
import { MATERIALS_TYPES } from '@/enums/MaterialsTypes';
import CrudList from './CrudList.vue';
import CreateMaterialModal from '../Modals/CreateMaterialModal.vue';

export default {
  components: {
    CrudList,
    CreateMaterialModal,
    [Switch.name]: Switch,
  },
  mixins: [MixinFetchData],
  props: {
    type: String,
    projection: {
      type: String,
      default: () => 'materialSummary',
    },
    title: String,
  },
  data() {
    return {
      errorMessage: null,
      newMaterialModal: false,
      currentMaterialOperation: 'create',
      material: {
        typeId: null,
      },
      filesPath: process.env.VUE_APP_INTRANET_API_URL,
      showDetails: false,
    };
  },
  created() {
    if (this.type !== this.currentType) {
      this.$store.commit('materials/setPageNumber', 1);
      this.$store.commit('materials/setType', this.type);
    }
  },
  computed: {
    ...mapGetters('materials', [
      'page',
      'materials',
      'allMaterials',
      'sort',
      'currentType',
    ]),
    isComputer() {
      return this.type === MATERIALS_TYPES.computer.value;
    },
    isServer() {
      return this.type === MATERIALS_TYPES.server.value;
    },
    isRouter() {
      return this.type === MATERIALS_TYPES.router.value;
    },
    isComputerOrServer() {
      return this.isComputer || this.isServer;
    },
    isServerOrRouter() {
      return this.isRouter || this.isServer;
    },
    isDictaphone() {
      return this.type === MATERIALS_TYPES.dictaphone.value;
    },
    isHeadsetOrDictaphoneOrDevice() {
      return (
        this.type === MATERIALS_TYPES.headset.value
        || this.type === MATERIALS_TYPES.device.value
        || this.isDictaphone
      );
    },
  },
  methods: {
    fetchMaterial(pageNumber, pageSize) {
      return this.$store
        .dispatch('materials/fetchMaterialsPage', {
          pageNumber,
          pageSize,
          type: this.type,
          projection: this.projection,
        })
        .then(() => ({
          records: this.materials,
          page: this.page,
        }));
    },
    showNewMaterialModal() {
      this.currentMaterialOperation = 'create';
      this.material = {
        typeId: null,
      };
      this.newMaterialModal = true;
    },
    closeMaterialModal() {
      this.newMaterialModal = false;
      // Refresh the crud-list component's data.
      this.$refs.crudList.fetch();
    },
    updateMaterial(row, event) {
      // Prevent the opening of the link since the user clicked on the platform row to update it.

      this.currentMaterialOperation = 'edit';
      this.material = {
        ...row,
        typeId: row.type.id,
      };
      this.newMaterialModal = true;
    },
    sortChange({ column, prop, order }) {
      this.$store.commit('materials/setSort', {
        prop,
        order,
      });
      this.$refs.crudList.fetch();
    },
    rowClicked(row) {
      this.$router.push({
        name: 'MaterialDetails',
        params: { id: row.id },
        query: {
          type: this.type,
          path: this.$route.name,
        },
      });
    },
    manualClick(manual, event) {
      this.downloadDocument('materials/manuals/', manual, event);
    },
    exportToXslx() {
      const wsData = [
        [
          'Identifiant',
          'Type',
          'Responsable',
          'Description',
          'Modèle',
          'Marque',
          'Prix (unitaire)',
          'Fournisseur',
          'Remarque',
          "Date d'achat",
          "Système d'exploitation",
          'Localisation',
          'IP',
          'Status',
        ],
      ];

      return this.$store
        .dispatch('materials/fetchAllMaterials', {
          type: this.type,
          projection: this.projection,
        })
        .then((materials) => {
          materials.forEach((material) => {
            wsData.push([
              material.identifier,
              material.subTypeString,
              material.collaborator
                ? `${material.collaborator.firstname} ${material.collaborator.lastname}`
                : '',
              material.description,
              material.modal,
              material.brand,
              material.unitPrice,
              material.provider ? `${material.provider.name}` : '',
              material.remark,
              material.purchaseDate,
              material.operatingSystem,
              material.location,
              material.ip,
              material.statusString,
            ]);
          });

          const ws = XLSX.utils.aoa_to_sheet(wsData); // Write into cells
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, `Liste_matériaux_${this.type}`);
          XLSX.writeFile(wb, `liste_materiaux_${this.type}.xlsx`);
        });
    },
    showDetailsChange(value) {
      if (value) {
        this.$notify({
          title: 'Plus de détails',
          text: 'Attention, il se peut que des données dépassent de votre écran. Vous pouvez défiler horizontalement le tableau grâce à la combinaison Shift + Scroll, ou via la barre de défilement située en bas du tableau.',
          type: 'warning',
          duration: 5000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.action-container {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.details-container {
  display: flex;
  align-items: center;
}

.details-span {
  margin-left: 1em;
}
</style>
