<template>
  <navbar :show-navbar="showNavbar">
    <div class="navbar-wrapper col-12 col-md-auto">
      <div class="navbar-minimize">
        <button
          id="minimizeSidebar"
          name="minimizeSidebar"
          class="btn btn-icon btn-round"
          aria-label="Minimiser le menu latéral"
          @click.prevent="minimizeSidebar"
        >
          <i
            class="nc-icon nc-minimal-right text-center visible-on-sidebar-mini"
          />
          <i
            class="nc-icon nc-minimal-left text-center visible-on-sidebar-regular"
          />
        </button>
      </div>
      <div class="navbar-toggle">
        <navbar-toggle-button @click="toggleSidebar" />
      </div>
      <a class="logout-mobile ml-auto d-md-none" href="#" @click="logout">
        Logout
      </a>
    </div>

    <template #navbar-menu>
      <div class="navbar-menu">
        <div class="search-form">
          <form @submit.prevent="search">
            <div class="input-group no-border">
              <div class="input-group-append">
                <div
                  id="resetAdvancesSearch"
                  class="input-group-text"
                  @click.prevent="clearField"
                >
                  <i class="nc-icon nc-simple-remove" />
                </div>
              </div>
              <label for="search-input" hidden></label>
              <input
                v-model="searchField"
                id="search-input"
                type="text"
                class="form-control"
                placeholder="Rechercher un document ou un lien..."
              />
              <div class="input-group-append">
                <div class="input-group-text" @click.prevent="search">
                  <i class="nc-icon nc-zoom-split" />
                </div>
              </div>
            </div>
          </form>
        </div>

        <ul class="navbar-nav">
          <!--
            <drop-down
              icon="nc-icon nc-bell-55"
              tag="li"
              position="right"
              direction="none"
              class="nav-item btn-rotate dropdown">
              <template #title>
                <a
                  slot-scope="{isOpen}"
                  :aria-expanded="isOpen"
                  class="nav-link dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true">
                  <i class="nc-icon nc-bell-55"/>
                  <p>
                    <span class="d-lg-none d-md-block">Some Actions</span>
                  </p>
                </a>
              </template>
              <a
                class="dropdown-item"
                href="#">Action</a>
              <a
                class="dropdown-item"
                href="#">Another action</a>
              <a
                class="dropdown-item"
                href="#">Something else here</a>
            </drop-down>
            -->
          <li class="nav-item">
            <a
              class="nav-link"
              href="#"
              @click.prevent="$store.dispatch('account/logout')"
            >
              Logout
            </a>
          </li>
        </ul>
      </div>
    </template>
  </navbar>
</template>

<script>
import { Navbar, NavbarToggleButton } from '../../UIComponents';

export default {
  components: {
    Navbar,
    NavbarToggleButton,
  },
  data() {
    return {
      searchField: '',
      activeNotifications: false,
      showNavbar: false,
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    minimizeSidebar() {
      this.$sidebar.toggleMinimize();
    },
    toggleNavbar() {
      this.showNavbar = !this.showNavbar;
    },
    logout() {
      this.$router.logout();
      this.$router.push('/login');
    },
    search() {
      this.$router.push({
        name: 'Search',
        query: { title: this.searchField },
      });
    },
    clearField() {
      this.searchField = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar-menu {
  display: flex;
  width: 100%;
}

.search-form {
  width: 100%;
}
</style>
