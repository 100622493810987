<template>
  <div>
    <div class="row staff-delegation-container">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Statuts CIIP</h4>
            <button
              v-if="isAdmin"
              class="btn btn-icon btn-round btn-update-staff-delegation"
              type="button"
              title="Modifier la délégation du personnel"
              @click="toggleStaffDelegation()"
            >
              <i class="fa fa-pencil-square-o" />
            </button>
          </div>
          <div class="card-body px-8">
            <div v-if="isEditing">
              <rich-text-editor v-model="staffDelegationText" />
              <p-button
                type="success"
                round
                @click="updateStaffDelegationText()"
              >
                Mettre à jour
              </p-button>
              <p-button type="danger" round @click="toggleStaffDelegation()">
                Annuler
              </p-button>
              <div class="my-4">
                <span class="font-italic"
                  >Pour ajouter ou modifier un document, veuillez le rajouter
                  dans la section ci-dessous et appuyer sur le boutton partager
                  pour récupérer son lien. Écrivez ensuite le texte
                  correspondant au lien dans l'éditeur riche, sélectionnez-le,
                  cliquez sur le bouton "lien" de la bar d'outil de l'éditeur
                  riche et mettez le lien reçu lors de l'étape précédente.</span
                >
              </div>
              <documents-crud-list
                title="Documents de la délégation du personnel"
                category="Délégation du personnel"
              />
            </div>
            <div v-else>
              <div class="staff-delegation-text" v-html="staffDelegationText" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MixinFetchData from '@/mixins/mixinFetchData';
import ROLES from '@/services/Roles';
import DocumentsCrudList from './UIComponents/DocumentsCrudList.vue';
import RichTextEditor from './UIComponents/richTextEditor/RichTextEditor.vue';

export default {
  components: {
    RichTextEditor,
    DocumentsCrudList,
  },
  mixins: [MixinFetchData],
  data() {
    return {
      staffDelegationText: '',
      isEditing: false,
    };
  },
  computed: {
    isAdmin() {
      return (
        this.$store.getters['account/roles'].includes(ROLES.ADMINISTRATOR)
        || this.$store.getters['account/roles'].includes(ROLES.POWER_USER)
      );
    },
  },
  mounted() {
    this.getStaffDelegationText();
  },
  methods: {
    toggleStaffDelegation() {
      this.isEditing = !this.isEditing;
      if (this.isEditing === false) {
        this.getStaffDelegationText();
      }
    },
    getStaffDelegationText() {
      this.$http
        .get(`${process.env.VUE_APP_INTRANET_API_URL}/staffDelegation/1`)
        .then((result) => {
          this.staffDelegationText = result.data.staffDelegationText;
        });
    },
    updateStaffDelegationText() {
      this.errorMessage = null;

      this.$http
        .patch(`${process.env.VUE_APP_INTRANET_API_URL}/staffDelegation/1`, {
          staffDelegationText: this.staffDelegationText,
        })
        .then(() => this.toggleStaffDelegation())
        .catch((error) => {
          this.errorMessage = error.response.data.message;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: $mobile-max-width) {
  .staff-delegation-container {
    margin-left: -30px;
    margin-right: -30px;
  }
}

.btn-update-staff-delegation {
  position: absolute !important;
  right: 1.7em;
  top: 0;
}
</style>

<style lang="scss">
.staff-delegation-text {
  font-size: 14px !important;
}

.staff-delegation-text h1,
.staff-delegation-text h2,
.staff-delegation-text h3 {
  margin-bottom: 0.5rem !important;
  margin-top: 1.2rem !important;
}

.staff-delegation-text h1 {
  font-size: 1.57em !important;
  line-height: 1.4em !important;
  margin-bottom: 15px !important;
}

.staff-delegation-text h2 {
  font-size: 1em !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
}

.staff-delegation-text h3 {
  font-weight: bolder !important;
}

.ql-toolbar {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 1;
}
</style>
