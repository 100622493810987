import { createApp } from 'vue';
import { configure } from 'vee-validate';

import './pollyfills';
import Notifications from '@kyvg/vue3-notification';
import {
  ElSelect, ElOption, ElTable, ElTableColumn,
} from 'element-plus';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import axios from 'axios';
import router from '@/router/router';
import store from '@/store';
import App from '@/App.vue';
import { verifyAndRenewToken } from '@/services/AuthService';

// Plugins
import GlobalComponents from './globalComponents';
import GlobalDirectives from './globalDirectives';
import SideBar from './components/UIComponents/SidebarPlugin';

// library imports
import './assets/sass/paper-dashboard.scss';
import './assets/sass/element_variables.scss';
import './assets/sass/demo.scss';
import './assets/sass/style.scss';
import './assets/sass/style-migration-vue3.scss';
import './assets/sass/style-mobile.scss';
import 'element-plus/theme-chalk/index.css';
import '@noction/vue-bezier/dist/style.css';
import 'vue3-carousel/dist/carousel.css';

import sidebarLinks from './sidebarLinks';
import './registerServiceWorker';

import 'moment/locale/fr';

const moment = require('moment');

moment.locale('fr');

configure({
  validateOnBlur: true,
  validateOnChange: true,
  validateOnInput: true,
  validateOnModelUpdate: true,
});

// eslint-disable-next-line import/prefer-default-export
export const app = createApp({
  extends: App,
  beforeCreate() {
    this.$store.dispatch('initialiseStore');
  },
});

app.use(router);
app.use(store);

// plugin setup
app.use(GlobalDirectives);
app.use(GlobalComponents);
app.use(Notifications);
app.use(SideBar, { sidebarLinks });
app.use(ElOption);
app.use(ElSelect);
app.use(ElTable);
app.use(ElTableColumn);

// eslint-disable-next-line no-restricted-syntax
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

// Axios http requests and set as $http provider globally
const instance = axios.create();
instance.interceptors.request.use(async (config) => {
  const configInstance = config;
  if (store.getters['account/token']) {
    // Check if the token is expired, if it is, it will try to login again to generate a new token
    await verifyAndRenewToken();
    configInstance.headers.Authorization = `Bearer ${store.getters['account/token']}`;
  }

  return configInstance;
});
app.config.globalProperties.$http = instance;

app.mount('#app');
