<template>
  <component
    :is="baseComponent"
    :to="link.path ? link.path : '/'"
    :class="{ active: isActive }"
    tag="li"
    v-if="checkAdmin"
  >
    <a
      v-if="isMenu"
      data-toggle="collapse"
      href="#"
      @click.prevent="collapseMenu"
    >
      <i :class="link.icon" />
      <p>
        {{ link.name }}
        <b class="caret" :class="{ rotated: !collapsed }" />
      </p>
    </a>
    <collapse-transition v-if="this.slots.default || this.isMenu">
      <div v-show="!collapsed" class="collapse-menu">
        <ul class="nav">
          <slot />
        </ul>
      </div>
    </collapse-transition>
    <slot
      v-if="children.length === 0 && !this.slots.default && link.path"
      name="title"
    >
      <component
        :is="elementType(link, false)"
        :to="link.path"
        :class="{ active: link.active }"
        :target="link.target"
        :rel="link.rel"
        :href="link.path"
        @click.stop="onItemClick"
      >
        <template v-if="addLink">
          <span class="icon-sidebar">{{ link.name.substring(0, 1) }}</span>
          <span class="sidebar-normal">{{ link.name }}</span>
        </template>
        <template v-else>
          <i :class="link.icon" />
          <p>{{ link.name }}</p>
        </template>
      </component>
    </slot>
  </component>
</template>

<script>
import { CollapseTransition } from '@noction/vue-bezier';
import { useSlots } from 'vue';
import ROLES from '@/services/Roles';

export default {
  components: {
    CollapseTransition,
  },
  props: {
    menu: {
      type: Boolean,
      default: false,
      description: 'Whether item is a menu containing multiple items',
    },
    link: {
      type: Object,
      default: () => ({
        name: '',
        path: '',
        children: [],
      }),
      description: 'Link object',
    },
  },
  provide() {
    return {
      addLink: this.addChild,
      removeLink: this.removeChild,
    };
  },
  inject: {
    addLink: { default: null },
    removeLink: { default: null },
    autoClose: { default: false },
  },
  data() {
    return {
      children: [],
      collapsed: true,
      slots: useSlots(),
    };
  },
  computed: {
    checkAdmin() {
      return this.link.isAdmin === true ? this.isAdmin : true;
    },
    isAdmin() {
      return (
        this.$store.getters['account/roles'].includes(ROLES.ADMINISTRATOR)
        || this.$store.getters['account/roles'].includes(ROLES.POWER_USER)
      );
    },
    baseComponent() {
      // return this.isMenu || this.link.isRoute ? 'li' : 'router-link';
      return 'li';
    },
    isMenu() {
      return this.children.length > 0 || this.menu === true;
    },
    isActive() {
      if (this.$route) {
        const isParentLinkActive = this.children.find((c) => this.$route.path.startsWith(c.link.path));
        const isCurrentLinkSelected = this.$route.path.startsWith(
          this.link.path,
        );
        if (
          isParentLinkActive !== undefined
          || isCurrentLinkSelected
          || this.$route.path === this.link.path
        ) {
          return true;
        }
      }
      return false;
    },
  },
  mounted() {
    if (this.addLink) {
      this.addLink(this);
    }
    if (this.link.collapsed !== undefined) {
      this.collapsed = this.link.collapsed;
    }
    if (this.isActive && this.isMenu) {
      this.collapsed = false;
    }
  },
  unmounted() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
    if (this.removeLink) {
      this.removeLink(this);
    }
  },
  methods: {
    addChild(item) {
      const index = this.$slots.default().indexOf(item.subTree);
      this.children.splice(index, 0, item);
    },
    removeChild(item) {
      const tabs = this.children;
      const index = tabs.indexOf(item);
      tabs.splice(index, 1);
    },
    elementType(link, isParent = true) {
      if (link.isRoute === false) {
        return isParent ? 'li' : 'a';
      }
      return 'router-link';
    },
    collapseMenu() {
      this.collapsed = !this.collapsed;
    },
    onItemClick() {
      if (this.autoClose) {
        this.$sidebar.showSidebar = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.caret.rotated {
  transform: rotate(180deg);
}

.icon-sidebar {
  padding-left: 12px;
  padding-right: 25px;
}
</style>
