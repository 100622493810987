import routes from '@/routes/routes';
import store from '@/store';
import { app } from '../main';

export const isUserAllowedByRoles = (rolesNeeded) => {
  const rolesUser = store.getters['account/roles'];
  return rolesNeeded.filter((role) => rolesUser.includes(role)).length > 0;
};

export const isUserAllowedByLvl = (rolesNeeded) => {
  if (rolesNeeded.lvl === 0) {
    return true;
  }

  const rolesUser = store.getters['account/roles'];
  let isLvlEnough = false;
  rolesUser.forEach((role) => {
    if (role.value >= rolesNeeded.lvl) {
      isLvlEnough = true;
    }
  });
  return isLvlEnough;
};

export const formatRoutes = (routesToFormat, parent) => routesToFormat.flatMap((route) => {
  if (route.children) {
    return formatRoutes(route.children, route.path);
  }
  return [
    {
      ...route,
      path: parent + route.path,
    },
  ];
});

export const getRolesNeeded = (to) => {
  const routesFormatted = formatRoutes(routes, '');
  const toArray = to.path.split('/').filter((e) => e !== '');
  const routesPossible = routesFormatted.filter((route) => {
    const routeArray = route.path.split('/').filter((e) => e !== '');
    return (
      routeArray.length === toArray.length
      && !routeArray.find(
        (element, index) => !element.startsWith(':') && element !== toArray[index],
      )
    );
  });
  return routesPossible.length > 0 ? routesPossible[0].permissions : null;
};

export const verifyAndRenewToken = async () => {
  if (
    store.getters['account/token']
    && new Date().getTime() > store.getters['account/expiration']
  ) {
    store.commit('account/clear'); // We need to clear because we need a new token

    try {
      await store.dispatch('account/login');

      if (store.getters['account/token']) {
        app.config.globalProperties.$notify({
          message: 'Vous avez été automatiquement reconnecté !',
          title: 'Session expirée',
          icon: 'fa fa-exclamation-triangle',
          horizontalAlign: 'right',
          verticalAlign: 'bottom',
          type: 'warning',
          timeout: 10000,
        });
      } else {
        throw new Error('Token expired and not authenticated');
      }
    } catch (error) {
      // eslint-disable-next-line
      console.log('Shib session is not available', error);
      app.config.globalProperties.$notify({
        message:
          'Votre session a expiré, veuillez vous reconnecter! Vous allez être redirigé sur la page de login.',
        title: 'Session expirée',
        icon: 'fa fa-exclamation-triangle',
        horizontalAlign: 'right',
        verticalAlign: 'bottom',
        type: 'warning',
        timeout: 3000,
      });

      // redirect to login page after 3s
      await new Promise((r) => setTimeout(r, 3000));
      window.location = `${process.env.VUE_APP_IDM_WEBAPP_URL}/login?appURL=${window.location.href}`;
    }
  }
};

export const isAllowed = (to) => {
  const rolesNeeded = getRolesNeeded(to);

  // PERMISSION DEFINED BY ROLES
  if (rolesNeeded && rolesNeeded.roles) {
    return isUserAllowedByRoles(rolesNeeded.roles);
  }

  // PERMISSION DEFINED BY LVL
  if (rolesNeeded && rolesNeeded.lvl !== null) {
    return isUserAllowedByLvl(rolesNeeded);
  }
  // NO PERMISSION DEFINED = public

  return true;
};
