<template>
  <div>
    <details-modal
      :show="show"
      :current-operation="currentOperation"
      :loading="loading"
      @close="closeModal"
      @cancel="closeModal"
      @create="createApplication"
      @update="updateApplication"
      @delete="deleteApplication"
    >
      <Form :validation-schema="validationSchema" validate-on-mount>
        <div class="form-group">
          <label class="control-label">*Identifiant</label>
          <Field
            name="identifier"
            v-model="application.identifier"
            v-slot="{ field, errorMessage }"
          >
            <input v-bind="field" class="form-control" type="text" />
            <small v-show="errorMessage" class="text-danger">
              L'identifiant est requis.
            </small>
          </Field>
        </div>
        <div class="form-group">
          <label class="control-label">*Nom</label>
          <Field
            name="name"
            v-model="application.name"
            v-slot="{ field, errorMessage }"
          >
            <input v-bind="field" class="form-control" type="text" />
            <small v-show="errorMessage" class="text-danger">
              Le nom est requis.
            </small>
          </Field>
        </div>
        <p>
          <label class="control-label">*Catégorie</label>
          <el-select
            v-model="application.categorie"
            id="select-type"
            class="select-info col-md-12 select-one-line-per-item"
            size="large"
            placeholder="Sélectionner"
            required="true"
            name="categorie"
            filterable
          >
            <el-option
              v-for="c in categories"
              :key="c.value"
              :label="`${c.name}`"
              :value="c.value"
            />
          </el-select>

          <Field
            name="categorie"
            v-model="application.categorie"
            v-slot="{ errorMessage }"
          >
            <small v-show="errorMessage" class="text-danger">
              La catégorie est requise.
            </small>
          </Field>
        </p>
        <div class="form-group">
          <label class="control-label">Modèle</label>
          <input
            v-model="application.modal"
            class="form-control"
            type="text"
            name="modal"
          />
        </div>
        <div class="form-group">
          <label class="control-label">Version</label>
          <input
            v-model="application.version"
            class="form-control"
            type="text"
            name="version"
          />
        </div>
        <div class="form-group">
          <label class="control-label">Système d'exploitation</label>
          <input
            v-model="application.operatingSystem"
            class="form-control"
            type="text"
            name="operatingSystem"
          />
        </div>
        <div class="form-group">
          <label class="control-label">Date d'achat</label>
          <el-date-picker
            id="purchaseDate"
            v-model="application.purchaseDate"
            type="date"
            placeholder="Choisissez un jour"
          />
        </div>
        <div class="form-group">
          <label class="control-label">Prix (unitaire)</label>
          <el-input-number
            v-model="application.unitPrice"
            :precision="2"
            :min="0"
          ></el-input-number>
        </div>
        <p>
          <label class="control-label">Fournisseur</label>
          <el-select
            id="select-type"
            v-model="application.providerId"
            class="select-info col-md-12 select-one-line-per-item"
            size="large"
            placeholder="Sélectionner"
            required="true"
            name="provider"
            filterable
            clearable
          >
            <el-option
              v-for="c in providers"
              :key="c.id"
              :label="`${c.name}`"
              :value="c.id"
            />
          </el-select>
        </p>
        <p>
          <label for="select-function">Responsable</label>
          <el-select
            id="select-function"
            v-model="application.responsibleId"
            class="select-info col-md-12 select-one-line-per-item"
            size="large"
            name="responsibleId"
            placeholder="Sélectionner"
            filterable
            clearable
          >
            <el-option
              v-for="c in collaboratorsName"
              :key="c.id"
              :label="`${c.lastname} ${c.firstname}`"
              :value="c.id"
            />
          </el-select>
        </p>
        <p>
          <label for="select-user">Utilisateurs</label>
          <el-select
            id="select-user"
            v-model="application.usersIds"
            class="select-info col-md-12 select-one-line-per-item"
            size="large"
            name="users"
            placeholder="Sélectionner"
            filterable
            clearable
            :multiple="true"
          >
            <el-option
              v-for="c in allCollaboratorsName"
              :key="c.id"
              :label="`${c.lastname} ${c.firstname}`"
              :value="c.id"
            >
              <el-tooltip
                v-if="!c.isActive"
                content="Inactif"
                placement="bottom"
              >
                <i class="fa fa-user-times"></i>
              </el-tooltip>
              <span> {{ c.lastname }} {{ c.firstname }}</span>
            </el-option>
          </el-select>
        </p>
        <div class="form-group">
          <label class="control-label">URL</label>
          <input
            v-model="application.url"
            class="form-control"
            type="text"
            name="url"
          />
        </div>
        <div class="form-group">
          <label class="control-label">Description</label>
          <textarea
            v-model="application.description"
            class="form-control"
            name="description"
            maxlength="1024"
          />
        </div>
        <div class="form-group">
          <label class="control-label">Remarque</label>
          <textarea
            v-model="application.remark"
            class="form-control"
            name="remark"
          />
        </div>
        <p><label>* champs obligatoires</label></p>
      </Form>
      <p v-if="errorMessage" class="error-message-in-modal">
        {{ errorMessage }}
      </p>
    </details-modal>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import {
  ElDatePicker as DatePicker,
  ElInputNumber as InputNumber,
  ElTooltip as Tooltip,
} from 'element-plus';
import { Form, Field } from 'vee-validate';
import * as yup from 'yup';
import MixinFetchData from '@/mixins/mixinFetchData';
import APPLICATION_CATEGORIES from '@/enums/ApplicationsCategories';
import DetailsModal from './DetailsModal.vue';

export default {
  components: {
    DetailsModal,
    [DatePicker.name]: DatePicker,
    [InputNumber.name]: InputNumber,
    [Tooltip.name]: Tooltip,
    Form,
    Field,
  },
  mixins: [MixinFetchData],
  props: ['show', 'currentOperation', 'applicationProps'],
  data() {
    return {
      loading: false,
      providers: [],
      errorMessage: '',
      validationSchema: {
        identifier: yup.string().required(),
        categorie: yup.string().required(),
        name: yup.string().required(),
      },
      application: {},
      categories: APPLICATION_CATEGORIES,
      typeInput: {
        invalid: false,
      },
    };
  },
  watch: {
    show: {
      handler(newVal) {
        if (newVal) {
          this.fetchAllCollaboratorsNames();
          this.fetchCollaboratorsNames();
          this.fetchProviders();
        }
      },
    },
    applicationProps: {
      handler(newVal) {
        this.application = newVal;
      },
    },
  },
  computed: {
    inactifIcon() {
      return '<i class="fas fa-user-slash"/>';
    },
  },
  methods: {
    fetchProviders() {
      this.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/enterprises/search/findAll`,
        )
        .then((response) => {
          this.providers = response.data._embedded.enterprises
            ? response.data._embedded.enterprises
            : [];
        });
    },
    async createApplication() {
      // Validate each field with vee-validate, and post the new application.
      const validationSchemaObject = yup.object(this.validationSchema);
      const isApplicationValid = await validationSchemaObject.isValid(
        this.application,
      );

      if (isApplicationValid) {
        this.loading = true;
        this.errorMessage = null;

        this.$http
          .post(
            `${process.env.VUE_APP_INTRANET_API_URL}/commands/applications/`,
            {
              ...this.application,
            },
          )
          .then(() => {
            this.closeModal();
          })
          .catch((error) => {
            this.errorMessage = "Une erreur est survenue lors de l'ajout de l'application, veuillez réessayer ou contacter le webmaster.";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.errorMessage = 'Un ou plusieurs champs sont invalides.';
      }
    },
    async updateApplication() {
      // Validate each field with vee-validate, and update the application.
      const validationSchemaObject = yup.object(this.validationSchema);
      const isApplicationValid = await validationSchemaObject.isValid(
        this.application,
      );

      if (isApplicationValid) {
        this.errorMessage = null;

        this.$http
          .patch(
            `${process.env.VUE_APP_INTRANET_API_URL}/commands/applications/${this.application.id}`,
            {
              ...this.application,
            },
          )
          .then(() => {
            this.closeModal();
          })
          .catch((error) => {
            this.errorMessage = "Une erreur est survenue lors de la modification de l'application, veuillez réessayer ou contacter le webmaster.";
          });
      } else {
        this.errorMessage = 'Un ou plusieurs champs sont invalides.';
      }
    },
    deleteApplication() {
      this.errorMessage = null;

      Swal.fire({
        title: 'Êtes-vous sûr de vouloir supprimer cette application ?',
        text: "Il ne sera pas possible d'annuler cette suppression !",
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill',
        },
        confirmButtonText: 'Oui, effacer',
        cancelButtonText: 'Non, conserver',
        buttonsStyling: false,
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.$http
              .delete(
                `${process.env.VUE_APP_INTRANET_API_URL}/applications/${this.application.id}`,
              )
              .then(() => {
                this.closeModal();
              })
              .catch((error) => {
                this.errorMessage = "Une erreur est survenue lors de la suppression de l'application, veuillez réessayer ou contacter le webmaster.";
              });
          }
        })
        .catch(() => {});
    },
    setSelectedTypeId(typeId) {
      this.application.typeId = typeId;
    },
    closeModal() {
      this.$emit('close');
      this.application.typeId = null;
      this.errorMessage = '';
      this.application = {};
    },
  },
};
</script>
