<template>
  <div>
    <details-modal
      :show="show && !initLoading"
      current-operation="edit"
      :loading="loading"
      @close="closeModal"
      @cancel="closeModal"
      @update="updateRecord"
      @delete="deleteRecord"
    >
      <Form :validation-schema="validationSchema" validate-on-mount>
        <div class="form-group">
          <label class="control-label">*Titre</label>
          <Field
            name="title"
            v-model="document.title"
            v-slot="{ field, errorMessage }"
          >
            <input v-bind="field" class="form-control" type="text" />
            <small v-show="errorMessage" class="text-danger">
              Le titre est requis.
            </small>
          </Field>
        </div>
        <p>
          <label for="select-function">*Catégorie</label>
          <el-select
            id="select-category"
            v-model="document.category.id"
            class="select-info col-md-12 select-one-line-per-item"
            size="large"
            placeholder="Sélectionner"
            @change="handleCategoryChange"
          >
            <el-option
              v-for="category in categories"
              :key="category.id"
              :label="category.name"
              :value="category.id"
            />
          </el-select>
        </p>
        <tag-select
          :tagId="document.tagId"
          :categoryId="document.category.id"
          @setSelectedTagId="setSelectedTagId"
        />
        <div class="form-group">
          <label class="control-label"
            >Position (max: {{ maxPositionDocumentInCateogry }})</label
          >
          <el-input-number
            v-if="!initLoading"
            v-model="document.position"
            name="position"
            :min="1"
            :max="maxPositionDocumentInCateogry"
          />
        </div>
        <p><label>* champs obligatoires</label></p>
      </Form>
      <p v-if="errorMessage" class="error-message-in-modal">
        {{ errorMessage }}
      </p>
    </details-modal>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import { ElInputNumber as InputNumber } from 'element-plus';
import { Form, Field } from 'vee-validate';
import * as yup from 'yup';
import DetailsModal from './DetailsModal.vue';
import TagSelect from '../UIComponents/TagSelect.vue';

export default {
  components: {
    DetailsModal,
    TagSelect,
    [InputNumber.name]: InputNumber,
    Form,
    Field,
  },
  props: ['show', 'documentProps'],
  data() {
    return {
      errorMessage: '',
      categories: [],
      document: {
        title: null,
        tagId: null,
        position: 1,
        category: {
          id: null,
          name: null,
        },
      },
      currentCategoryId: null,
      totalDocumentsInCategory: 0,
      validationSchema: {
        title: yup.string().required(),
      },
      loading: false,
      initLoading: true,
    };
  },
  computed: {
    maxPositionDocumentInCateogry() {
      // This computed value is to be similar then then create modal and to be coherent
      // if the user changed the category, possibility to had at the end of the list
      const haveChangeCategory = this.document.category.id !== this.currentCategoryId;
      const haveChangeCategoryIncrement = haveChangeCategory ? 1 : 0;
      return this.totalDocumentsInCategory + haveChangeCategoryIncrement;
    },
  },
  watch: {
    documentProps: {
      handler(newVal) {
        if (newVal) {
          this.document = {
            ...newVal,
            position: newVal.position ? newVal.position + 1 : 1,
            tagId: newVal.tag ? newVal.tag.id : null,
          };
          this.currentCategoryId = this.document.category.id;
        }
      },
    },
    show: {
      handler(newVal) {
        if (newVal) {
          this.fetchData();
        }
      },
    },
  },
  methods: {
    async updateRecord() {
      // Validate each field with vee-validate, and post the new document.
      const validationSchemaObject = yup.object(this.validationSchema);
      const isDocumentValid = await validationSchemaObject.isValid(
        this.document,
      );

      if (isDocumentValid) {
        this.errorMessage = null;

        this.loading = true;

        const data = {
          title: this.document.title,
          isExternal: this.document.isExternal,
          path: this.document.path,
          categoryId: this.document.category.id,
          tagId: this.document.tagId,
          position: this.document.position - 1,
        };

        this.$http
          .patch(
            `${process.env.VUE_APP_INTRANET_API_URL}/commands/documents/${this.document.id}`,
            data,
          )
          .then(() => {
            this.closeModal();
          })
          .catch((error) => {
            this.errorMessage = 'Une erreur est survenue lors du chargement des données, veuillez réessayer ou contacter le webmaster.';
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.errorMessage = 'Un ou plusieurs champs sont invalides.';
      }
    },
    deleteRecord() {
      Swal.fire({
        title: 'Êtes-vous sûr de vouloir supprimer ce document ?',
        text: "Il ne sera pas possible d'annuler cette suppression !",
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill',
        },
        confirmButtonText: 'Oui, effacer',
        cancelButtonText: 'Non, conserver',
        buttonsStyling: false,
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.$http
              .delete(
                `${process.env.VUE_APP_INTRANET_API_URL}/commands/documents/${this.document.id}`,
              )
              .then(() => {
                // Refresh the crud-list component's data.
                this.closeModal();
              })
              .catch((error) => {
                this.errorMessage = error.response.data.message;
              });
          }
        })
        .catch(() => {});
    },
    closeModal() {
      this.$emit('close');
      this.document = {
        title: null,
        tagId: null,
        position: 1,
        isExternal: false,
        category: {
          id: 0,
          name: '',
        },
      };
      this.categories = [];
    },
    fetchNumberOfDocuments() {
      return this.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/documents/search/countByCategoryId?categoryId=${this.document.category.id}`,
        )
        .then((response) => {
          this.totalDocumentsInCategory = response.data;
        });
    },
    fetchAllCategories() {
      return this.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/documentsCategories?sort=name,asc`,
        )
        .then((response) => {
          this.categories = response.data._embedded.documentsCategories;
        })
        .catch((error) => {
          this.errorMessage = 'Une erreur est survenue lors du chargement des données, veuillez réessayer ou contacter le webmaster.';
        });
    },
    fetchData() {
      this.initLoading = true;
      this.fetchAllCategories()
        .then(() => this.fetchNumberOfDocuments())
        .finally(() => {
          this.initLoading = false;
        });
    },
    setSelectedTagId(tagId) {
      this.document.tagId = tagId;
    },
    handleCategoryChange() {
      this.document.position = 1;
      this.fetchNumberOfDocuments();
    },
  },
};
</script>

<style lang="scss" scoped></style>
