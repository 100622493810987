<template>
  <div>
    <button class="btn btn-export" @click="exportDirectoriesToXlsx">
      Exporter la liste au format Excel
    </button>
    <div class="row">
      <div class="col-md-12">
        <directory-modal
          :show="showModal"
          :directoryProps="directorySelected"
          :currentOperation="currentOperation"
          @close="closeModal"
        />
        <crud-list
          ref="crudList"
          :fetch-data="fetchDirectories"
          title="Lecteurs Réseaux et Dossiers"
          @create="createDirectory"
        >
          <button-column
            type="edit"
            button-title="Modifier le dossier"
            :show-on-mobile="true"
            :update-function="updateDirectory"
          />
          <el-table-column label="Chemin" min-width="200" prop="path">
            <template #default="slotProps">
              <div class="table-items-list-item">
                {{ `${slotProps.row.path}` }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Utilisateur(s) autorisé(s)" min-width="180">
            <template #default="slotProps">
              <div class="table-items-list-item">
                <div
                  v-for="access in slotProps.row.access"
                  :key="access.id"
                  class="table-double-cells"
                >
                  <span
                    v-if="access.collaborator !== null"
                    :class="{ inactif: !access.collaborator.isActive }"
                    class="user"
                  >
                    <router-link
                      :to="{
                        name: 'CollaboratorDetails',
                        params: { id: access.collaborator.id },
                      }"
                    >
                      <el-tooltip
                        v-if="!access.collaborator.isActive"
                        content="Inactif"
                        placement="bottom"
                      >
                        <i class="fa fa-user-times"></i>
                      </el-tooltip>
                      {{ access.collaborator.firstname }}
                      {{ access.collaborator.lastname }}
                      <el-tag type="info">{{ access.accessRightStr }}</el-tag>
                    </router-link>
                  </span>
                  <br />
                </div>
              </div>
            </template>
          </el-table-column>
          <button-column
            type="edit"
            button-title="Modifier le dossier"
            :show-on-mobile="false"
            :update-function="updateDirectory"
          />
        </crud-list>
      </div>
    </div>
  </div>
</template>
<script>
import { ElTag, ElTooltip } from 'element-plus';
import XLSX from 'xlsx';
import CrudList from './UIComponents/CrudList.vue';
import ButtonColumn from './UIComponents/ButtonColumn.vue';
import DirectoryModal from './Modals/DirectoryModal.vue';
import DIRECTORY_ACCESS from '../../enums/DirectoryAccess';

export default {
  components: {
    CrudList,
    ButtonColumn,
    DirectoryModal,
    [ElTag.name]: ElTag,
    [ElTooltip.name]: ElTooltip,
  },
  data() {
    return {
      showModal: false,
      directorySelected: null,
      currentOperation: 'create',
    };
  },
  methods: {
    fetchDirectories(pageNumber, pageSize) {
      return this.$http
        .get(
          `${
            process.env.VUE_APP_INTRANET_API_URL
          }/directories/search/findAllPage?projection=directoryProjection&page=${
            pageNumber - 1
          }&size=${pageSize}`,
        )
        .then((response) => {
          let { directories } = response.data._embedded;
          directories = directories.map((directory) => ({
            ...directory,
            access: directory.access.map((access) => ({
              ...access,
              accessRightStr: DIRECTORY_ACCESS.get(access.accessRight),
            })),
          }));
          return {
            records: directories,
            page: response.data.page ? response.data.page : 0,
          };
        });
    },
    fetchAllDirectories() {
      return this.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/directories/search/findAll?projection=directoryProjection`,
        )
        .then((response) => {
          let { directories } = response.data._embedded;
          directories = directories.map((directory) => ({
            ...directory,
            access: directory.access.map((access) => ({
              ...access,
              accessRightStr: DIRECTORY_ACCESS.get(access.accessRight),
            })),
          }));
          return directories;
        });
    },
    updateDirectory(row, event) {
      event.stopPropagation();

      this.currentOperation = 'edit';
      this.showModal = true;

      this.directorySelected = { ...row };
    },
    createDirectory() {
      this.currentOperation = 'create';
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.$refs.crudList.fetch();
    },
    exportDirectoriesToXlsx() {
      const wsData = [['Chemin', 'Utilisateur(s) autorisé(s)']];

      this.fetchAllDirectories().then((directories) => {
        directories.forEach((directory) => {
          const collaboratorsStr = directory.access
            .map(
              (access) => `${access.collaborator.firstname} ${access.collaborator.lastname} (${access.accessRightStr})`,
            )
            .join(', ');
          wsData.push([directory.path, collaboratorsStr]);
        });

        const ws = XLSX.utils.aoa_to_sheet(wsData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'LecteursRéseauxEtDossiers');
        XLSX.writeFile(wb, 'ciip_lecteurs_réseaux_et_dossiers.xlsx');
        this.$notify({
          title: 'Téléchargement',
          text: 'Téléchargement du fichier contenant les lecteurs réseaux et dossiers',
          type: 'success',
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.access-right {
  font-size: 0.8em;
  font-style: italic;
}

.user a {
  color: inherit !important;
}

.inactif {
  color: rgb(169, 50, 50);
}
</style>
