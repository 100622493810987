<template>
  <carousel :autoplay="slideTimeout">
    <slide key="agendeCiipNews">
      <carousel-slide-content
        :image-url="lastAgendaCiipNews.image"
        :title="lastAgendaCiipNews.title"
        :description="lastAgendaCiipNews.description"
        :link="lastAgendaCiipNews.link"
      />
    </slide>

    <slide key="agendeCiipManifestation">
      <carousel-slide-content
        :image-url="lastAgendaCiipManifestation.image"
        :title="lastAgendaCiipManifestation.title"
        :description="lastAgendaCiipManifestation.description"
        :link="lastAgendaCiipManifestation.link"
      />
    </slide>

    <slide v-for="slide in slides" :key="slide.id">
      <carousel-slide-content
        :title="slide.title"
        :description="slide.content"
        :link="slide.link"
      />
    </slide>

    <template #addons>
      <navigation />
      <pagination />
    </template>
  </carousel>
</template>

<script>
import {
  Carousel, Slide, Pagination, Navigation,
} from 'vue3-carousel';
// We need to use axios without the service in order to make requests to Agenda CIIP.
import axios from 'axios';
import CarouselSlideContent from './CarouselSlideContent.vue';

export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    CarouselSlideContent,
  },
  props: {
    slides: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      slideTimeout: 10000,
      lastAgendaCiipNews: {},
      lastAgendaCiipManifestation: {},
    };
  },
  mounted() {
    this.fetchLastNewsFromAgendaCiip();
    this.fetchLastManifestationFromAgendaCiip();
  },
  methods: {
    fetchLastNewsFromAgendaCiip() {
      return axios
        .get(
          `${process.env.VUE_APP_AGENDA_API_URL}/newsEntities/search/lastNews?projection=newsForIntranetDTO`,
        )
        .then((response) => {
          this.lastAgendaCiipNews = {
            ...response.data,
            image: `${process.env.VUE_APP_AGENDA_API_URL}/files/img/${response.data.image}`,
            link: `${process.env.VUE_APP_AGENDA_WEBAPP_URL}/news`,
          };
        })
        .catch((error) => {
          this.lastAgendaCiipNews = {
            title: 'Aucune actualité disponible',
            description:
              "Il n'y a actuellement aucune actualité disponible sur Agenda CIIP.",
            image: `/static/img/slides/IMG (${Math.ceil(
              Math.random() * 139,
            )}).jpg`,
            link: `${process.env.VUE_APP_AGENDA_WEBAPP_URL}/`,
          };
        });
    },
    fetchLastManifestationFromAgendaCiip() {
      return axios
        .get(
          `${
            process.env.VUE_APP_AGENDA_API_URL
          }/manifestationEntities/search/nextManifestationAfterDate?date=${new Date().toUTCString()}&projection=manifestationForIntranetDTO`,
        )
        .then((response) => {
          this.lastAgendaCiipManifestation = {
            title: `Prochaine manifestation : ${response.data.title}`,
            description: response.data.description,
            image: `${process.env.VUE_APP_AGENDA_API_URL}/files/img/${response.data.image}`,
            link: `${process.env.VUE_APP_AGENDA_WEBAPP_URL}/event/${response.data.id}`,
          };
        })
        .catch((error) => {
          this.lastAgendaCiipManifestation = {
            title: 'Aucune manifestation à venir',
            description:
              "Il n'y a actuellement aucune manifestation prévue dans les prochains temps sur Agenda CIIP.",
            image: `/static/img/slides/IMG (${Math.ceil(
              Math.random() * 139,
            )}).jpg`,
            link: `${process.env.VUE_APP_AGENDA_WEBAPP_URL}/`,
          };
        });
    },
  },
};
</script>

<style lang="scss">
.carousel__slide {
  background-position-y: center;
  background-size: cover;
  height: $slide-height;
  position: relative;
}

.carousel__pagination {
  margin-bottom: 2em !important;
}

@media screen and (max-width: $mobile-max-width) {
  .carousel__slide {
    height: $mobile-slide-height;
  }
}

// Make the slides' height the same as the mobile screen's height when the screen's height is lesser than the slides' height.
@media screen and (max-height: calc(#{$mobile-slide-height} + #{$mobile-header-navbar-height})) {
  .carousel__slide {
    height: $mobile-slide-height-small-screen;
  }
}
</style>
