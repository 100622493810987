import { createStore } from 'vuex';

// Load store modules dynamically.
import account from './modules/account';
import settings from './modules/settings';
import collaborators from './modules/collaborators';
import image from './modules/image';
import materials from './modules/materials';

export default createStore({
  modules: {
    account,
    settings,
    collaborators,
    image,
    materials,
  },
  actions: {
    initialiseStore({ commit, dispatch }) {
      dispatch('account/init');
      dispatch('settings/init');
      dispatch('collaborators/init');
      dispatch('image/init');
      dispatch('materials/init');
    },
  },
});
