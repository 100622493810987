import { createRouter, createWebHistory } from 'vue-router';
import routes from '@/routes/routes';
import store from '@/store';
import { isAllowed, verifyAndRenewToken } from '@/services/AuthService';

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: 'active',
  scrollBehavior: (to) => {
    if (to.hash) {
      return { el: to.hash };
    }
    return {
      left: 0,
      top: 0,
    };
  },
});

router.beforeEach(async (to, from, next) => {
  if (!store.getters['account/token']) {
    try {
      await store.dispatch('account/login');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Shib session is not available', error);
      // Ignore, as we are not authentified
    }
  } else {
    // Check if the token is expired, if it is, it will try to login again to generate a new token
    await verifyAndRenewToken();
  }

  if (isAllowed(to)) {
    next();
  } else {
    // if not connected, ask for login
    if (!store.getters['account/token']) {
      window.location = `${process.env.VUE_APP_IDM_WEBAPP_URL}/login?appURL=${window.location.href}`;
    }
    // if connected but do not have writes, show a message and tell them to ask for the roles
    next({ name: 'NotAllowed' });
  }
});

export default router;
