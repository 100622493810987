// GeneralViews
// Dashboard pages
import Overview from '../components/Dashboard/Views/Dashboard/Overview.vue';
// Pages

// Mailing application specific components
import Collaborators from '../components/ApplicationViews/Collaborators.vue';
import CollaboratorDetails from '../components/ApplicationViews/CollaboratorDetails.vue';
import StaffDelegation from '../components/ApplicationViews/StaffDelegation.vue';
import DocumentsStatutsReglements from '../components/ApplicationViews/DocumentsStatutsReglements.vue';
import DocumentsOrganisationInstitutionnelleFonctions from '../components/ApplicationViews/DocumentsOrganisationInstitutionnelleFonctions.vue';
import DocumentsInformatique from '../components/ApplicationViews/DocumentsInformatique.vue';
import DocumentsInfrastructres from '../components/ApplicationViews/DocumentsInfrastructures.vue';
import DocumentsMobilite from '../components/ApplicationViews/DocumentsMobilite.vue';
import DocumentsFinances from '../components/ApplicationViews/DocumentsFinances.vue';
import DocumentsOrganisationAdministrative from '../components/ApplicationViews/DocumentsOrganisationAdministrative.vue';
import DocumentsEditionAndRedaction from '../components/ApplicationViews/DocumentsEditionAndRedaction.vue';
import DocumentsFormulaires from '../components/ApplicationViews/DocumentsFormulaires.vue';
import DocumentsRessourcesHumaines from '../components/ApplicationViews/DocumentsRessourcesHumaines.vue';
import DocumentsModeles from '../components/ApplicationViews/DocumentsModeles.vue';
import DocumentsDownload from '../components/ApplicationViews/DocumentsDownload.vue';
import Gallery from '../components/ApplicationViews/Gallery.vue';
import GalleryItem from '../components/ApplicationViews/GalleryItem.vue';
import Annuaire from '../components/ApplicationViews/Annuaire.vue';
import Extranet from '../components/ApplicationViews/Extranet.vue';
import UsefulLinks from '../components/ApplicationViews/UsefulLinks.vue';
import Partners from '../components/ApplicationViews/Partners.vue';
import Platforms from '../components/ApplicationViews/Platforms.vue';
import MaterialsComputers from '../components/ApplicationViews/MaterialsComputers.vue';
import MaterialsBeamers from '../components/ApplicationViews/MaterialsBeamers.vue';
import MaterialsDevices from '../components/ApplicationViews/MaterialsDevices.vue';
import MaterialsDictaphones from '../components/ApplicationViews/MaterialsDictaphones.vue';
import MaterialsHeadsets from '../components/ApplicationViews/MaterialsHeadsets.vue';
import MaterialsPrinters from '../components/ApplicationViews/MaterialsPrinters.vue';
import MaterialsRouters from '../components/ApplicationViews/MaterialsRouters.vue';
import MaterialsScanners from '../components/ApplicationViews/MaterialsScanners.vue';
import MaterialsServers from '../components/ApplicationViews/MaterialsServers.vue';
import MaterialsStationsVideoconferences from '../components/ApplicationViews/MaterialsStationsVideoconferences.vue';
import MaterialDetails from '../components/ApplicationViews/MaterialDetails.vue';
import Search from '../components/ApplicationViews/Search.vue';
import Cars from '../components/ApplicationViews/Cars.vue';
import Applications from '../components/ApplicationViews/Applications.vue';
import FAQ from '../components/ApplicationViews/FAQ.vue';
import NotFound from '../components/GeneralViews/NotFoundPage.vue';
import NotAllowed from '../components/GeneralViews/NotAllowed.vue';
import DashboardLayout from '../components/Dashboard/Layout/DashboardLayout.vue';
import ParentRouteLayout from '../components/Dashboard/Layout/ParentRouteLayout.vue';
import Contacts from '../components/ApplicationViews/Contacts.vue';
import Enterprises from '../components/ApplicationViews/Enterprises.vue';
import Directories from '../components/ApplicationViews/Directories.vue';
import DistributionList from '../components/ApplicationViews/DistributionList.vue';
import Letterbox from '../components/ApplicationViews/Letterbox.vue';

const routes = [
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/overview',
    children: [
      {
        path: 'overview',
        name: 'Overview',
        component: Overview,
        permissions: {
          lvl: 1,
        },
      },
      {
        path: 'collaborators',
        name: 'Collaborators',
        component: Collaborators,
        permissions: {
          lvl: 1,
        },
      },
      {
        path: 'collaborators/:id',
        name: 'CollaboratorDetails',
        component: CollaboratorDetails,
        permissions: {
          lvl: 1,
        },
      },
      {
        path: 'cars',
        name: 'Cars',
        component: Cars,
        permissions: {
          lvl: 1,
        },
      },
      {
        path: 'delegation-personnel',
        name: 'StaffDelegation',
        component: StaffDelegation,
        permissions: {
          lvl: 1,
        },
      },
      {
        path: 'materials/',
        component: ParentRouteLayout,
        name: 'Material',
        redirect: 'materials/computers',
        children: [
          {
            path: 'computers',
            name: 'Computer',
            component: MaterialsComputers,
            permissions: {
              lvl: 1,
            },
          },
          {
            path: 'headsets',
            name: 'Headsets',
            component: MaterialsHeadsets,
            permissions: {
              lvl: 1,
            },
          },
          {
            path: 'servers',
            name: 'Servers',
            component: MaterialsServers,
            permissions: {
              lvl: 1,
            },
          },
          {
            path: 'routers',
            name: 'Routers',
            component: MaterialsRouters,
            permissions: {
              lvl: 1,
            },
          },
          {
            path: 'printers',
            name: 'Printers',
            component: MaterialsPrinters,
            permissions: {
              lvl: 1,
            },
          },
          {
            path: 'devices',
            name: 'Devices',
            component: MaterialsDevices,
            permissions: {
              lvl: 1,
            },
          },
          {
            path: 'scanners',
            name: 'Scanners',
            component: MaterialsScanners,
            permissions: {
              lvl: 1,
            },
          },
          {
            path: 'dictaphones',
            name: 'Dictaphones',
            component: MaterialsDictaphones,
            permissions: {
              lvl: 1,
            },
          },
          {
            path: 'beamers',
            name: 'Beamers',
            component: MaterialsBeamers,
            permissions: {
              lvl: 1,
            },
          },
          {
            path: 'stationsvideoconferences',
            name: 'Stations de vidéoconférences',
            component: MaterialsStationsVideoconferences,
            permissions: {
              lvl: 1,
            },
          },
        ],
      },
      {
        path: 'materials/:id',
        name: 'MaterialDetails',
        component: MaterialDetails,
        permissions: {
          lvl: 1,
        },
      },
      {
        path: 'plateformes',
        name: 'Platforms',
        component: Platforms,
        permissions: {
          lvl: 1,
        },
      },
      {
        path: 'documents/',
        component: ParentRouteLayout,
        redirect: '/documents/statuts-reglements-ciip',
        children: [
          {
            path: 'statuts-reglements-ciip',
            name: 'DocumentsStatutsReglements',
            component: DocumentsStatutsReglements,
            permissions: {
              lvl: 1,
            },
          },
          {
            path: 'organisation-institutionnelle-fonctions',
            name: 'DocumentsOrganisationInstitutionnelleFonctions',
            component: DocumentsOrganisationInstitutionnelleFonctions,
            permissions: {
              lvl: 1,
            },
          },
          {
            path: 'ressources-humaines',
            name: 'DocumentsRessourcesHumaine',
            component: DocumentsRessourcesHumaines,
            premissions: {
              lvl: 1,
            },
          },
          {
            path: 'informatique',
            name: 'DocumentsInformatique',
            component: DocumentsInformatique,
            permissions: {
              lvl: 1,
            },
          },
          {
            path: 'infrastructures',
            name: 'DocumentsInfrastructures',
            component: DocumentsInfrastructres,
            permissions: {
              lvl: 1,
            },
          },
          {
            path: 'formulaires',
            name: 'DocumentsFormulaires',
            component: DocumentsFormulaires,
            permissions: {
              lvl: 1,
            },
          },
          {
            path: 'modeles',
            name: 'DocumentsModeles',
            component: DocumentsModeles,
            permissions: {
              lvl: 1,
            },
          },
          {
            path: 'mobilite',
            name: 'DocumentsMobilite',
            component: DocumentsMobilite,
            permissions: {
              lvl: 1,
            },
          },
          {
            path: 'finances',
            name: 'DocumentsFinances',
            component: DocumentsFinances,
            permissions: {
              lvl: 1,
            },
          },
          {
            path: 'organisation-administrative',
            name: 'OrganisationAdministrative',
            component: DocumentsOrganisationAdministrative,
            permissions: {
              lvl: 1,
            },
          },
          {
            path: 'edition-redaction',
            name: 'EditionEtRedaction',
            component: DocumentsEditionAndRedaction,
            permissions: {
              lvl: 1,
            },
          },
          {
            path: ':id',
            name: 'downloadDocument',
            component: DocumentsDownload,
            permissions: {
              lvl: 1,
            },
          },
        ],
      },
      {
        path: 'applications',
        name: 'Applications',
        component: Applications,
        permissions: {
          lvl: 1,
        },
      },
      {
        path: 'dossiers',
        name: 'Directories',
        component: Directories,
        permissions: {
          lvl: 1,
        },
      },
      {
        path: 'listes-distribution',
        name: 'DistributionList',
        component: DistributionList,
        permissions: {
          lvl: 1,
        },
      },
      {
        path: 'letterbox',
        name: 'Letterbox',
        component: Letterbox,
        permissions: {
          lvl: 1,
        },
      },
      {
        path: 'enterprises',
        name: 'Enterprise',
        component: Enterprises,
        permissions: {
          lvl: 2,
        },
      },
      {
        path: 'partenaires',
        name: 'Partners',
        component: Partners,
        permissions: {
          lvl: 1,
        },
      },
      {
        path: 'gallery',
        name: 'Gallery',
        component: Gallery,
        permissions: {
          lvl: 1,
        },
      },
      {
        path: 'gallery/:id',
        name: 'GalleryItem',
        component: GalleryItem,
        permissions: {
          lvl: 1,
        },
      },
      {
        path: 'annuaire',
        name: 'Annuaire',
        component: Annuaire,
        permissions: {
          lvl: 1,
        },
      },
      {
        path: 'extranet',
        name: 'Extranet',
        component: Extranet,
        permissions: {
          lvl: 1,
        },
      },
      {
        path: 'liens-utiles',
        name: 'UsefulLinks',
        component: UsefulLinks,
        permissions: {
          lvl: 1,
        },
      },
      {
        path: 'recherche',
        name: 'Search',
        component: Search,
        permissions: {
          lvl: 1,
        },
      },
      {
        path: 'contacts',
        name: 'Contacts',
        component: Contacts,
        permissions: {
          lvl: 2,
        },
      },
      {
        path: 'faq/',
        component: ParentRouteLayout,
        name: 'FAQ',
        redirect: 'faq/interflex',
        children: [
          {
            path: 'interflex',
            name: 'Interflex',
            component: FAQ,
            permissions: {
              lvl: 1,
            },
          },
        ],
      },
    ],
  },
  {
    path: '/not-allowed',
    name: 'NotAllowed',
    component: NotAllowed,
  },
  {
    path: '/:pathMatch(.*)*',
    component: NotFound,
  },
];

export default routes;
