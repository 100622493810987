<template>
  <div>
    <p class="pt-3 pt-md-0 mb-3 text-center text-md-left">
      <a href="https://www.irdp.ch/institut/reseaux-1588.html" target="_blank"
        >Les instances officielles</a
      >
    </p>

    <create-partner-modal
      :show="newPartnerModal"
      :current-operation="currentPartnerOperation"
      :partner="partner"
      @close="closePartnerModal"
    />

    <div class="row">
      <div class="col-md-12">
        <crud-list
          ref="crudList"
          title="Liste des partenaires"
          :fetch-data="fetchPartners"
          @create="showNewPartnerModal"
          @row-clicked="rowClicked"
          :defaultSort="{ prop: 'name', order: 'ascending' }"
          @sort-change="sortChange"
        >
          <button-column
            type="edit"
            button-title="Modifier le partenaire"
            :show-on-mobile="true"
            :update-function="updatePartner"
          />
          <el-table-column
            label="Nom"
            min-width="150"
            prop="name"
            sortable="custom"
          >
            <template #default="slotProps">
              <div class="table-items-list-item">
                {{ slotProps.row.name }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="Type"
            min-width="120"
            prop="type"
            sortable="custom"
          >
            <template #default="slotProps">
              <div class="table-items-list-item">
                {{ slotProps.row.type }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="Description"
            min-width="300"
            prop="description"
            sortable="custom"
          >
            <template #default="slotProps">
              <div class="table-items-list-item">
                {{ slotProps.row.description }}
              </div>
            </template>
          </el-table-column>
          <button-column
            type="edit"
            button-title="Modifier le partenaire"
            :show-on-mobile="false"
            :update-function="updatePartner"
          />
        </crud-list>
      </div>
    </div>
  </div>
</template>

<script>
import CrudList from './UIComponents/CrudList.vue';
import ButtonColumn from './UIComponents/ButtonColumn.vue';
import CreatePartnerModal from './Modals/CreatePartnerModal.vue';

export default {
  components: {
    CrudList,
    ButtonColumn,
    CreatePartnerModal,
  },
  data() {
    return {
      newPartnerModal: false,
      currentPartnerOperation: 'create',
      partner: {},
      sort: '&sort=name,asc',
    };
  },
  methods: {
    fetchPartners(pageNumber, pageSize) {
      return this.$http
        .get(
          `${
            process.env.VUE_APP_INTRANET_API_URL
          }/partners/search/findAllPage?page=${
            pageNumber - 1
          }&size=${pageSize}${this.sort}`,
        )
        .then((response) => ({
          records: response.data._embedded.partners
            ? response.data._embedded.partners
            : [],
          page: response.data.page ? response.data.page : 0,
        }));
    },
    showNewPartnerModal() {
      this.currentPartnerOperation = 'create';
      this.partner = {};
      this.newPartnerModal = true;
    },
    closePartnerModal() {
      this.newPartnerModal = false;
      // Refresh the crud-list component's data.
      this.$refs.crudList.fetch();
    },
    rowClicked(row) {
      window.open(row.website, '_blank');
    },
    updatePartner(row, event) {
      // Prevent the opening of the link since the user clicked on the partner row to update it.
      event.stopPropagation();

      this.currentPartnerOperation = 'edit';
      this.partner = { ...row };
      this.newPartnerModal = true;
    },
    sortChange({ column, prop, order }) {
      const orderString = order === 'ascending' ? 'asc' : 'desc';
      this.sort = `&sort=${prop},${orderString}`;
      this.$refs.crudList.fetch();
    },
  },
};
</script>

<style lang="scss" scoped></style>
