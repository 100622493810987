<template>
  <div>
    <el-input
      placeholder="Moyen de contact"
      v-model="content"
      class="input-with-select"
      @change="() => sendValueToParent()"
    >
      <template #prepend>
        <el-select
          v-model="type"
          class="inner-select"
          placeholder="Type"
          @change="() => sendValueToParent()"
        >
          <el-option
            v-for="typeOption in types"
            :key="typeOption.name"
            :label="typeOption.name"
            :value="typeOption.value"
          >
          </el-option>
        </el-select>
      </template>
      <template #append>
        <el-button
          :icon="ElIconDelete"
          @click="() => this.$emit('delete')"
        ></el-button>
      </template>
    </el-input>
  </div>
</template>

<script>
import { Delete as ElIconDelete } from '@element-plus/icons-vue';
import {
  ElSelect as Select,
  ElInput as Input,
  ElButton as Button,
} from 'element-plus';

export default {
  data() {
    return {
      content: this.value.content,
      type: this.value.type,
      types: [
        {
          name: 'Téléphone',
          value: 'TEL',
        },
        {
          name: 'Fax',
          value: 'FAX',
        },
        {
          name: 'Lien',
          value: 'LINK',
        },
        {
          name: 'Adresse',
          value: 'ADDRESS',
        },
        {
          name: 'Email',
          value: 'EMAIL',
        },
      ],
      ElIconDelete,
    };
  },
  props: ['value'],
  components: {
    [Select.name]: Select,
    [Input.name]: Input,
    [Button.name]: Button,
  },
  watch: {
    value: {
      handler(newValue) {
        if (newValue) {
          this.content = newValue.content;
          this.type = newValue.type;
        }
      },
      deep: true,
    },
  },
  methods: {
    sendValueToParent() {
      const payload = {
        ...this.value,
        content: this.content,
        type: this.type,
      };
      this.$emit('update:modelValue', payload);
    },
  },
};
</script>

<style lang="scss" scoped>
.inner-select {
  min-width: 150px;
}
</style>
