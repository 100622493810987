<template>
  <div>
    <create-gallery-item-modal
      :show="newGalleryItemModal"
      @close="closeGalleryItemModal"
    />

    <div class="row">
      <div class="col-md-12">
        <crud-list
          ref="crudList"
          :fetch-data="fetchGalleryItems"
          title="Galerie"
          @create="showNewGalleryItemModal"
          @row-clicked="rowClicked"
        >
          <button-column
            type="delete"
            button-title="Supprimer l'élément"
            :show-on-mobile="true"
            :update-function="deleteGalleryItem"
          />
          <el-table-column label="Titre" min-width="260">
            <template #default="slotProps">
              <div class="table-items-list-item">
                {{ slotProps.row.title }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Date" min-width="150">
            <template #default="slotProps">
              <div class="table-items-list-item">
                {{ moment(slotProps.row.date).format('D MMMM YYYY') }}
              </div>
            </template>
          </el-table-column>
          <button-column
            type="delete"
            button-title="Supprimer l'élément"
            :show-on-mobile="false"
            :update-function="deleteGalleryItem"
          />
        </crud-list>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import Swal from 'sweetalert2';
import CrudList from './UIComponents/CrudList.vue';
import ButtonColumn from './UIComponents/ButtonColumn.vue';
import CreateGalleryItemModal from './Modals/CreateGalleryItemModal.vue';

export default {
  components: {
    CrudList,
    ButtonColumn,
    CreateGalleryItemModal,
  },
  data() {
    return {
      galleryItems: [],
      newGalleryItemModal: false,
      moment: null,
    };
  },
  created() {
    this.moment = moment;
  },
  methods: {
    rowClicked(row) {
      this.$router.push({
        name: 'GalleryItem',
        params: { id: row.id },
      });
    },
    fetchGalleryItems(pageNumber, pageSize) {
      return this.$http
        .get(
          `${
            process.env.VUE_APP_INTRANET_API_URL
          }/gallery_items?projection=galleryItemSummary&page=${
            pageNumber - 1
          }&size=${pageSize}&sort=date,desc&sort=title,asc`,
        )
        .then((response) => ({
          records: response.data._embedded.gallery_items
            ? response.data._embedded.gallery_items
            : [],
          page: response.data.page ? response.data.page : 0,
        }));
    },
    deleteGalleryItem(row, event) {
      // Prevent the opening of the link since the user clicked on the gallery item row to delete it.
      event.stopPropagation();

      Swal.fire({
        title: 'Êtes-vous sûr de vouloir supprimer cet élément ?',
        text: "Il ne sera pas possible d'annuler cette suppression !",
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill',
        },
        confirmButtonText: 'Oui, effacer',
        cancelButtonText: 'Non, conserver',
        buttonsStyling: false,
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.$http
              .delete(
                `${process.env.VUE_APP_INTRANET_API_URL}/commands/galleryItems/${row.id}`,
              )
              .then(() => {
                // Refresh the crud-list component's data.
                this.$refs.crudList.fetch();
              })
              .catch((error) => {
                this.$notify({
                  title: 'Error',
                  text: "Une erreur est survenue lors de la suppression de l'image, veuillez réessayer ou contacter le webmaster.",
                  type: 'danger',
                });
              });
          }
        })
        .catch(() => {});
    },
    showNewGalleryItemModal() {
      this.newGalleryItemModal = true;
    },
    closeGalleryItemModal() {
      this.newGalleryItemModal = false;
    },
  },
};
</script>
