<template>
  <div class="wrapper">
    <side-bar
      :sidebar-links="$sidebar.sidebarLinks"
      type="sidebar"
      title="intraNET"
      logo="/static/img/ciip.png"
    >
      <!--<user-menu></user-menu>-->
    </side-bar>

    <div class="main-panel">
      <top-navbar />
      <dashboard-content @click="toggleSidebar" />
      <content-footer />
    </div>
  </div>
</template>

<script>
import TopNavbar from './TopNavbar.vue';
import ContentFooter from './ContentFooter.vue';
import DashboardContent from './Content.vue';

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>

<style lang="scss"></style>
