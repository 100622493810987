import moment from 'moment';
import {
  MATERIALS_SUBTYPES_ARRAY,
  MATERIALS_STATUS_ARRAY,
} from '@/enums/MaterialsTypes';
import { app } from '../../main';

export default {
  namespaced: true,
  state: {
    materials: [],
    sort: {
      prop: 'identifier',
      order: 'ascending',
    },
    page: {
      number: 1,
      totalElements: 0,
      size: 30,
      totalPages: 1,
    },
    currentType: '',
  },
  mutations: {
    setMaterials(state, materials) {
      state.materials = materials;
    },
    setSort(state, sort) {
      state.sort = sort;
    },
    setPage(state, page) {
      state.page = page;
    },
    setPageNumber(state, pageNumber) {
      state.page.number = pageNumber;
    },
    setPageSize(state, pageSize) {
      state.page.size = pageSize;
    },
    setType(state, type) {
      state.currentType = type;
    },
  },
  actions: {
    init({ commit, dispatch }) {},
    async getPrevMaterial(
      { state, dispatch },
      { materialId, type, projection },
    ) {
      const nbMaterials = state.materials.length;
      let pageSize = state.page.size;
      const pageNumber = state.page.number;

      if (nbMaterials === 0) {
        await dispatch('fetchAllMaterialsId', {
          type,
          projection,
        });
        pageSize = state.materials.length;
      }

      const materialIndex = state.materials
        .map((material) => material.id)
        .indexOf(materialId);

      // Check if material in current page
      if (materialIndex > 0) {
        return {
          id: state.materials[materialIndex - 1].id,
          isOnNewPage: false,
        };
      }

      // check if not on the first page
      if (pageNumber > 1) {
        const { materials } = await dispatch('fetchMaterialsPageRequest', {
          pageNumber: pageNumber - 1,
          pageSize,
          type,
          projection,
        });
        return {
          id: materials[pageSize - 1].id,
          isOnNewPage: true,
        };
      }

      // the material is the first of the list
      return {
        id: materialId,
        isOnNewPage: false,
      };
    },
    async getNextMaterial(
      { state, dispatch },
      { materialId, type, projection },
    ) {
      const nbMaterials = state.materials.length;
      let pageSize = state.page.size > nbMaterials ? nbMaterials : state.page.size;
      const pageNumber = state.page.number;

      if (nbMaterials === 0) {
        await dispatch('fetchAllMaterialsId', {
          type,
          projection,
        });
        pageSize = state.materials.length;
      }

      const materialIndex = state.materials
        .map((material) => material.id)
        .indexOf(materialId);

      // Check if material in current page
      if (materialIndex < pageSize - 1) {
        return {
          id: state.materials[materialIndex + 1].id,
          isOnNewPage: false,
        };
      }

      // check if not on the last page
      if (pageNumber < state.page.totalPages) {
        const { materials } = await dispatch('fetchMaterialsPageRequest', {
          pageNumber: pageNumber + 1,
          pageSize,
          type,
          projection,
        });
        return {
          id: materials[0].id,
          isOnNewPage: true,
        };
      }

      // the material is the first of the list
      return {
        id: materialId,
        isOnNewPage: false,
      };
    },
    async fetchMaterialsPage(
      { commit, dispatch },
      {
        pageNumber, pageSize, type, projection,
      },
    ) {
      const { materials, page } = await dispatch('fetchMaterialsPageRequest', {
        pageNumber,
        pageSize,
        type,
        projection,
      });
      commit('setMaterials', materials);
      commit('setPage', page);
    },
    fetchMaterialsPageRequest(
      { getters },
      {
        pageNumber, pageSize, type, projection,
      },
    ) {
      let materials = [];
      let page = {};

      return app.config.globalProperties.$http
        .get(
          `${
            process.env.VUE_APP_INTRANET_API_URL
          }/materials/search/findAllByTypePage?type=${type}&page=${
            pageNumber - 1
          }&size=${pageSize}&projection=${projection}${getters.sortRequest}`,
        )
        .then(async (materialsResponse) => {
          materials = materialsResponse.data._embedded.materials
            ? materialsResponse.data._embedded.materials
            : [];
          materials = materials.map((material) => ({
            ...material,
            subTypeString: material.subType
              ? MATERIALS_SUBTYPES_ARRAY.filter(
                (subType) => subType.value === material.subType,
              )[0].name
              : null,
            statusString: material.status
              ? MATERIALS_STATUS_ARRAY.filter(
                (status) => status.value === material.status,
              )[0].name
              : null,
            purchaseDate: material.purchaseDate
              ? moment(material.purchaseDate).format('DD.MM.YYYY')
              : null,
          }));

          page = {
            ...materialsResponse.data.page,
            number: materialsResponse.data.page.number + 1,
          };
          return {
            materials,
            page,
          };
        })
        .catch(() => {
          app.config.globalProperties.$notify({
            title: 'Error',
            message:
              'Une erreur est survenue lors de la récupération des données, veuillez réessayer ou contacter le support.',
            type: 'danger',
          });
        });
    },
    fetchAllMaterialsId({ commit }, { type, projection }) {
      return app.config.globalProperties.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/materials/search/findAllByType?type=${type}&projection=${projection}`,
        )
        .then((response) => {
          const materials = response.data._embedded.materials
            ? response.data._embedded.materials
            : [];
          commit('setMaterials', materials);
          commit('setPageSize', materials.length);
        })
        .catch(() => {
          app.config.globalProperties.$notify({
            title: 'Error',
            message:
              'Une erreur est survenue lors de la récupération des données, veuillez réessayer ou contacter le support.',
            type: 'danger',
          });
        });
    },
    fetchAllMaterials({ commit }, { type, projection }) {
      return app.config.globalProperties.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/materials/search/findAllByType?type=${type}&projection=${projection}`,
        )
        .then((response) => {
          let materials = response.data._embedded.materials
            ? response.data._embedded.materials
            : [];
          materials = materials.map((material) => ({
            ...material,
            subTypeString: material.subType
              ? MATERIALS_SUBTYPES_ARRAY.filter(
                (subType) => subType.value === material.subType,
              )[0].name
              : null,
            statusString: material.status
              ? MATERIALS_STATUS_ARRAY.filter(
                (status) => status.value === material.status,
              )[0].name
              : null,
            purchaseDate: material.purchaseDate
              ? moment(material.purchaseDate).format('DD.MM.YYYY')
              : null,
          }));
          return materials;
        })
        .catch(() => {
          app.config.globalProperties.$notify({
            title: 'Error',
            message:
              'Une erreur est survenue lors de la récupération des données, veuillez réessayer ou contacter le support.',
            type: 'danger',
          });
        });
    },
  },
  getters: {
    page: (state) => state.page,
    materials: (state) => state.materials,
    sort: (state) => state.sort,
    sortRequest: (state) => (state.sort.prop
      ? `&sort=${state.sort.prop},${
        state.sort.order === 'ascending' ? 'asc' : 'desc'
      }`
      : ''),
    currentType: (state) => state.currentType,
  },
};
