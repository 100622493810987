<template>
  <div>
    <update-document-modal
      :show="updateDocumentModal"
      :documentProps="selectedDocument"
      @close="closeUpdateDocumentModal"
    />
    <p v-if="errorMessage" class="error-message">
      {{ errorMessage }}
    </p>

    <div class="row documents-list-container">
      <div class="col-md-12">
        <crud-list
          ref="crudList"
          :fetch-data="fetchDocuments"
          title="Recherche de documents"
          @row-clicked="rowClicked"
          :creation-disabled="true"
        >
          <button-column
            type="update"
            button-title="Modifier le document"
            :show-on-mobile="true"
            :update-function="showUpdateDocumentModal"
          />
          <button-column
            type="shared"
            button-title="Partager le document"
            :show-on-mobile="true"
            :update-function="sharedDocument"
          />
          <el-table-column label="Titre" min-width="280" prop="title">
            <template #default="slotProps">
              <div class="table-items-list-item">
                {{ slotProps.row.title }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-if="documentsHasTag"
            label="Tag"
            min-width="160"
            prop="categorie"
          >
            <template #default="slotProps">
              <div class="table-items-list-item">
                {{ slotProps.row.tag ? slotProps.row.tag.name : '-' }}
              </div>
            </template>
          </el-table-column>
          <button-column
            type="update"
            button-title="Modifier le document"
            :show-on-mobile="false"
            :update-function="showUpdateDocumentModal"
          />
          <button-column
            type="shared"
            button-title="Partager le document"
            :show-on-mobile="false"
            :update-function="sharedDocument"
            :admin="false"
          />
        </crud-list>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import ROLES from '@/services/Roles';
import ButtonColumn from './UIComponents/ButtonColumn.vue';
import CrudList from './UIComponents/CrudList.vue';
import UpdateDocumentModal from './Modals/UpdateDocumentModal.vue';

export default {
  components: {
    CrudList,
    ButtonColumn,
    UpdateDocumentModal,
  },
  data() {
    return {
      documentsHasTag: false,
      updateDocumentModal: false,
      errorMessage: null,
      selectedDocument: null,
    };
  },
  watch: {
    // Watch after the parameters of the query in order to refresh the search.
    '$route.query': {
      handler() {
        // Refresh the crud-list component's data.
        if (this.$refs.crudList) {
          this.$refs.crudList.fetch();
        }
      },
      immediate: true,
    },
  },
  computed: {
    isAdmin() {
      return (
        this.$store.getters['account/roles'].includes(ROLES.ADMINISTRATOR)
        || this.$store.getters['account/roles'].includes(ROLES.POWER_USER)
      );
    },
  },
  methods: {
    fetchDocuments() {
      return this.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/documents/search/allByTitle?title=${this.$route.query.title}&projection=documentSummary`,
        )
        .then((response) => {
          // Check if at least one document has a tag in order to display or not the "Tag" column in the crud list.
          this.documentsHasTag = response.data._embedded.documents.filter((d) => d.tag).length > 0;

          return {
            records: response.data._embedded.documents
              ? response.data._embedded.documents
              : [],
            page: 0,
          };
        });
    },
    rowClicked(row) {
      if (row.isExternal) {
        window.open(row.path, '_blank');
        return null;
      }
      return this.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/commands/documents?path=docs/&name=${row.path}`,
          {
            responseType: 'blob',
          },
        )
        .then((response) => {
          // Download the document by first creating a temporary link tag, which redirect to a temporary URL that contains the blob file.
          // Then we simulate a click on it in order to download the file.
          const documentBlob = response.data;
          const urlCreatedFromBlob = window.URL.createObjectURL(documentBlob);
          const tempLinkTag = document.createElement('a');
          tempLinkTag.href = urlCreatedFromBlob;
          tempLinkTag.setAttribute('download', row.path);
          document.body.appendChild(tempLinkTag);
          tempLinkTag.click();
          tempLinkTag.remove();
        });
    },
    deleteDocument(documentId, event) {
      // Prevent the opening of the link sicne the user clicked on the document row to delete it.
      event.stopPropagation();

      Swal.fire({
        title: 'Êtes-vous sûr de vouloir supprimer ce document ?',
        text: "Il ne sera pas possible d'annuler cette suppression !",
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill',
        },
        confirmButtonText: 'Oui, effacer',
        cancelButtonText: 'Non, conserver',
        buttonsStyling: false,
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.$http
              .delete(
                `${process.env.VUE_APP_INTRANET_API_URL}/commands/documents/${documentId}`,
              )
              .then(() => {
                // Refresh the crud-list component's data.
                this.$refs.crudList.fetch();
              })
              .catch((error) => {
                this.errorMessage = 'Une erreur est survenue lors de la suppression du'
                  + 'document, veuillez réessayer ou contacter le webmaster.';
              });
          }
        })
        .catch(() => {});
    },
    closeUpdateDocumentModal() {
      this.updateDocumentModal = false;
      // Refresh the crud-list component's data.
      this.$refs.crudList.fetch();
    },
    showUpdateDocumentModal(row, event) {
      event.stopPropagation();

      this.selectedDocument = {
        ...row,
      };
      this.updateDocumentModal = true;
    },
    sharedDocument(row, event) {
      event.stopPropagation();
      navigator.clipboard.writeText(
        `${process.env.VUE_APP_INTRANET_WEBAPP}/documents/${row.id}`,
      );
      this.$notify({
        title: 'Partager',
        text: 'Le lien de partage a été copié avec succès dans votre presse-papier.',
        type: 'success',
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
