<template>
  <div>
    <slides-modal :show="showSlidesModal" @close="closeSlidesModal" />
    <whats-up-modal
      :show="showWhatsUpModal"
      :whats-up-message="whatsUpMessage"
      @close="closeWhatsUpModal"
    />

    <div class="row">
      <div class="col-sm-12 px-0 px-md-3">
        <div class="card">
          <div class="card-header px-0 px-md-3 pt-0 pt-md-3">
            <button
              v-if="isAdmin"
              class="btn btn-icon btn-round btn-update-slides"
              type="button"
              title="Modifier les slides"
              @click="openSlidesModal()"
            >
              <i class="fa fa-pencil-square-o" />
            </button>
            <carousel-news :slides="slides" />
          </div>
          <div class="card-body pb-0 pb-md-3">
            <div class="row">
              <div class="col-md-6">
                <crud-list
                  ref="crudList"
                  title="Anniversaires"
                  :fetch-data="fetchBirthdays"
                  :creation-disabled="true"
                >
                  <el-table-column label="Nom">
                    <template #default="slotProps">
                      <div class="table-items-list-item">
                        {{
                          `${slotProps.row.firstname} ${slotProps.row.lastname}`
                        }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="Date">
                    <template #default="slotProps">
                      <div class="table-items-list-item">
                        {{ slotProps.row.birthdayDate }}
                      </div>
                    </template>
                  </el-table-column>
                </crud-list>
              </div>
              <div class="col-md-6">
                <div class="card">
                  <div class="card-header">
                    <h5 class="title">Quoi de neuf ?</h5>
                    <button
                      v-if="isAdmin"
                      class="btn btn-icon btn-round btn-update-whats-up"
                      type="button"
                      title="Modifier la section"
                      @click="openWhatsUpModal()"
                    >
                      <i class="fa fa-pencil-square-o" />
                    </button>
                  </div>
                  <div class="card-body" v-html="whatsUpMessage" />
                  <div v-if="!whatsUpMessage" class="card-body">
                    <i>Il n'y a aucune nouveauté pour le moment.</i>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="card mb-0 mb-md-3">
                  <div class="card-header">
                    <h5 class="title">Coordonnées utiles</h5>
                    <button
                      v-if="isAdmin"
                      class="btn btn-icon btn-round btn-update-whats-up"
                      type="button"
                      title="Modifier les contacts"
                      @click="goToContacts()"
                    >
                      <i class="fa fa-pencil-square-o" />
                    </button>
                  </div>
                  <div class="card-body">
                    <contact-overview />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import MixinFetchData from '@/mixins/mixinFetchData';
import CrudList from '@/components/ApplicationViews/UIComponents/CrudList.vue';
import SlidesModal from '@/components/ApplicationViews/Modals/SlidesModal.vue';
import WhatsUpModal from '@/components/ApplicationViews/Modals/WhatsUpModal.vue';
import ContactOverview from '@/components/ApplicationViews/UIComponents/ContactOverview.vue';
import ROLES from '@/services/Roles';
import CarouselNews from '../../../ApplicationViews/UIComponents/CarouselNews.vue';

export default {
  name: 'Overview',
  components: {
    CarouselNews,
    CrudList,
    SlidesModal,
    WhatsUpModal,
    ContactOverview,
  },
  mixins: [MixinFetchData],
  data() {
    return {
      currentImageIndex: 0,
      showSlidesModal: false,
      slides: [],
      showWhatsUpModal: false,
      whatsUpMessage: null,
    };
  },
  mounted() {
    // This function is declared in the MixinFetchData mixin.
    this.fetchSlides();
    this.fetchBirthdays();
    this.fetchWhatsUpMessage();
  },
  computed: {
    isAdmin() {
      return (
        this.$store.getters['account/roles'].includes(ROLES.ADMINISTRATOR)
        || this.$store.getters['account/roles'].includes(ROLES.POWER_USER)
      );
    },
  },
  methods: {
    fetchBirthdays() {
      return this.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/collaborators/search/findUpcomingBirthdays?projection=collaboratorBirthday`,
        )
        .then((response) => {
          if (response.data._embedded) {
            response.data._embedded.collaborators.forEach((collaborator) => {
              // eslint-disable-next-line no-param-reassign
              collaborator.birthdayDate = moment(
                collaborator.birthdayDate,
              ).format('Do MMMM');
            });

            return {
              records: response.data._embedded.collaborators
                ? response.data._embedded.collaborators
                : [],
              page: 0,
            };
          }
          return {
            records: [],
            page: 0,
          };
        });
    },
    fetchWhatsUpMessage() {
      return this.$http
        .get(`${process.env.VUE_APP_INTRANET_API_URL}/homePage`)
        .then((response) => {
          this.whatsUpMessage = response.data._embedded.homePage.length > 0
            ? response.data._embedded.homePage[0].whatsUpMessage
            : '';
        });
    },
    openSlidesModal() {
      this.showSlidesModal = true;
    },
    closeSlidesModal() {
      this.showSlidesModal = false;
      this.fetchSlides();
    },
    openWhatsUpModal() {
      this.showWhatsUpModal = true;
    },
    closeWhatsUpModal() {
      this.showWhatsUpModal = false;
      this.fetchWhatsUpMessage();
    },
    goToContacts() {
      this.$router.push('contacts');
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  color: inherit;
}

.btn-update-slides {
  border: 1px solid #fff;
  position: absolute !important;
  right: 1.7em;
  z-index: 10;
}

.btn-update-whats-up {
  position: absolute !important;
  right: 1.7em;
  top: 0;
}

@media screen and (max-width: $mobile-max-width) {
  .btn-update-slides {
    margin: 5px 0;
    right: $mobile-overview-edit-button-right;
  }

  .btn-update-whats-up {
    right: $mobile-overview-edit-button-right;
  }
}
</style>
