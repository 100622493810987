<template>
  <div class="contact-container">
    <div v-for="contact in contacts" :key="contact.id" class="contact">
      <p>
        <span class="contact-title"> {{ contact.name }} </span>
        <br />
        <span v-for="(coordinate, index) in contact.coordinates" :key="index">
          <a v-if="coordinate.href" :href="coordinate.href" target="_blank">
            {{ coordinate.content }}
          </a>
          <span v-else> {{ coordinate.content }} </span>
          <br />
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import { ElRow as Row, ElCol as Col } from 'element-plus';

export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
  },
  data() {
    return {
      contacts: [],
    };
  },
  mounted() {
    this.fetchContacts();
  },
  methods: {
    fetchContacts() {
      this.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/contacts/search/findAll?projection=contactDetails`,
        )
        .then((payload) => {
          this.contacts = this.formatContacts(payload.data._embedded.contacts);
        })
        .catch((error) => {
          this.$notify({
            title: 'Erreur',
            text: 'Une erreur est survenue lors du chargement des contacts, veuillez réessayer ou contacter le support.',
            type: 'danger',
          });
        });
    },
    formatContacts(contacts) {
      return contacts.map((contact) => {
        const coordinatesFormated = contact.coordinates
          .map((coordinate) => ({
            ...coordinate,
            content: this.formatContent(coordinate),
            href: this.getHref(coordinate),
          }))
          .sort((a, b) => a.position - b.position);

        return {
          ...contact,
          coordinates: coordinatesFormated,
        };
      });
    },
    formatContent(coordinate) {
      let content = null;
      switch (coordinate.type) {
        case 'TEL':
          content = 'Tél. ';
          break;
        case 'FAX':
          content = 'Fax ';
          break;
        default:
          content = '';
      }
      return content + coordinate.content;
    },
    getHref(coordinate) {
      let href = null;
      switch (coordinate.type) {
        case 'TEL':
          href = 'tel:';
          break;
        case 'FAX':
          href = 'fax:';
          break;
        case 'EMAIL':
          href = 'mailto:';
          break;
        case 'LINK':
          href = '';
          break;
        default:
          href = null;
      }
      if (href !== null) {
        href += coordinate.content;
      }
      return href;
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-title {
  font-weight: bold;
}

.contact-container {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
}

.contact {
  min-width: 33%;
}
</style>
