export default [
  {
    name: 'Collaborateurs',
    icon: 'nc-icon nc-single-02',
    path: '/collaborators',
  },
  {
    name: 'Véhicules',
    icon: 'fa fa-car',
    path: '/cars',
  },
  {
    name: 'Documents',
    icon: 'nc-icon nc-single-copy-04',
    children: [
      {
        name: 'Statuts - Règlements institutionnels',
        path: '/documents/statuts-reglements-ciip',
      },
      {
        name: 'Ressources humaines',
        path: '/documents/ressources-humaines',
      },
      {
        name: 'Finances',
        path: '/documents/finances',
      },
      {
        name: 'Organisation et fonctions',
        path: '/documents/organisation-institutionnelle-fonctions',
      },
      {
        name: 'Édition et rédaction',
        path: '/documents/edition-redaction',
      },
      {
        name: 'Infrastructures',
        path: '/documents/infrastructures',
      },
      {
        name: 'Informatique',
        path: '/documents/informatique',
      },
      {
        name: 'Mobilité',
        path: '/documents/mobilite',
      },
      {
        name: 'Formulaires',
        path: '/documents/formulaires',
      },
      {
        name: 'Modèles',
        path: '/documents/modeles',
      },
      {
        name: 'Organisation administrative',
        path: '/documents/organisation-administrative',
      },
    ],
  },
  {
    name: 'Matériel informatique',
    icon: 'nc-icon nc-tv-2',
    children: [
      {
        name: 'Postes de travail',
        path: '/materials/computers',
      },
      {
        name: 'Casques téléphoniques',
        path: '/materials/headsets',
      },
      {
        name: 'Imprimantes',
        path: '/materials/printers',
      },
      {
        name: 'Scanners',
        path: '/materials/scanners',
      },
      {
        name: 'Périphériques',
        path: '/materials/devices',
      },
      {
        name: 'Dictaphones',
        path: '/materials/dictaphones',
      },
      {
        name: 'Projecteurs',
        path: '/materials/beamers',
      },
      {
        name: 'Serveurs',
        path: '/materials/servers',
      },
      {
        name: 'Stations de vidéoconférences',
        path: '/materials/stationsvideoconferences',
      },
      {
        name: 'Routeurs',
        path: '/materials/routers',
      },
    ],
  },
  {
    name: 'Applications',
    icon: 'fa fa-windows',
    path: '/applications',
  },
  {
    name: 'Lecteurs réseaux et dossiers',
    icon: 'fa fa-folder',
    path: '/dossiers',
  },
  {
    name: 'Listes de distribution',
    icon: 'fa fa-reply-all',
    path: '/listes-distribution',
  },
  {
    name: 'Boîte aux lettres',
    icon: 'fa fa-envelope',
    path: '/letterbox',
  },
  {
    name: 'Fournisseur de services',
    icon: 'fa fa-building',
    path: '/enterprises',
    admin: true,
  },
  {
    name: 'Délégation du personnel',
    icon: 'nc-icon nc-bullet-list-67',
    path: '/delegation-personnel',
  },
  {
    name: 'Plateformes institutionnelles',
    icon: 'nc-icon nc-app',
    path: '/plateformes',
  },
  {
    name: 'Liens utiles',
    icon: 'nc-icon nc-globe',
    path: '/liens-utiles',
  },
  {
    name: 'Interflex',
    icon: 'fa fa-hourglass',
    path: '//interflexwebclient.ne.ch/WebClient/iflx/login.jsp?userlocale=fr,FR',
    target: '_blank',
    rel: 'noopener',
  },
  {
    name: 'FAQ',
    icon: 'fa fa-question',
    children: [
      {
        name: 'Interflex',
        path: '/faq/interflex',
      },
    ],
  },
  {
    name: 'Extranet',
    icon: 'nc-icon nc-bookmark-2',
    path: '//extranet.ciip.ch/admin/',
    target: '_blank',
    rel: 'noopener',
  },
  {
    name: 'Annuaire',
    icon: 'nc-icon nc-badge',
    path: '//annuaire.ciip.ch/',
    target: '_blank',
    rel: 'noopener',
  },
  {
    name: 'Galerie',
    icon: 'nc-icon nc-album-2',
    path: '/gallery',
  },
  {
    name: 'Partenaires',
    icon: 'nc-icon nc-bank',
    path: '/partenaires',
  },
  {
    name: 'Support',
    icon: 'fa fa-life-ring',
    path: '//support.ciip.ch/tickets-create?applications=INTRANET',
    target: '_blank',
    rel: 'noopener',
  },
];
