<template>
  <div>
    <details-modal
      :show="show"
      current-operation="create"
      :loading="loading"
      @close="closeModal"
      @cancel="closeModal"
      @create="createRecord"
    >
      <form>
        <div v-if="!document.isExternal">
          <label class="control-label">*Fichier</label>
          <p>
            <input
              id="file"
              type="file"
              name="file"
              accept="application/pdf"
              @change="setFile($event)"
            /><br />
          </p>
        </div>
        <p><label>* champs obligatoires</label></p>
      </form>
      <p v-if="errorMessage" class="error-message-in-modal">
        {{ errorMessage }}
      </p>
    </details-modal>
  </div>
</template>

<script>
import DetailsModal from './DetailsModal.vue';

export default {
  components: {
    DetailsModal,
  },
  props: ['show', 'category'],
  data() {
    return {
      document: {
        file: null,
      },
      errorMessage: '',
      loading: false,
    };
  },
  computed: {},
  watch: {},
  methods: {
    createRecord() {
      this.errorMessage = null;

      const formData = new FormData();

      if (this.document.file) {
        formData.append('file', this.document.file);
      }

      this.loading = true;

      this.$http
        .post(
          `${process.env.VUE_APP_INTRANET_API_URL}/commands/collaboratorsFile/`,
          formData,
        )
        .then(() => {
          this.$notify({
            title: 'Update',
            text: 'Le fichier a été sauvegardé avec succès.',
            type: 'success',
          });
          this.closeModal();
        })
        .catch((error) => {
          this.errorMessage = 'Une erreur est survenue lors du chargement des données, veuillez réessayer ou contacter le webmaster.';
        })
        .finally(() => {
          this.loading = false;
        });
    },
    closeModal() {
      this.$emit('close');
      this.errorMessage = '';

      // Reset the file input.
      if (document.getElementById('file')) {
        document.getElementById('file').value = '';
      }
    },
    setFile(event) {
      if (event.target.files[0]) {
        [this.document.file] = event.target.files;
      }
    },
  },
};
</script>
