<template>
  <div>
    <details-modal
      :show="show"
      current-operation="create"
      :loading="loading"
      @close="closeModal"
      @cancel="closeModal"
      @create="createRecord"
    >
      <Form :validation-schema="validationSchema" validate-on-mount>
        <label class="control-label">Image</label>
        <p>
          <input
            id="image"
            type="file"
            name="image"
            accept="image/png, image/jpeg"
            @change="processImage($event)"
          /><br />
          <small v-show="imageTooBig" class="text-danger">
            La taille de l'image ne peut pas dépasser 5MB, veuillez réessayer.
          </small>
        </p>
        <label class="control-label">Cahier des charges</label>
        <p>
          <input
            id="specifications"
            type="file"
            name="specifications"
            accept="application/pdf"
            @change="setSpecifications($event)"
          /><br />
        </p>
        <div class="form-group">
          <label class="control-label">*Nom</label>
          <Field
            name="lastname"
            v-model="collaborator.lastname"
            v-slot="{ field, errorMessage }"
          >
            <input
              v-bind="field"
              type="text"
              class="form-control"
              @input="lastnameInputChange($event)"
            />
            <small v-show="errorMessage" class="text-danger">
              Le nom est requis.
            </small>
          </Field>
        </div>
        <div class="form-group">
          <label class="control-label">*Prénom</label>
          <Field
            name="firstname"
            v-model="collaborator.firstname"
            v-slot="{ field, errorMessage }"
          >
            <input v-bind="field" class="form-control" type="text" />
            <small v-show="errorMessage" class="text-danger">
              Le prénom est requis.
            </small>
          </Field>
        </div>
        <div class="form-group">
          <label class="control-label">*Initiales</label>
          <Field
            name="initials"
            v-model="collaborator.initials"
            v-slot="{ field, errorMessage }"
          >
            <input v-bind="field" class="form-control" type="text" />
            <small v-show="errorMessage" class="text-danger">
              Les initiales sont requises.
            </small>
          </Field>
        </div>
        <div class="form-group">
          <label class="control-label">E-mail</label>
          <Field
            name="email"
            v-model="collaborator.email"
            v-slot="{ field, errorMessage }"
          >
            <input v-bind="field" type="email" class="form-control" />
            <small v-show="errorMessage" class="text-danger">
              Le champ e-mail doit être une adresse e-mail valide.
            </small>
          </Field>
        </div>
        <label for="birthdayDate">Date de naissance</label>
        <el-date-picker
          id="birthdayDate"
          v-model="collaborator.birthdayDate"
          type="date"
          placeholder="Choisissez un jour"
        />
        <fg-input
          v-model="collaborator.internalphone"
          type="text"
          label="Téléphone interne"
        />
        <fg-input
          v-model="collaborator.privatephone"
          type="text"
          label="Téléphone privé"
        />
        <label for="activities">Domaine(s) d'activité(s)</label>
        <textarea
          id="activities"
          v-model="collaborator.activities"
          class="form-control"
          rows="3"
        />
        <fg-input v-model="collaborator.rate" type="number" label="Taux (%)" />
        <p>
          <label for="select-upper-hierarchy"
            >Supérieur(s) hiérarchique(s)</label
          >
          <el-select
            id="select-upper-hierarchy"
            v-model="collaborator.upperHierarchyIds"
            class="select-info col-md-12 select-one-line-per-item"
            size="large"
            placeholder="Sélectionner"
            :multiple="true"
            clearable
            filterable
          >
            <el-option
              v-for="c in collaboratorsName"
              :key="c.id"
              :label="`${c.lastname} ${c.firstname}`"
              :value="c.id"
            />
          </el-select>
        </p>
        <crud-select
          v-model="collaborator.functionId"
          label="Fonction"
          :set-selected-record-id="setSelectedFunctionId"
          :fetch-records="fetchFunctions"
          :create-record="createFunction"
        />
        <p>
          <label for="select-materials">Matériel(-aux)</label>
          <el-select
            id="select-materials"
            v-model="collaborator.materialsIds"
            filterable
            class="select-info col-md-12 select-one-line-per-item"
            size="large"
            placeholder="Sélectionner"
            :multiple="true"
          >
            <el-option-group
              v-for="group in materials"
              :key="group.label"
              :label="group.label"
            >
              <el-option
                v-for="material in group.options"
                :key="material.id"
                :label="material.identifier"
                :value="material.id"
              />
            </el-option-group>
          </el-select>
        </p>
        <crud-select-car
          v-model="collaborator.carsIds"
          label="Voiture(s)"
          :multiple="true"
          :set-selected-record-id="setSelectedCarId"
          :fetch-records="fetchCars"
          :create-record="createCar"
          :delete-record="deleteCar"
        />
        <p>
          <label for="select-services">Application(s)</label>
          <el-select
            id="select-applications"
            v-model="collaborator.applicationsIds"
            class="select-info col-md-12 select-one-line-per-item"
            size="large"
            placeholder="Sélectionner"
            :multiple="true"
            clearable
            filterable
          >
            <el-option
              v-for="application in applications"
              :key="application.id"
              :label="application.value"
              :value="application.id"
            />
          </el-select>
        </p>
        <p>
          <label for="select-services">Service(s)</label>
          <el-select
            id="select-services"
            v-model="collaborator.servicesIds"
            class="select-info col-md-12 select-one-line-per-item"
            size="large"
            placeholder="Sélectionner"
            :multiple="true"
            clearable
            filterable
          >
            <el-option
              v-for="service in services"
              :key="service.id"
              :label="service.acronym"
              :value="service.id"
            />
          </el-select>
        </p>
        <label>Horaire</label>
        <schedule-manager
          :editingDisabled="false"
          :scheduleProps="collaborator.schedule"
          :isNotRegisterProps="scheduleIsNotRegister"
          @scheduleChange="scheduleChange"
          @addNewSchedule="addNewSchedule"
        />
        <p><label>* champs obligatoires</label></p>
      </Form>
      <p v-if="errorMessage" class="error-message-in-modal">
        {{ errorMessage }}
      </p>
    </details-modal>
  </div>
</template>

<script>
import {
  ElDatePicker as DatePicker,
  ElOptionGroup as OptionGroup,
} from 'element-plus';
import { Form, Field } from 'vee-validate';
import * as yup from 'yup';
import FormData from 'form-data';
import ScheduleService from '@/services/ScheduleService';
import MixinFetchData from '@/mixins/mixinFetchData';
import MixinProcessImage from '@/mixins/mixinProcessImage';
import MATERIALS_TYPES_ARRAY from '@/enums/MaterialsTypes';
import CrudSelect from '../UIComponents/CrudSelect.vue';
import CrudSelectCar from '../UIComponents/CrudSelectCar.vue';
import DetailsModal from './DetailsModal.vue';
import ScheduleManager from '../UIComponents/SchedulesManager.vue';

export default {
  components: {
    DetailsModal,
    CrudSelect,
    CrudSelectCar,
    ScheduleManager,
    [DatePicker.name]: DatePicker,
    [OptionGroup.name]: OptionGroup,
    Form,
    Field,
  },
  mixins: [MixinFetchData, MixinProcessImage],
  props: ['show'],
  data() {
    return {
      collaborator: {
        functionId: null,
        materialsIds: [],
        carsIds: [],
        applicationsIds: [],
        schedule: ScheduleService.initSchedule(),
      },
      scheduleIsNotRegister: true,
      applications: [],
      materials: [],
      errorMessage: '',
      validationSchema: {
        lastname: yup.string().required(),
        firstname: yup.string().required(),
        initials: yup.string().required(),
        email: yup.string().email(),
      },
      loading: false,
    };
  },
  mounted() {
    this.fetchApplications();
    this.fetchNotAttributedMaterials();
  },
  watch: {
    show: {
      handler(newVal) {
        if (newVal) {
          this.collaborator.isActive = true;
          this.fetchData();
        }
      },
    },
  },
  methods: {
    async createRecord() {
      // Validate each field with vee-validate, then post the new collaborator.
      const validationSchemaObject = yup.object(this.validationSchema);
      const isCollaboratorValid = await validationSchemaObject.isValid(
        this.collaborator,
      );

      if (isCollaboratorValid) {
        this.errorMessage = null;
        if (
          this.collaborator.schedule
          && this.scheduleIsNotRegister === false
          && this.collaborator.schedule.id === null
        ) {
          this.createSchedule().then(() => {
            this.requestCreateCollaborator();
          });
        } else {
          this.requestCreateCollaborator();
        }
      } else {
        this.errorMessage = 'Un ou plusieurs champs sont invalides.';
      }
    },
    requestCreateCollaborator() {
      const formData = new FormData();
      formData.append('image', this.collaborator.newImage);
      formData.append('specifications', this.collaborator.specifications);
      // Add the properties as application/json content type in order to unserialize them in the server.
      formData.append(
        'properties',
        new Blob(
          [
            JSON.stringify({
              lastname: this.collaborator.lastname,
              firstname: this.collaborator.firstname,
              initials: this.collaborator.initials,
              email: this.collaborator.email,
              birthdayDate: this.collaborator.birthdayDate,
              internalphone: this.collaborator.internalphone,
              privatephone: this.collaborator.privatephone,
              activities: this.collaborator.activities,
              rate: this.collaborator.rate,
              functionId: this.collaborator.functionId,
              upperHierarchyIds: this.collaborator.upperHierarchyIds,
              servicesIds: this.collaborator.servicesIds,
              materialsIds: this.collaborator.materialsIds,
              applicationsIds: this.collaborator.applicationsIds,
              carsIds: this.collaborator.carsIds,
              scheduleId: this.collaborator.schedule.id,
            }),
          ],
          {
            type: 'application/json',
          },
        ),
      );

      this.loading = true;

      this.$http
        .post(
          `${process.env.VUE_APP_INTRANET_API_URL}/commands/collaborators/`,
          formData,
        )
        .then((response) => {
          this.closeModal();

          // Wait for the DOM to properly update (which means we are waiting for the modal to close) before redirecting the user to the new-created
          // collaborator's page. We have to process this way in order to prevent the scrollbar to disapear after the creation.
          this.$nextTick(() => {
            const newCollaboratorId = response.headers.location.substr(
              response.headers.location.lastIndexOf('/') + 1,
            );
            this.$router.push({
              name: 'CollaboratorDetails',
              params: { id: newCollaboratorId },
            });
          });
        })
        .catch((error) => {
          this.errorMessage = 'Une erreur est survenue lors du chargement des données, veuillez réessayer ou contacter le webmaster.';
        })
        .finally(() => {
          this.loading = false;
        });
    },
    closeModal() {
      this.$emit('close');
      this.resetData();
      this.collaborator = {
        upperHierarchyIds: [],
        servicesIds: [],
        materialsIds: [],
        applicationsIds: [],
        carsIds: [],
        schedule: ScheduleService.initSchedule(),
      };
      this.scheduleIsNotRegister = true;
      this.errorMessage = '';
    },
    fetchData() {
      // These functions are coming from the "mixinFetchData" mixin.
      this.fetchCollaboratorsNames();
      this.fetchServices();
    },
    setSpecifications(event) {
      if (event.target.files[0]) {
        [this.collaborator.specifications] = event.target.files;
      }
    },
    setSelectedFunctionId(functionId) {
      this.collaborator.functionId = functionId;
    },
    fetchFunctions() {
      return this.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/functions/search/findAllOrderByName`,
        )
        .then((response) => ({
          records: response.data._embedded.functions
            ? response.data._embedded.functions.map((type) => ({
              id: type.id,
              value: type.name,
            }))
            : [],
        }));
    },
    createFunction(data) {
      return this.$http.post(
        `${process.env.VUE_APP_INTRANET_API_URL}/functions`,
        {
          name: data,
        },
      );
    },
    deleteFunction(functionId) {
      return this.$http.delete(
        `${process.env.VUE_APP_INTRANET_API_URL}/functions/${functionId}`,
      );
    },
    setSelectedMaterialId(materialId) {
      this.collaborator.materialsIds = materialId;
    },
    fetchNotAttributedMaterials() {
      return this.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/materials/search/notAttributedMaterials`,
        )
        .then((response) => {
          const materials = response.data._embedded.materials
            ? response.data._embedded.materials.map((material) => ({
              ...material,
              value: material.number,
            }))
            : [];
          this.materials = this.formatMaterials(materials);
        });
    },
    setSelectedCarId(carIds) {
      this.collaborator.carsIds = carIds;
    },
    fetchCars() {
      return this.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/cars/search/findAll?sort=licensePlateNumber,asc`,
        )
        .then((response) => ({
          records: response.data._embedded.cars
            ? response.data._embedded.cars.map((car) => ({
              id: car.id,
              value: car.licensePlateNumber,
            }))
            : [],
        }));
    },
    createCar(car) {
      return this.$http
        .post(`${process.env.VUE_APP_INTRANET_API_URL}/commands/cars/`, {
          licensePlateNumber: car.licensePlate,
          collaboratorsIds: car.ownersIds,
        })
        .catch((error) => {
          this.errorMessage = 'Une erreur est survenue durant la création du véhicule';
        });
    },
    deleteCar(carId) {
      return this.$http.delete(
        `${process.env.VUE_APP_INTRANET_API_URL}/commands/cars/${carId}`,
      );
    },
    setSelectedApplicationId(applicationId) {
      this.collaborator.applicationsIds = applicationId;
    },
    fetchApplications() {
      return this.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/applications/search/findAll?sort=name,asc`,
        )
        .then((response) => {
          this.applications = response.data._embedded.applications
            ? response.data._embedded.applications.map((application) => ({
              id: application.id,
              value: application.name,
            }))
            : [];
        });
    },
    formatMaterials(materials) {
      return MATERIALS_TYPES_ARRAY.map((materialType) => {
        const options = materials.filter((m) => m.type === materialType.value);
        return {
          label: materialType.name,
          options,
        };
      });
    },
    addNewSchedule() {
      this.scheduleIsNotRegister = false; // will be registered
    },
    createSchedule() {
      if (this.collaborator.schedule) {
        return ScheduleService.createSchedule(this.collaborator.schedule)
          .then((res) => {
            if (res.data) {
              this.collaborator.schedule.id = res.data.id;
            }
          })
          .catch((error) => {
            this.errorMessage = "Une erreur est survenue lors de la création de l'horaire "
              + ', veuillez réessayer ou contacter le webmaster.';
          });
      }
      return null;
    },
    scheduleChange(schedule) {
      this.collaborator.schedule = schedule;
    },
    lastnameInputChange(e) {
      const start = e.target.selectionStart;
      // eslint-disable-next-line no-param-reassign
      e.target.value = e.target.value.toUpperCase();
      this.collaborator.lastname = e.target.value;
      e.target.setSelectionRange(start, start);
    },
  },
};
</script>

<style lang="scss" scoped>
.select-text-primary {
  float: left;
}

.select-text-secondary {
  float: right;
  margin-right: 15px;
  font-size: 13px;
}
</style>
