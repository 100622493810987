<template>
  <div>
    <details-modal
      :show="show"
      :current-operation="currentOperation"
      :loading="loading"
      @close="closeModal"
      @cancel="closeModal"
      @create="createDistributionList"
      @update="updateDistributionList"
      @delete="deleteDistributionList"
    >
      <Form :validation-schema="validationSchema" validate-on-mount>
        <div class="form-group">
          <label class="control-label">*Nom</label>
          <Field
            name="name"
            v-model="distributionList.name"
            v-slot="{ field, errorMessage }"
          >
            <input v-bind="field" class="form-control" type="text" />
            <small v-show="errorMessage" class="text-danger">
              Le nom est requis.
            </small>
          </Field>
        </div>
        <p>
          <label for="select-function">Utilisateurs</label>
          <el-select
            id="select-function"
            v-model="distributionList.collaboratorsId"
            class="select-info col-md-12 select-one-line-per-item"
            size="large"
            name="users"
            placeholder="Sélectionner"
            filterable
            clearable
            :multiple="true"
          >
            <el-option
              v-for="c in allCollaboratorsName"
              :key="c.id"
              :label="`${c.lastname} ${c.firstname}`"
              :value="c.id"
            >
              <el-tooltip
                v-if="!c.isActive"
                content="Inactif"
                placement="bottom"
              >
                <i class="fa fa-user-times"></i>
              </el-tooltip>
              <span> {{ c.lastname }} {{ c.firstname }}</span>
            </el-option>
          </el-select>
        </p>
        <p><label>* champs obligatoires</label></p>
      </Form>
      <p v-if="errorMessage" class="error-message-in-modal">
        {{ errorMessage }}
      </p>
    </details-modal>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import {
  ElDatePicker as DatePicker,
  ElInputNumber as InputNumber,
  ElTooltip as Tooltip,
} from 'element-plus';
import { Form, Field } from 'vee-validate';
import * as yup from 'yup';
import MixinFetchData from '@/mixins/mixinFetchData';
import DetailsModal from './DetailsModal.vue';

export default {
  components: {
    DetailsModal,
    [DatePicker.name]: DatePicker,
    [InputNumber.name]: InputNumber,
    [Tooltip.name]: Tooltip,
    Form,
    Field,
  },
  mixins: [MixinFetchData],
  props: ['show', 'currentOperation', 'distributionListProps'],
  data() {
    return {
      loading: false,
      errorMessage: '',
      validationSchema: {
        name: yup.string().required(),
      },
      distributionList: {},
    };
  },
  watch: {
    show: {
      handler(newVal) {
        if (newVal) {
          this.fetchAllCollaboratorsNames();
        }
      },
    },
    distributionListProps: {
      handler(newVal) {
        this.distributionList = newVal;
      },
    },
  },
  computed: {
    inactifIcon() {
      return '<i class="fas fa-user-slash"/>';
    },
  },
  methods: {
    fetchProviders() {
      this.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/distributionList/search/findAll`,
        )
        .then((response) => {
          this.providers = response.data._embedded.enterprises
            ? response.data._embedded.enterprises
            : [];
        });
    },
    async createDistributionList() {
      // Validate each field with vee-validate, and post the new distributionList.
      const validationSchemaObject = yup.object(this.validationSchema);
      const isDistributionListValid = await validationSchemaObject.isValid(
        this.distributionList,
      );

      if (isDistributionListValid) {
        this.loading = true;
        this.errorMessage = null;

        this.$http
          .post(
            `${process.env.VUE_APP_INTRANET_API_URL}/commands/distributionList/`,
            {
              ...this.distributionList,
            },
          )
          .then(() => {
            this.closeModal();
          })
          .catch((error) => {
            this.errorMessage = "Une erreur est survenue lors de l'ajout de la liste de distribution, veuillez réessayer ou contacter le webmaster.";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.errorMessage = 'Un ou plusieurs champs sont invalides.';
      }
    },
    async updateDistributionList() {
      // Validate each field with vee-validate, and update the distributionList.
      const validationSchemaObject = yup.object(this.validationSchema);
      const isDistributionListValid = await validationSchemaObject.isValid(
        this.distributionList,
      );

      if (isDistributionListValid) {
        this.errorMessage = null;

        this.$http
          .patch(
            `${process.env.VUE_APP_INTRANET_API_URL}/commands/distributionList/${this.distributionList.id}`,
            {
              ...this.distributionList,
            },
          )
          .then(() => {
            this.closeModal();
          })
          .catch((error) => {
            this.errorMessage = 'Une erreur est survenue lors de la modification de la liste de distribution, veuillez réessayer ou contacter le webmaster.';
          });
      } else {
        this.errorMessage = 'Un ou plusieurs champs sont invalides.';
      }
    },
    deleteDistributionList() {
      this.errorMessage = null;

      Swal.fire({
        title: 'Êtes-vous sûr de vouloir supprimer cette distributionList ?',
        text: "Il ne sera pas possible d'annuler cette suppression !",
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill',
        },
        confirmButtonText: 'Oui, effacer',
        cancelButtonText: 'Non, conserver',
        buttonsStyling: false,
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.$http
              .delete(
                `${process.env.VUE_APP_INTRANET_API_URL}/distributionList/${this.distributionList.id}`,
              )
              .then(() => {
                this.closeModal();
              })
              .catch((error) => {
                this.errorMessage = 'Une erreur est survenue lors de la suppression de la liste de distribution, veuillez réessayer ou contacter le webmaster.';
              });
          }
        })
        .catch(() => {});
    },
    closeModal() {
      this.$emit('close');
      this.errorMessage = '';
      this.distributionList = {};
    },
  },
};
</script>
