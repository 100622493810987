<template>
  <div>
    <documents-crud-list
      title="Édition et rédaction"
      category="Édition et rédaction"
    />
  </div>
</template>

<script>
import DocumentsCrudList from './UIComponents/DocumentsCrudList.vue';

export default {
  components: {
    DocumentsCrudList,
  },
};
</script>
