<template>
  <div>
    <documents-crud-list
      title="Liens utiles"
      category="Liens utiles"
      style="margin: 0 15px"
    />
  </div>
</template>

<script>
import DocumentsCrudList from './UIComponents/DocumentsCrudList.vue';

export default {
  components: {
    DocumentsCrudList,
  },
};
</script>
