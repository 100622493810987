<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div>
    <details-modal
      :show="show"
      :current-operation="currentOperation"
      :loading="loading"
      @close="closeModal"
      @cancel="closeModal"
      @create="createRecord"
      @update="updateRecord"
      @delete="deleteRecord"
    >
      <Form :validation-schema="validationSchema" validate-on-mount>
        <div class="form-group">
          <label class="control-label">*Nom</label>
          <Field
            name="name"
            v-model="enterprise.name"
            v-slot="{ field, errorMessage }"
          >
            <input v-bind="field" class="form-control" type="text" />
            <small v-show="errorMessage" class="text-danger">
              Le nom est requis.
            </small>
          </Field>
        </div>
        <div class="form-group">
          <label class="control-label">Adresse</label>
          <input
            v-model="enterprise.address"
            class="form-control"
            type="text"
            name="name"
          />
        </div>
      </Form>
      <p v-if="errorMessage" class="error-message-in-modal">
        {{ errorMessage }}
      </p>
    </details-modal>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import { Form, Field } from 'vee-validate';
import * as yup from 'yup';
import DetailsModal from './DetailsModal.vue';

export default {
  components: {
    DetailsModal,
    Form,
    Field,
  },
  props: ['show', 'currentOperation', 'enterprise'],
  data() {
    return {
      errorMessage: '',
      validationSchema: {
        name: yup.string().required(),
      },
      loading: false,
    };
  },
  methods: {
    async createRecord() {
      const validationSchemaObject = yup.object(this.validationSchema);
      const isEnterpriseValid = await validationSchemaObject.isValid(
        this.enterprise,
      );

      if (isEnterpriseValid) {
        this.errorMessage = null;

        this.loading = true;

        this.$http
          .post(
            `${process.env.VUE_APP_INTRANET_API_URL}/commands/enterprises/`,
            this.enterprise,
          )
          .then(() => this.closeModal())
          .catch((error) => {
            this.errorMessage = "Une erreur est survenue lors de l'ajout de l'entreprise, veuillez réessayer ou contacter le webmaster.";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.errorMessage = 'Un ou plusieurs champs sont invalides.';
      }
    },
    async updateRecord() {
      const validationSchemaObject = yup.object(this.validationSchema);
      const isEnterpriseValid = await validationSchemaObject.isValid(
        this.enterprise,
      );

      if (isEnterpriseValid) {
        this.errorMessage = null;

        this.loading = true;

        this.$http
          .patch(
            `${process.env.VUE_APP_INTRANET_API_URL}/commands/enterprises/${this.enterprise.id}`,
            this.enterprise,
          )
          .then(() => {
            this.closeModal();
          })
          .catch((error) => {
            this.errorMessage = "Une erreur est survenue lors de la modification de l'entreprise, veuillez réessayer ou contacter le webmaster.";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.errorMessage = 'Un ou plusieurs champs sont invalides.';
      }
    },
    deleteRecord() {
      this.errorMessage = null;

      Swal.fire({
        title: 'Êtes-vous sûr de vouloir supprimer cette entreprise ?',
        text: "Il ne sera pas possible d'annuler cette suppression !",
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill',
        },
        confirmButtonText: 'Oui, effacer',
        cancelButtonText: 'Non, conserver',
        buttonsStyling: false,
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.$http
              .delete(
                `${process.env.VUE_APP_INTRANET_API_URL}/enterprises/${this.enterprise.id}`,
              )
              .then(() => {
                this.closeModal();
              })
              .catch((error) => {
                this.errorMessage = "Une erreur est survenue lors de la suppression de l'entreprise, veuillez réessayer ou contacter le webmaster.";
              });
          }
        })
        .catch(() => {});
    },
    closeModal() {
      this.$emit('close');
      this.errorMessage = '';
    },
  },
};
</script>
