<template>
  <material-content :type="type" :title="title" />
</template>

<script>
import { MATERIALS_TYPES } from '@/enums/MaterialsTypes';
import MaterialContent from './UIComponents/MaterialContent.vue';

export default {
  components: {
    MaterialContent,
  },
  data() {
    return {
      type: MATERIALS_TYPES.station_videoconference.value,
      title: 'Liste des stations de vidéoconférences',
    };
  },
};
</script>
