import moment from 'moment';
import { app } from '../main';

const HOUR_START_MORNING = '08:00:00';
const HOUR_END_MORNING = '12:00:00';
const HOUR_START_AFTERNOON = '13:00:00';
const HOUR_END_AFTERNOON = '18:00:00';

export default class ScheduleService {
  static initSchedule() {
    return {
      id: null,
      monday: {
        morningStart: moment(HOUR_START_MORNING, 'HH:mm:ss'),
        morningEnd: moment(HOUR_END_MORNING, 'HH:mm:ss'),
        afternoonStart: moment(HOUR_START_AFTERNOON, 'HH:mm:ss'),
        afternoonEnd: moment(HOUR_END_AFTERNOON, 'HH:mm:ss'),
        morningComment: '',
        afternoonComment: '',
      },
      tuesday: {
        morningStart: moment(HOUR_START_MORNING, 'HH:mm:ss'),
        morningEnd: moment(HOUR_END_MORNING, 'HH:mm:ss'),
        afternoonStart: moment(HOUR_START_AFTERNOON, 'HH:mm:ss'),
        afternoonEnd: moment(HOUR_END_AFTERNOON, 'HH:mm:ss'),
        morningComment: '',
        afternoonComment: '',
      },
      wednesday: {
        morningStart: moment(HOUR_START_MORNING, 'HH:mm:ss'),
        morningEnd: moment(HOUR_END_MORNING, 'HH:mm:ss'),
        afternoonStart: moment(HOUR_START_AFTERNOON, 'HH:mm:ss'),
        afternoonEnd: moment(HOUR_END_AFTERNOON, 'HH:mm:ss'),
        morningComment: '',
        afternoonComment: '',
      },
      thursday: {
        morningStart: moment(HOUR_START_MORNING, 'HH:mm:ss'),
        morningEnd: moment(HOUR_END_MORNING, 'HH:mm:ss'),
        afternoonStart: moment(HOUR_START_AFTERNOON, 'HH:mm:ss'),
        afternoonEnd: moment(HOUR_END_AFTERNOON, 'HH:mm:ss'),
        morningComment: '',
        afternoonComment: '',
      },
      friday: {
        morningStart: moment(HOUR_START_MORNING, 'HH:mm:ss'),
        morningEnd: moment(HOUR_END_MORNING, 'HH:mm:ss'),
        afternoonStart: moment(HOUR_START_AFTERNOON, 'HH:mm:ss'),
        afternoonEnd: moment(HOUR_END_AFTERNOON, 'HH:mm:ss'),
        morningComment: '',
        afternoonComment: '',
      },
    };
  }

  static convertScheduleFromJson(schedule) {
    return {
      id: schedule.id,
      monday: {
        morningStart: schedule.mondayMorningStart
          ? moment(schedule.mondayMorningStart, 'HH:mm:ss')
          : null,
        morningEnd: schedule.mondayMorningEnd
          ? moment(schedule.mondayMorningEnd, 'HH:mm:ss')
          : null,
        afternoonStart: schedule.mondayAfternoonStart
          ? moment(schedule.mondayAfternoonStart, 'HH:mm:ss')
          : null,
        afternoonEnd: schedule.mondayAfternoonEnd
          ? moment(schedule.mondayAfternoonEnd, 'HH:mm:ss')
          : null,
        morningComment: schedule.mondayMorningComment,
        afternoonComment: schedule.mondayAfternoonComment,
      },
      tuesday: {
        morningStart: schedule.tuesdayMorningStart
          ? moment(schedule.tuesdayMorningStart, 'HH:mm:ss')
          : null,
        morningEnd: schedule.tuesdayMorningEnd
          ? moment(schedule.tuesdayMorningEnd, 'HH:mm:ss')
          : null,
        afternoonStart: schedule.tuesdayAfternoonStart
          ? moment(schedule.tuesdayAfternoonStart, 'HH:mm:ss')
          : null,
        afternoonEnd: schedule.tuesdayAfternoonEnd
          ? moment(schedule.tuesdayAfternoonEnd, 'HH:mm:ss')
          : null,
        morningComment: schedule.tuesdayMorningComment,
        afternoonComment: schedule.tuesdayAfternoonComment,
      },
      wednesday: {
        morningStart: schedule.wednesdayMorningStart
          ? moment(schedule.wednesdayMorningStart, 'HH:mm:ss')
          : null,
        morningEnd: schedule.wednesdayMorningEnd
          ? moment(schedule.wednesdayMorningEnd, 'HH:mm:ss')
          : null,
        afternoonStart: schedule.wednesdayAfternoonStart
          ? moment(schedule.wednesdayAfternoonStart, 'HH:mm:ss')
          : null,
        afternoonEnd: schedule.wednesdayAfternoonEnd
          ? moment(schedule.wednesdayAfternoonEnd, 'HH:mm:ss')
          : null,
        morningComment: schedule.wednesdayMorningComment,
        afternoonComment: schedule.wednesdayAfternoonComment,
      },
      thursday: {
        morningStart: schedule.thursdayMorningStart
          ? moment(schedule.thursdayMorningStart, 'HH:mm:ss')
          : null,
        morningEnd: schedule.thursdayMorningEnd
          ? moment(schedule.thursdayMorningEnd, 'HH:mm:ss')
          : null,
        afternoonStart: schedule.thursdayAfternoonStart
          ? moment(schedule.thursdayAfternoonStart, 'HH:mm:ss')
          : null,
        afternoonEnd: schedule.thursdayAfternoonEnd
          ? moment(schedule.thursdayAfternoonEnd, 'HH:mm:ss')
          : null,
        morningComment: schedule.thursdayMorningComment,
        afternoonComment: schedule.thursdayAfternoonComment,
      },
      friday: {
        morningStart: schedule.fridayMorningStart
          ? moment(schedule.fridayMorningStart, 'HH:mm:ss')
          : null,
        morningEnd: schedule.fridayMorningEnd
          ? moment(schedule.fridayMorningEnd, 'HH:mm:ss')
          : null,
        afternoonStart: schedule.fridayAfternoonStart
          ? moment(schedule.fridayAfternoonStart, 'HH:mm:ss')
          : null,
        afternoonEnd: schedule.fridayAfternoonEnd
          ? moment(schedule.fridayAfternoonEnd, 'HH:mm:ss')
          : null,
        morningComment: schedule.fridayMorningComment,
        afternoonComment: schedule.fridayAfternoonComment,
      },
    };
  }

  static convertScheduleToJson(schedule) {
    return {
      mondayMorningStart: schedule.monday.morningStart
        ? schedule.monday.morningStart.format('HH:mm:ss')
        : null,
      mondayMorningEnd: schedule.monday.morningEnd
        ? schedule.monday.morningEnd.format('HH:mm:ss')
        : null,
      mondayMorningComment: schedule.monday.morningComment,
      mondayAfternoonStart: schedule.monday.afternoonStart
        ? schedule.monday.afternoonStart.format('HH:mm:ss')
        : null,
      mondayAfternoonEnd: schedule.monday.afternoonEnd
        ? schedule.monday.afternoonEnd.format('HH:mm:ss')
        : null,
      mondayAfternoonComment: schedule.monday.afternoonComment,

      tuesdayMorningStart: schedule.tuesday.morningStart
        ? schedule.tuesday.morningStart.format('HH:mm:ss')
        : null,
      tuesdayMorningEnd: schedule.tuesday.morningEnd
        ? schedule.tuesday.morningEnd.format('HH:mm:ss')
        : null,
      tuesdayMorningComment: schedule.tuesday.morningComment,
      tuesdayAfternoonStart: schedule.tuesday.afternoonStart
        ? schedule.tuesday.afternoonStart.format('HH:mm:ss')
        : null,
      tuesdayAfternoonEnd: schedule.tuesday.afternoonEnd
        ? schedule.tuesday.afternoonEnd.format('HH:mm:ss')
        : null,
      tuesdayAfternoonComment: schedule.tuesday.afternoonComment,

      wednesdayMorningStart: schedule.wednesday.morningStart
        ? schedule.wednesday.morningStart.format('HH:mm:ss')
        : null,
      wednesdayMorningEnd: schedule.wednesday.morningEnd
        ? schedule.wednesday.morningEnd.format('HH:mm:ss')
        : null,
      wednesdayMorningComment: schedule.wednesday.morningComment,
      wednesdayAfternoonStart: schedule.wednesday.afternoonStart
        ? schedule.wednesday.afternoonStart.format('HH:mm:ss')
        : null,
      wednesdayAfternoonEnd: schedule.wednesday.afternoonEnd
        ? schedule.wednesday.afternoonEnd.format('HH:mm:ss')
        : null,
      wednesdayAfternoonComment: schedule.wednesday.afternoonComment,

      thursdayMorningStart: schedule.thursday.morningStart
        ? schedule.thursday.morningStart.format('HH:mm:ss')
        : null,
      thursdayMorningEnd: schedule.thursday.morningEnd
        ? schedule.thursday.morningEnd.format('HH:mm:ss')
        : null,
      thursdayMorningComment: schedule.thursday.morningComment,
      thursdayAfternoonStart: schedule.thursday.afternoonStart
        ? schedule.thursday.afternoonStart.format('HH:mm:ss')
        : null,
      thursdayAfternoonEnd: schedule.thursday.afternoonEnd
        ? schedule.thursday.afternoonEnd.format('HH:mm:ss')
        : null,
      thursdayAfternoonComment: schedule.thursday.afternoonComment,

      fridayMorningStart: schedule.friday.morningStart
        ? schedule.friday.morningStart.format('HH:mm:ss')
        : null,
      fridayMorningEnd: schedule.friday.morningEnd
        ? schedule.friday.morningEnd.format('HH:mm:ss')
        : null,
      fridayMorningComment: schedule.friday.morningComment,
      fridayAfternoonStart: schedule.friday.afternoonStart
        ? schedule.friday.afternoonStart.format('HH:mm:ss')
        : null,
      fridayAfternoonEnd: schedule.friday.afternoonEnd
        ? schedule.friday.afternoonEnd.format('HH:mm:ss')
        : null,
      fridayAfternoonComment: schedule.friday.afternoonComment,
    };
  }

  static createSchedule(schedule) {
    const payload = this.convertScheduleToJson(schedule);
    return app.config.globalProperties.$http.post(
      `${process.env.VUE_APP_INTRANET_API_URL}/commands/schedules/`,
      payload,
    );
  }

  static updateSchedule(schedule) {
    const payload = this.convertScheduleToJson(schedule);
    return app.config.globalProperties.$http.patch(
      `${process.env.VUE_APP_INTRANET_API_URL}/commands/schedules/${schedule.id}`,
      payload,
    );
  }
}
