<template>
  <div>
    <enterprise-modal
      :show="showEnterpriseModal"
      :current-operation="currentEnterpriseOperation"
      :enterprise="enterpriseSelected"
      @close="closeEnterpriseModal"
    />
    <button class="btn btn-export" @click="exportEntreprisesToXlsx">
      Exporter la liste au format Excel
    </button>
    <div class="row">
      <div class="col-md-12">
        <crud-list
          ref="crudList"
          :fetch-data="fetchEnterprises"
          title="Fournisseur de services"
          @create="showCreateEnterpriseModal"
          :defaultSort="{ prop: 'name', order: 'ascending' }"
          @sort-change="sortChange"
        >
          <button-column
            type="edit"
            button-title="Modifier l'entreprise"
            :show-on-mobile="true"
            :update-function="showUpdateEnterpriseModal"
          />
          <el-table-column
            label="Nom"
            min-width="200"
            prop="name"
            sortable="custum"
          >
            <template #default="slotProps">
              <div class="table-items-list-item">
                {{ `${slotProps.row.name}` }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Addresse" min-width="200">
            <template #default="slotProps">
              <div class="table-items-list-item">
                {{ `${slotProps.row.address}` }}
              </div>
            </template>
          </el-table-column>
          <button-column
            type="edit"
            button-title="Modifier l'entreprise"
            :show-on-mobile="false"
            :update-function="showUpdateEnterpriseModal"
          />
        </crud-list>
      </div>
    </div>
  </div>
</template>

<script>
import XLSX from 'xlsx';
import CrudList from './UIComponents/CrudList.vue';
import ButtonColumn from './UIComponents/ButtonColumn.vue';
import EnterpriseModal from './Modals/EnterpriseModal.vue';

export default {
  components: {
    CrudList,
    ButtonColumn,
    EnterpriseModal,
  },
  data() {
    return {
      enterprises: [],
      showEnterpriseModal: false,
      enterpriseSelected: {},
      currentEnterpriseOperation: 'create',
      sort: '&sort=name,asc',
    };
  },
  methods: {
    fetchEnterprises(pageNumber, pageSize) {
      return this.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/enterprises/search/findAllPage?`
            + `&page=${pageNumber - 1}&size=${pageSize}${this.sort}${this.sort}`,
        )
        .then((response) => ({
          records: response.data._embedded.enterprises
            ? response.data._embedded.enterprises
            : [],
          page: response.data.page ? response.data.page : 0,
        }));
    },
    fetchAllEnterprises() {
      return this.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/enterprises/search/findAll`,
        )
        .then((response) => (response.data._embedded.enterprises
          ? response.data._embedded.enterprises
          : []));
    },
    showCreateEnterpriseModal() {
      this.enterpriseSelected = {};
      this.showEnterpriseModal = true;
      this.currentEnterpriseOperation = 'create';
    },
    showUpdateEnterpriseModal(row) {
      this.enterpriseSelected = { ...row };
      this.currentEnterpriseOperation = 'edit';
      this.showEnterpriseModal = true;
    },
    closeEnterpriseModal() {
      this.showEnterpriseModal = false;
      // Refresh the crud-list component's data.
      this.$refs.crudList.fetch();
    },
    sortChange({ column, prop, order }) {
      const orderString = order === 'ascending' ? 'asc' : 'desc';
      this.sort = `&sort=${prop},${orderString}`;
      this.$refs.crudList.fetch();
    },
    exportEntreprisesToXlsx() {
      const wsData = [['Nom', 'Addresse']];

      this.fetchAllEnterprises().then((entreprises) => {
        entreprises.forEach((entreprise) => {
          wsData.push([entreprise.name, entreprise.address]);
        });

        const ws = XLSX.utils.aoa_to_sheet(wsData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'FournisseurDeService');
        XLSX.writeFile(wb, 'ciip_fournisseur_de_service.xlsx');
        this.$notify({
          title: 'Téléchargement',
          text: 'Téléchargement du fichier contenant les fournisseurs de service',
          type: 'success',
        });
      });
    },
  },
};
</script>
