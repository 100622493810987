<template>
  <div ref="wrapper">
    <div :key="tableKey">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import sortable from 'sortablejs';

export default {
  name: 'ElementUiElTableDraggable',
  props: {
    handle: {
      type: String,
      default: '',
    },
    animate: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      tableKey: 0,
    };
  },
  methods: {
    makeTableSortAble() {
      const table = this.$refs.wrapper.querySelector(
        '.el-table__body-wrapper tbody',
      );
      sortable.create(table, {
        handle: this.handle,
        animation: this.animate,
        onStart: () => {
          this.$emit('drag');
        },
        onEnd: ({ newIndex, oldIndex }) => {
          this.keepWrapperHeight(true);
          this.tableKey = Math.random();
          this.$emit('drop', { newIndex, oldIndex });
        },
      });
    },
    keepWrapperHeight(keep) {
      // eslint-disable-next-line prefer-destructuring
      const wrapper = this.$refs.wrapper;
      if (keep) {
        wrapper.style.minHeight = `${wrapper.clientHeight}px`;
      } else {
        wrapper.style.minHeight = 'auto';
      }
    },
  },
  mounted() {
    this.makeTableSortAble();
  },
  watch: {
    tableKey() {
      this.$nextTick(() => {
        this.makeTableSortAble();
        this.keepWrapperHeight(false);
      });
    },
  },
};
</script>
