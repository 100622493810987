<template>
  <SlideYUpTransition :duration="animationDuration">
    <div
      v-show="show"
      :class="[
        { 'show d-block': show },
        { 'd-none': !show },
        { 'modal-mini': type === 'mini' },
      ]"
      :aria-hidden="!show"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      @click.self="close"
    >
      <div
        :class="[{ 'modal-notice': type === 'notice' }, modalClasses]"
        class="modal-dialog"
      >
        <div class="modal-content">
          <div :class="headerClasses" class="modal-header">
            <slot name="close-button">
              <button
                v-if="showClose"
                :aria-hidden="!show"
                type="button"
                class="close"
                data-dismiss="modal"
                @click="close"
              >
                <i class="now-ui-icons ui-1_simple-remove" />
              </button>
            </slot>
            <slot name="header" />
          </div>

          <div :class="bodyClasses" class="modal-body">
            <slot />
          </div>

          <div :class="footerClasses" class="modal-footer">
            <slot name="footer" />
          </div>
        </div>
      </div>
    </div>
  </SlideYUpTransition>
</template>

<script>
import { SlideYUpTransition } from '@noction/vue-bezier';

export default {
  name: 'Modal',
  components: {
    SlideYUpTransition,
  },
  props: {
    show: Boolean,
    showClose: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: '',
      validator(value) {
        const acceptedValues = ['', 'notice', 'mini'];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: 'Modal type (notice|mini|"") ',
    },
    modalClasses: {
      type: [Object, String],
      description: 'Modal dialog css classes',
    },
    headerClasses: {
      type: [Object, String],
      description: 'Modal Header css classes',
    },
    bodyClasses: {
      type: [Object, String],
      description: 'Modal Body css classes',
    },
    footerClasses: {
      type: [Object, String],
      description: 'Modal Footer css classes',
    },
    animationDuration: {
      type: Number,
      default: 500,
      description: 'Modal transition duration',
    },
  },
  watch: {
    show(val) {
      const documentClasses = document.body.classList;
      if (val) {
        documentClasses.add('modal-open');
      } else {
        documentClasses.remove('modal-open');
      }
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
.modal.show {
  background-color: rgba(0, 0, 0, 0.3);
}
</style>
