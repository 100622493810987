<template>
  <div :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications />
    <router-view name="header" />
    <transition name="fade" mode="out-in">
      <div>
        <router-view />
      </div>
    </transition>
    <router-view name="footer" />
  </div>
</template>

<script>
// Loading some plugin css asynchronously
import 'sweetalert2/dist/sweetalert2.css';

export default {};
</script>

<style lang="scss"></style>
