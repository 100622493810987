<template>
  <div class="download-document-container">
    <h2>Téléchargement du document</h2>
    <router-link to="/documents/">
      <button class="btn">Retour aux documents</button>
    </router-link>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetchDocument(this.documentId).then((document) => this.openDocument(document));
  },
  computed: {
    documentId() {
      return this.$route.params.id;
    },
  },
  methods: {
    fetchDocument(documentId) {
      return this.$http
        .get(`${process.env.VUE_APP_INTRANET_API_URL}/documents/${documentId}`)
        .then((response) => response.data);
    },
    openDocument(doc) {
      if (doc.isExternal) {
        window.open(doc.path, '_blank');
        return null;
      }
      return this.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/commands/documents?path=docs/&name=${doc.path}`,
          {
            responseType: 'blob',
          },
        )
        .then((response) => {
          // Download the document by first creating a temporary link tag, which redirect to a temporary URL that contains the blob file.
          // Then we simulate a click on it in order to download the file.
          const documentBlob = response.data;
          const urlCreatedFromBlob = window.URL.createObjectURL(documentBlob);
          const tempLinkTag = document.createElement('a');
          tempLinkTag.href = urlCreatedFromBlob;
          tempLinkTag.setAttribute('download', doc.path);
          document.body.appendChild(tempLinkTag);
          tempLinkTag.click();
          tempLinkTag.remove();
        })
        .catch(() => {
          this.$notify({
            title: 'Error',
            text: 'Une erreur est survenue lors du téléchargement du document, veuillez réessayer, vérifier le lien ou contacter le support.',
            type: 'danger',
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.download-document-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
