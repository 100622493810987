<template>
  <div>
    <create-car-modal
      :show="CarModal"
      :carProps="carSelected"
      :currentOperation="currentCarOperation"
      @close="closeCarModal"
    />

    <button class="btn btn-round btn-export" @click="exportToXslx">
      Exporter la liste au format Excel
    </button>

    <div class="row">
      <div class="col-md-12">
        <crud-list
          ref="crudList"
          :fetch-data="fetchCars"
          title="Véhicules"
          @create="showCarModal"
          :defaultSort="{ prop: 'licensePlateNumber', order: 'ascending' }"
          @sort-change="sortChange"
        >
          <button-column
            type="edit"
            button-title="Modifier le véhicule"
            :show-on-mobile="true"
            :update-function="updateCar"
          />
          <el-table-column
            label="Numéro de plaque"
            min-width="200"
            prop="licensePlateNumber"
            sortable="custum"
          >
            <template #default="slotProps">
              <div class="table-items-list-item">
                {{ `${slotProps.row.licensePlateNumber}` }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Propriétaire(s)" min-width="180">
            <template #default="slotProps">
              <div class="table-items-list-item">
                <div
                  v-for="collaborator in slotProps.row.collaborators"
                  :key="collaborator.firstname + collaborator.lastname"
                  class="table-double-cells"
                >
                  <router-link
                    :to="{
                      name: 'CollaboratorDetails',
                      params: { id: collaborator.id },
                    }"
                  >
                    <span>
                      {{ `${collaborator.firstname} ${collaborator.lastname}` }}
                    </span>
                  </router-link>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Téléphone(s)" min-width="150">
            <template #default="slotProps">
              <div class="table-items-list-item">
                <div
                  v-for="collaborator in slotProps.row.collaborators"
                  :key="collaborator.lastname"
                  class="table-double-cells"
                >
                  <div
                    v-if="
                      collaborator.internalphone || collaborator.privatephone
                    "
                  >
                    <span>
                      {{ collaborator.internalphone }}<br />
                      {{ collaborator.privatephone }}
                    </span>
                  </div>
                  <div
                    v-if="
                      !collaborator.internalphone && !collaborator.privatephone
                    "
                  >
                    <span> - </span>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <button-column
            type="edit"
            button-title="Modifier le véhicule"
            :show-on-mobile="false"
            :update-function="updateCar"
          />
        </crud-list>
      </div>
    </div>
  </div>
</template>
<script>
import XLSX from 'xlsx';
import MixinFetchData from '@/mixins/mixinFetchData';
import CrudList from './UIComponents/CrudList.vue';
import ButtonColumn from './UIComponents/ButtonColumn.vue';
import CreateCarModal from './Modals/CreateCarModal.vue';

export default {
  components: {
    CrudList,
    ButtonColumn,
    CreateCarModal,
  },
  mixins: [MixinFetchData],
  data() {
    return {
      cars: [],
      CarModal: false,
      carSelected: {},
      currentCarOperation: 'create',
      sort: '&sort=licensePlateNumber,asc',
    };
  },
  methods: {
    // Fetch cars with pagination
    fetchCars(pageNumber, pageSize) {
      return this.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/cars/search/findAllPage?projection=carCollaborators`
            + `&page=${pageNumber - 1}&size=${pageSize}${this.sort}`,
        )
        .then((response) => {
          const newCars = response.data._embedded.cars;
          return {
            records: newCars,
            page: response.data.page ? response.data.page : 0,
          };
        })
        .catch(() => {
          this.$notify({
            title: 'Error',
            text: 'Une erreur est survenue lors de la récupération des données, veuillez réessayer ou contacter le webmaster.',
            type: 'danger',
          });
        });
    },
    // Fetch all cars at once
    fetchAllCars() {
      return this.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/cars/search/findAll?projection=carCollaborators&sort=licensePlateNumber,asc`,
        )
        .then((response) => {
          const newCars = response.data._embedded.cars;
          return {
            records: newCars,
            page: response.data.page ? response.data.page : 0,
          };
        })
        .catch(() => {
          this.$notify({
            title: 'Error',
            text: 'Une erreur est survenue lors de la récupération des données, veuillez réessayer ou contacter le webmaster.',
            type: 'danger',
          });
        });
    },
    showCarModal() {
      this.currentCarOperation = 'create';
      this.CarModal = true;
    },
    closeCarModal() {
      this.CarModal = false;
      // refresh data when closing modal
      this.$refs.crudList.fetch();
    },
    updateCar(row, event) {
      event.stopPropagation();

      this.currentCarOperation = 'edit';
      const ownersIds = row.collaborators.map(
        (collaborator) => collaborator.id,
      );
      this.carSelected = {
        id: row.id,
        licensePlateNumber: row.licensePlateNumber,
        ownersIds,
      };

      this.CarModal = true;
    },
    exportToXslx() {
      const wsData = [
        [
          'Numéro de plaque',
          'Propriétaire',
          'Téléphone interne',
          'Téléphone privé',
          'Jour(s) de parcage',
        ],
      ];

      this.fetchAllCars().then((res) => {
        const merge = []; // Array of merge cells
        let nbRow = 1; // compter for the rows. used to know witch row to merge

        // Go throw all cars
        res.records.forEach((car) => {
          // If the cars have owners
          if (car.collaborators.length > 0) {
            // Treate all owners
            car.collaborators.forEach((collaborator, index) => {
              const owner = `${collaborator.firstname} ${collaborator.lastname}`;
              // only add licensePlate for the first owner and add merge info
              if (index === 0) {
                if (car.collaborators.length > 1) {
                  // Merge info is more the one owner
                  merge.push({
                    s: {
                      r: nbRow,
                      c: 0,
                    },
                    e: {
                      r: nbRow + car.collaborators.length - 1,
                      c: 0,
                    },
                  });
                  merge.push({
                    s: {
                      r: nbRow,
                      c: 4,
                    },
                    e: {
                      r: nbRow + car.collaborators.length - 1,
                      c: 4,
                    },
                  });
                }
                wsData.push([
                  car.licensePlateNumber,
                  owner,
                  collaborator.internalphone,
                  collaborator.privatephone,
                ]);
              } else {
                // Dont add licence plate when more then two owners
                wsData.push([
                  '',
                  owner,
                  collaborator.internalphone,
                  collaborator.privatephone,
                  '',
                ]);
              }
              nbRow++;
            });
          } else {
            // Car without owners
            wsData.push([car.licensePlateNumber, '', '', '', '']);
            nbRow++;
          }
        });

        const ws = XLSX.utils.aoa_to_sheet(wsData); // Write into cells
        ws['!merges'] = merge; // Merge cells
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Liste_detenteur_vehicules');
        XLSX.writeFile(wb, 'liste_detenteur_vehicules.xlsx');
      });
    },
    sortChange({ column, prop, order }) {
      const orderString = order === 'ascending' ? 'asc' : 'desc';
      this.sort = `&sort=${prop},${orderString}`;
      this.$refs.crudList.fetch();
    },
  },
};
</script>

<style lang="scss" scoped>
.table-double-cells:not(:first-child) {
  border-top-width: 1px;
  border-top-color: #ebeef5;
  border-top-style: solid;
}

.table-double-cells {
  height: 50px;
  display: flex;
  align-items: center;
}

@media screen and (max-width: $mobile-max-width) {
  .btn-export {
    width: 100%;
  }
}
</style>
