const MATERIALS_TYPES_ARRAY = [
  {
    value: 'COMPUTER',
    name: 'Poste de travail',
  },
  {
    value: 'HEADSET',
    name: 'Casque téléphonique',
  },
  {
    value: 'PRINTER',
    name: 'Imprimante',
  },
  {
    value: 'SCANNER',
    name: 'Scanner',
  },
  {
    value: 'DEVICE',
    name: 'Périphérique',
  },
  {
    value: 'DICTAPHONE',
    name: 'Dictaphone',
  },
  {
    value: 'BEAMER',
    name: 'Projecteur',
  },
  {
    value: 'SERVER',
    name: 'Serveur',
  },
  {
    value: 'STATION_VIDEOCONFERENCE',
    name: 'Station de vidéoconférence',
  },
  {
    value: 'ROUTER',
    name: 'Routeur',
  },
];

export const MATERIALS_TYPES = {
  computer: {
    value: 'COMPUTER',
    name: 'Poste de travail',
  },
  headset: {
    value: 'HEADSET',
    name: 'Casque téléphonique',
  },
  printer: {
    value: 'PRINTER',
    name: 'Imprimante',
  },
  scanner: {
    value: 'SCANNER',
    name: 'Scanner',
  },
  device: {
    value: 'DEVICE',
    name: 'Périphérique',
  },
  dictaphone: {
    value: 'DICTAPHONE',
    name: 'Dictaphone',
  },
  beamer: {
    value: 'BEAMER',
    name: 'Projecteur',
  },
  server: {
    value: 'SERVER',
    name: 'Serveur',
  },
  station_videoconference: {
    value: 'STATION_VIDEOCONFERENCE',
    name: 'Station de vidéoconférence',
  },
  router: {
    value: 'ROUTER',
    name: 'Routeur',
  },
};

export const MATERIALS_SUBTYPES_ARRAY = [
  {
    name: 'Fixe',
    value: 'FIXE',
  },
  {
    name: 'Portable',
    value: 'PORTABLE',
  },
];

export const MATERIALS_STATUS_ARRAY = [
  {
    name: 'Assigné',
    value: 'ASSIGNED',
  },
  {
    name: 'Non assignée',
    value: 'NOT_ASSIGNED',
  },
  {
    name: 'Réservable',
    value: 'RESERVABLE',
  },
];

export const MATERIALS_STATUS = {
  assigned: {
    name: 'Assigné',
    value: 'ASSIGNED',
  },
  not_assigned: {
    name: 'Non assignée',
    value: 'NOT_ASSIGNED',
  },
  reservable: {
    name: 'Réservable',
    value: 'RESERVABLE',
  },
};

export default MATERIALS_TYPES_ARRAY;
