/* eslint-disable */
export const faqInterflex = [
  {
    question:
      'Je travaille à temps partiel et je dois assister à une séance un jour où je ne suis pas censé.e travailler. Comment dois-je procéder pour inscrire cette rocade ?',
    answer:
      "Il suffit simplement d'introduire l'horaire effectif le jour travaillé. Une irrégularité s'inscrit, mais n'a  aucune  influence  sur  votre  décompte  mensuel.  Il  n'est  pas  possible  de  supprimer  cette mention ; elle n'apparaît pas sur votre décompte mensuel.",
  },
  {
    question:
      'Interflex indique "temps obligatoire violé". S\'agit-il d\'un bug ?',
    answer:
      'Interflex le mentionne lorsque les horaires bloqués où la présence est obligatoire (9h00 à 11h00 et de 14h30 à 16h00) ne sont pas respectés.',
  },
  {
    question: 'Peut-on modifier le terme "temps obligatoire violé" ?',
    answer:
      "Non, cela n'est pas possible. Si vous souhaitez expliquer le motif, vous pouvez l'ajouter par écrit sur votre décompte papier (pas obligatoire).",
  },
  {
    question: 'Comment indiquer mon rendez-vous de médecin dans Interflex ?',
    answer:
      "En cas d'empêchement non-fautif du collaborateur ou de la collaboratrice de travailler dû à la maladie ou à un accident, les visites médicales chez un médecin peuvent être prises sur le temps de travail durant les heures bloquées et comptées comme telles jusqu'à 1h30 maximum par visite. (Attention : l’horaire journalier maximum est de 8 heures). La visite médicale prise en dehors des horaires bloqués ne doit pas être inscrite dans Interflex.",
  },
  {
    question:
      'Comment  dois-je  faire  pour  noter  une  semaine  de  vacances/absences  lorsque  je  travaille  à temps partiel ?',
    answer:
      "Comme votre profil est paramétré en fonction de votre taux d'activité, il suffit de sélectionner la semaine entière dans Interfex. Le calcul se fait automatiquement.",
  },
  {
    question:
      "Je n'arrive pas à consulter mon décompte mensuel (PDF ou Excel à générer), cela indique une erreur. Cela ne fonctionne ni sur Explorer, ni sur Chrome. Que faire ?",
    answer:
      'Pour imprimer votre décompte mensuel, vous devez indiquer : 06.2020 et non juin 2020.',
  },
  {
    question:
      'Dans le journal mensuel et le décompte, je ne comprends pas comment le système calcule mes heures, serait-il possible de voir le malus/bonus en heures et/ou en minutes ?',
    answer: 'Il faut consulter les détails sous Gestion du temps – compte.',
  },
  {
    question:
      "J'ai travaillé toute la journée, sans prendre de pause à midi, je perds donc 30 minutes de temps de travail ?",
    answer:
      'Pour rappel, une pause minimum de 30 minutes doit légalement être observée, sauf cas exceptionnel validé par son.sa supérieur.e direct.e (cf. travail non-stop exceptionnel). Pour ce faire, vous devez cocher la case "pause de midi payée".',
  },
  {
    question:
      "Serait-il possible de voir les détails de décompte des heures (nombre d'heures travaillées + pauses) pour ne pas avoir à calculer manuellement combien de temps de pause le système a déduit ?",
    answer:
      'Le décompte mensuel permet de voir le temps fait, le temps dû, le cumul mensuel, mais pas le temps de pause.',
  },
  {
    question:
      "Dans le vade-mecum relatif à Interflex, p.10, il est noté que le solde de jours non repris durant l'année civile n'est en aucun cas reportable sur l'année suivante.",
    answer:
      "Le solde des heures non reprises en fin d'année est reporté sur l'année suivante. Par contre, les jours compensatoires qui ne sont pas utilisés d'ici au 31 décembre ne sont pas reportés sur l'année civile suivante.",
  },
  {
    question:
      "Je dois faire des modifications dans Interflex, mais je n'y arrive plus. Pourquoi ?",
    answer:
      "Les modifications peuvent être apportées jusqu'au 6 du mois suivant. Ensuite le système est bloqué, seule l'assistante de direction peut apporter des corrections.",
  },
  {
    question:
      "Sur mon décompte, la matinée de vacances que j'ai prise comptabilise uniquement 2 heures. Ne devrait-il pas comptabiliser 4 heures de temps ?",
    answer:
      "Comme votre profil est paramétré en fonction de votre taux d'activité, lorsque vous prenez un matin ou un après-midi de congé, pour un emploi à temps partiel, il faut indiquer 1 jour entier, autrement le temps de travail pour une matinée (ou un après-midi) sera divisé par 2.",
  },
  {
    question:
      'Je dois malheureusement annuler mes vacances, comment les supprimer dans Interflex ?',
    answer:
      'Cette modification doit être faite depuis Interflex (avant le 6 du mois suivant) ; onglet : gestion du temps => absences => sélectionner les dates des vacances que vous souhaitez supprimer, puis cliquer sur "supprimer". Un mail de confirmation de votre supérieur.e hiérarchique vous parviendra lorsqu\'il/elle aura accepté votre demande.',
  },
  {
    question: 'A qui dois-je transmettre mon décompte mensuel ?',
    answer:
      "A votre supérieur.e hiérarchique, selon l'unité dont vous dépendez (SG, UMER-SO, UMER-FP, IRDP).",
  },
  {
    question: 'Pourquoi faut-il badger en ligne ?',
    answer:
      "Comme Interflex s'ouvre dès l'ouverture de votre session, prenez l'habitude de badger directement. Ce procédé est rapide, permet d'éviter certains oublis et autres manipulations ultérieures qui demandent du temps. Le fait de badger indique que la personne est atteignable ou absente (cf. tableau à lampes). Pour respecter notre règlement de fonctionnement et les directives internes qui ont pour but la protection des collaborateurs et collaboratrices de notre institution.",
  },
  {
    question: 'Quand utiliser le mode "corrections" ?',
    answer:
      'Lorsque j\'ai : fait une erreur, oublié de badger ou débadger, que je participe à une séance ou que je suis en déplacement (motif d\'absence à inscrire "30" travail externe).',
  },
  {
    question: 'Comment inscrire le télétravail ?',
    answer: 'En badgeant normalement. Le motif 33 est indiqué le jour suivant.',
  },
  {
    question: 'Que signifie MAB ?',
    answer:
      'Lorsque vous oubliez de quitter Interflex en fin de journée (hors horaire-cadre).',
  },
  {
    question: 'Comment reprendre des heures compensatoires ?',
    answer:
      'En sélectionnant uniquement le motif d\'absence "82", mais sans inscrire d\'heures.',
  },
  {
    question: "Je déménage, comment l'inscrire dans Interflex ?",
    answer:
      "Lorsque vous déménagez, vous avez droit à un jour de congé payé (motif d'absence : 8).",
  },
];
