<template>
  <modal
    :show="show"
    footer-classes="justify-content-center"
    type="notice"
    @close="close"
  >
    <template #header>
      <h5 class="modal-title">Slides</h5>
    </template>
    <div class="d-flex">
      <el-select
        v-model="selectedSlide"
        class="select-info slide-select"
        size="large"
        placeholder="Sélectionner"
      >
        <el-option label="Slide 1" :value="0" />
        <el-option label="Slide 2" :value="1" />
        <el-option
          v-for="(slide, index) in slides"
          :key="index + 2"
          :label="`Slide ${index + 3}`"
          :value="index + 2"
        />
      </el-select>
      <button
        class="btn btn-icon btn-round btn-danger btn-crud-slides"
        type="button"
        title="Supprimer le slide sélectionné"
        :disabled="selectedSlide <= 1"
        @click="deleteSelectedSlide"
      >
        <i class="fa fa-trash" />
      </button>
      <button
        class="btn btn-icon btn-round btn-success btn-crud-slides"
        type="button"
        title="Ajouter un slide"
        @click="addSlide"
      >
        <i class="fa fa-plus" />
      </button>
    </div>
    <div v-if="selectedSlide === 0" class="mt-4">
      <p>
        <em>
          Il est impossible de modifier ou de supprimer le premier slide, car il
          s'agit d'un slide automatique qui contient la dernière actualité
          publiée sur Agenda CIIP.
        </em>
      </p>
    </div>
    <div v-if="selectedSlide === 1" class="mt-4">
      <p>
        <em>
          Il est impossible de modifier ou de supprimer le deuxième slide, car
          il s'agit d'un slide automatique qui contient la prochaine
          manifestation à venir sur Agenda CIIP.
        </em>
      </p>
    </div>
    <Form
      v-if="selectedSlide > 1 && slides[selectedSlide - 2]"
      class="mt-3"
      :validation-schema="validationSchema"
      validate-on-mount
    >
      <div class="form-group">
        <label class="control-label">*Titre</label>
        <Field
          name="title"
          v-model="slides[selectedSlide - 2].title"
          v-slot="{ field, errorMessage }"
        >
          <textarea v-bind="field" class="form-control" rows="3" />
          <small v-show="errorMessage" class="text-danger">
            Le titre est requis.
          </small>
        </Field>
      </div>
      <div class="form-group">
        <label class="control-label">*Contenu</label>
        <Field
          name="content"
          v-model="slides[selectedSlide - 2].content"
          v-slot="{ componentField, errorMessage }"
        >
          <rich-text-editor v-bind="componentField" />
          <small v-show="errorMessage" class="text-danger">
            Le contenu est requis.
          </small>
        </Field>
      </div>
      <div class="form-group">
        <label class="control-label"
          >Lien externe (bouton "plus d'informations")</label
        >
        <input
          v-model="slides[selectedSlide - 2].link"
          class="form-control"
          type="text"
          name="link"
        />
      </div>
      <p><label>* champs obligatoires</label></p>
      <p v-if="errorMessage" class="error-message-in-modal mt-0">
        {{ errorMessage }}
      </p>
    </Form>
    <template #footer>
      <div class="justify-content-center d-flex">
        <p-button type="success" round @click="updateSlides">
          Mettre à jour
        </p-button>
        <span>&nbsp;</span>
        <p-button type="light" round @click="$emit('close')">
          Annuler
        </p-button>
      </div>
    </template>
  </modal>
</template>

<script>
import { Form, Field } from 'vee-validate';
import * as yup from 'yup';
import MixinFetchData from '@/mixins/mixinFetchData';
import Modal from '../../UIComponents/Modal.vue';
import RichTextEditor from '../UIComponents/richTextEditor/RichTextEditor.vue';

export default {
  components: {
    Form,
    Field,
    RichTextEditor,
    Modal,
  },
  mixins: [MixinFetchData],
  props: ['show'],
  data() {
    return {
      selectedSlide: null,
      errorMessage: null,
      validationSchema: {
        title: yup.string().required(),
        content: yup.string().required(),
      },
    };
  },
  watch: {
    slides: {
      handler(newVal) {
        if (newVal.length && !this.selectedSlide) {
          this.selectedSlide = 0;
        }
      },
      deep: true,
    },
    show: {
      handler(newVal) {
        if (newVal) {
          this.selectedSlide = 0;
          this.errorMessage = null;
          // This function is declared in the MixinFetchData mixin.
          this.fetchSlides();
        }
      },
    },
  },
  created() {
    this.fetchSlides();
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async updateSlides() {
      const validationSchemaObject = yup.object(this.validationSchema);
      let valid = true;

      this.errorMessage = null;

      // eslint-disable-next-line no-restricted-syntax
      for (const [index, slide] of this.slides.entries()) {
        if (valid) {
          // eslint-disable-next-line no-await-in-loop
          const isSlideValid = await validationSchemaObject.isValid(slide);

          if (!isSlideValid) {
            valid = false;
            this.errorMessage = `Veuillez entrer un titre et un contenu pour le slide ${
              index + 3
            }.`;
            this.selectedSlide = index + 2;
          }
        }
      }

      if (valid) {
        this.$http
          .patch(
            `${process.env.VUE_APP_INTRANET_API_URL}/commands/slides/`,
            this.slides,
          )
          .then(() => {
            this.close();
          })
          .catch(() => {
            this.errorMessage = 'Une erreur est survenue lors de la modification des slides, veuillez réessayer ou contacter le webmaster.';
          });
      }
    },
    deleteSelectedSlide() {
      this.errorMessage = null;

      this.slides.splice(this.selectedSlide - 2, 1);

      if (this.selectedSlide - 2 === this.slides.length) {
        this.selectedSlide -= 1;
      }
    },
    addSlide() {
      this.errorMessage = null;

      this.slides.push({
        title: null,
        content: null,
        link: null,
      });

      this.selectedSlide = this.slides.length + 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.slide-select {
  width: 100%;
}

.btn-crud-slides {
  margin: 0 0 0 0.5em;
}
</style>
