<template>
  <div>
    <create-application-modal
      :show="ApplicationModal"
      :current-operation="currentApplicationOperation"
      :applicationProps="applicationSelected"
      @close="closeApplicationModal"
    />
    <div class="action-container">
      <button class="btn btn-export" @click="exportToXslx">
        Exporter la liste au format Excel
      </button>
      <div class="details-container">
        <el-switch
          v-model="showDetails"
          active-color="#13ce66"
          @change="showDetailsChange"
        >
        </el-switch>
        <span class="details-span">Plus de détails</span>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <crud-list
          ref="crudList"
          title="Liste des applications"
          :fetch-data="fetchApplications"
          @create="showApplicationModal"
          :defaultSort="{ prop: 'name', order: 'ascending' }"
          @sort-change="sortChange"
        >
          <button-column
            type="edit"
            button-title="Modifier l'application"
            :show-on-mobile="true"
            :update-function="updateApplication"
          />
          <el-table-column
            label="Nom"
            min-width="290"
            prop="name"
            sortable="custom"
          >
            <template #default="slotProps">
              <div class="table-items-list-item">
                {{ slotProps.row.name ? slotProps.row.name : '-' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-if="showDetails"
            label="Identifiant"
            min-width="160"
            prop="identifier"
            sortable="custom"
          >
            <template #default="slotProps">
              <div class="table-items-list-item">
                {{ slotProps.row.identifier ? slotProps.row.identifier : '-' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-if="showDetails"
            label="Catégorie"
            min-width="200"
            prop="categorie"
            sortable="custom"
          >
            <template #default="slotProps">
              <div class="table-items-list-item">
                {{
                  slotProps.row.categorieFormat
                    ? slotProps.row.categorieFormat
                    : '-'
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-if="showDetails"
            label="Modèle"
            min-width="150"
            prop="modal"
            sortable="custom"
          >
            <template #default="slotProps">
              <div class="table-items-list-item">
                {{ slotProps.row.modal ? slotProps.row.modal : '-' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-if="showDetails"
            label="Date d'achat"
            min-width="170"
            prop="purchaseDate"
            sortable="custom"
          >
            <template #default="slotProps">
              <div class="table-items-list-item">
                {{
                  slotProps.row.purchaseDateFormat
                    ? slotProps.row.purchaseDateFormat
                    : '-'
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-if="showDetails"
            label="Prix (unitaire)"
            min-width="170"
            prop="unitPrice"
          >
            <template #default="slotProps">
              <div class="table-items-list-item">
                {{
                  slotProps.row.unitPrice
                    ? slotProps.row.unitPrice.toFixed(2) + ' CHF'
                    : '-'
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-if="showDetails"
            label="OS"
            min-width="150"
            prop="operatingSystem"
            sortable="custom"
          >
            <template #default="slotProps">
              <div class="table-items-list-item">
                {{
                  slotProps.row.operatingSystem
                    ? slotProps.row.operatingSystem
                    : '-'
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-if="showDetails"
            label="Version"
            min-width="130"
            prop="version"
            sortable="custom"
          >
            <template #default="slotProps">
              <div class="table-items-list-item">
                {{ slotProps.row.version ? slotProps.row.version : '-' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-if="showDetails"
            label="URL"
            min-width="130"
            prop="version"
          >
            <template #default="slotProps">
              <div class="table-items-list-item">
                {{ slotProps.row.url ? slotProps.row.url : '-' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Responsable" min-width="170">
            <template #default="slotProps">
              <div class="table-items-list-item">
                <span v-if="slotProps.row.responsible">
                  {{
                    `${slotProps.row.responsible.firstname} ${slotProps.row.responsible.lastname}`
                  }}
                </span>
                <span v-else> - </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-if="showDetails"
            label="Utilisateurs"
            min-width="500"
            prop="remark"
          >
            <template #default="slotProps">
              <div class="table-items-list-item">
                <span
                  v-for="(user, index) in slotProps.row.users"
                  :key="user.id"
                  :class="{ inactif: !user.isActive }"
                  class="user"
                >
                  <router-link
                    :to="{
                      name: 'CollaboratorDetails',
                      params: { id: user.id },
                    }"
                  >
                    <el-tooltip
                      v-if="!user.isActive"
                      content="Inactif"
                      placement="bottom"
                    >
                      <i class="fa fa-user-times"></i>
                    </el-tooltip>
                    {{ user.firstname }} {{ user.lastname }}
                    {{ index === slotProps.row.users.length - 1 ? '' : ',' }}
                  </router-link>
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Fournisseur" min-width="200">
            <template #default="slotProps">
              <div class="table-items-list-item">
                <span v-if="slotProps.row.provider">
                  {{
                    `${slotProps.row.provider.name}, ${slotProps.row.provider.address}`
                  }}
                </span>
                <span v-else> - </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="Description"
            min-width="200"
            prop="description"
          >
            <template #default="slotProps">
              <div class="table-items-list-item">
                {{
                  slotProps.row.description ? slotProps.row.description : '-'
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-if="showDetails"
            label="Remarques"
            min-width="200"
            prop="remark"
          >
            <template #default="slotProps">
              <div class="table-items-list-item">
                {{ slotProps.row.remark ? slotProps.row.remark : '-' }}
              </div>
            </template>
          </el-table-column>
          <button-column
            type="edit"
            button-title="Modifier l'application"
            :show-on-mobile="false"
            :update-function="updateApplication"
          />
        </crud-list>
      </div>
    </div>
  </div>
</template>
<script>
import XLSX from 'xlsx';
import moment from 'moment';
import { ElSwitch, ElTooltip } from 'element-plus';
import MixinFetchData from '@/mixins/mixinFetchData';
import APPLICATION_CATEGORIES from '@/enums/ApplicationsCategories';
import CrudList from './UIComponents/CrudList.vue';
import CreateApplicationModal from './Modals/CreateApplicationModal.vue';
import ButtonColumn from './UIComponents/ButtonColumn.vue';

export default {
  components: {
    CrudList,
    CreateApplicationModal,
    ButtonColumn,
    [ElSwitch.name]: ElSwitch,
    [ElTooltip.name]: ElTooltip,
  },
  mixins: [MixinFetchData],
  data() {
    return {
      applications: [],
      ApplicationModal: false,
      applicationSelected: {},
      currentApplicationOperation: 'create',
      sort: '&sort=name,asc', // do not forget the &
      showDetails: false,
    };
  },
  methods: {
    // Fetch applications with pagination
    fetchApplications(pageNumber, pageSize) {
      return this.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/applications/search/findAllPage`
            + `?projection=applicationSummary&page=${
              pageNumber - 1
            }&size=${pageSize}${this.sort}`,
        )
        .then((response) => {
          if (response.data) {
            let applicationsFetch = response.data._embedded.applications
              ? response.data._embedded.applications
              : [];
            applicationsFetch = applicationsFetch.map((app) => ({
              ...app,
              purchaseDateFormat: app.purchaseDate
                ? moment(app.purchaseDate).format('DD.MM.YYYY')
                : null,
              categorieFormat: app.categorie
                ? APPLICATION_CATEGORIES.filter(
                  (cat) => cat.value === app.categorie,
                )[0].name
                : null,
            }));
            return {
              records: applicationsFetch,
              page: response.data.page ? response.data.page : 0,
            };
          }
          return {
            records: [],
            page: 0,
          };
        })
        .catch(() => {
          this.$notify({
            title: 'Error',
            text: 'Une erreur est survenue lors de la récupération des données, veuillez réessayer ou contacter le webmaster.',
            type: 'danger',
          });
        });
    },
    // Fetch all applications at once
    fetchAllApplications() {
      return this.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/applications/search/findAll?projection=applicationSummary`,
        )
        .then((response) => {
          if (response.data) {
            let applicationsFetch = response.data._embedded.applications
              ? response.data._embedded.applications
              : [];
            applicationsFetch = applicationsFetch.map((app) => ({
              ...app,
              purchaseDate: app.purchaseDate
                ? moment(app.purchaseDate).format('DD.MM.YYYY')
                : null,
              categorieFormat: app.categorie
                ? APPLICATION_CATEGORIES.filter(
                  (cat) => cat.value === app.categorie,
                )[0].name
                : null,
            }));
            return {
              records: applicationsFetch,
              page: response.data.page ? response.data.page : 0,
            };
          }
          return {
            records: [],
            page: 0,
          };
        })
        .catch(() => {
          this.$notify({
            title: 'Error',
            text: 'Une erreur est survenue lors de la récupération des données, veuillez réessayer ou contacter le webmaster.',
            type: 'danger',
          });
        });
    },
    showApplicationModal() {
      this.currentApplicationOperation = 'create';
      this.ApplicationModal = true;
    },
    closeApplicationModal() {
      this.ApplicationModal = false;
      // refresh data when closing modal
      this.$refs.crudList.fetch();
    },
    updateApplication(row, event) {
      event.stopPropagation();

      this.currentApplicationOperation = 'edit';
      this.applicationSelected = {
        ...row,
        responsibleId: row.responsible ? row.responsible.id : null,
        providerId: row.provider ? row.provider.id : null,
        usersIds: row.users ? row.users.map((user) => user.id) : null,
      };

      this.ApplicationModal = true;
    },
    exportToXslx() {
      const wsData = [
        [
          'Identifiant',
          'Nom',
          'Catégorie',
          'Modèle',
          "Date d'achat",
          'OS',
          'Version',
          'Description',
          'Remarques',
          'Prix unitaire',
          'Responsable',
          'Utilisateurs',
        ],
      ];

      this.fetchAllApplications().then((res) => {
        res.records.forEach((app) => {
          let userStr = '';
          app.users.forEach((user) => {
            userStr += `${user.firstname} ${user.lastname}, `;
          });
          wsData.push([
            app.identifier,
            app.name,
            app.categorieFormat,
            app.modal,
            app.purchaseDate,
            app.operatingSystem,
            app.version,
            app.description,
            app.remark,
            app.unitPrice,
            app.responsible ? app.responsible.fullName : '',
            userStr,
          ]);
        });

        const ws = XLSX.utils.aoa_to_sheet(wsData); // Write into cells
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Liste_applications');
        XLSX.writeFile(wb, 'liste_applications.xlsx');
      });
    },
    sortChange({ column, prop, order }) {
      const orderString = order === 'ascending' ? 'asc' : 'desc';
      this.sort = `&sort=${prop},${orderString}`;
      this.$refs.crudList.fetch();
    },
    showDetailsChange(value) {
      if (value) {
        this.$notify({
          title: 'Plus de détails',
          text: 'Attention, il se peut que des données dépassent de votre écran. Vous pouvez défiler horizontalement le tableau grâce à la combinaison Shift + Scroll, ou via la barre de défilement située en bas du tableau.',
          type: 'warning',
          duration: 5000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.table-double-cells:not(:first-child) {
  border-top-width: 1px;
  border-top-color: #ebeef5;
  border-top-style: solid;
}

.table-double-cells {
  height: 50px;
  display: flex;
  align-items: center;
}

.action-container {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.details-container {
  display: flex;
  align-items: center;
}

.details-span {
  margin-left: 1em;
}

.user a {
  color: inherit !important;
}

.inactif {
  color: rgb(169, 50, 50);
}

@media screen and (max-width: $mobile-max-width) {
  .btn-export {
    width: 100%;
  }
}
</style>
