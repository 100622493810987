<template>
  <div>
    <div class="name-container">
      <label>Nom</label>
      <el-input name="name" v-model="name" @change="() => sendInfoToParent()" />
    </div>
    <div class="address-container">
      <label>Coordonnée(s)</label>
      <draggable
        v-model="coordinates"
        group="contacts"
        handle=".handle"
        item-key="content"
        :sort="true"
        @end="updateItemOrder"
      >
        <template #item="{ element, index }">
          <div class="coordinate-container">
            <contact-coordinate
              class="coordinate"
              :value="element"
              @update:modelValue="(value) => setCoordinate(value, index)"
              @delete="() => deleteCoordinate(index)"
            />
            <i class="fa fa-arrows handle"></i>
          </div>
        </template>
      </draggable>
      <el-button
        class="btn-coordinate"
        type="primary"
        :icon="ElIconPlus"
        @click="() => addNewCoordinate()"
        >Ajouter nouvelle coordonnée
      </el-button>
      <el-button
        class="btn-coordinate"
        type="danger"
        :icon="ElIconDelete"
        @click="() => this.$emit('delete')"
        >Supprimer contact
      </el-button>
    </div>
  </div>
</template>

<script>
import {
  Plus as ElIconPlus,
  Delete as ElIconDelete,
} from '@element-plus/icons-vue';
import {
  ElInput as Input,
  ElButton as Button,
  ElRow as Row,
  ElCol as Col,
} from 'element-plus';
import draggable from 'vuedraggable';
import ContactCoordinate from './ContactCoordinate.vue';

export default {
  data() {
    return {
      name: this.contact.name,
      coordinates: this.contact.coordinates,
      ElIconPlus,
      ElIconDelete,
    };
  },
  components: {
    [Button.name]: Button,
    [Input.name]: Input,
    [Row.name]: Row,
    [Col.name]: Col,
    ContactCoordinate,
    draggable,
  },
  props: ['contact'],
  watch: {
    contact: {
      handler(newValue) {
        if (newValue) {
          this.name = newValue.name;
          this.coordinates = newValue.coordinates;
        }
      },
      deep: true,
    },
  },
  methods: {
    setCoordinate(value, index) {
      this.coordinates[index] = value;
      this.sendInfoToParent();
    },
    addNewCoordinate() {
      this.coordinates.push({
        type: null,
        content: '',
        position: this.coordinates.length,
      });
      this.sendInfoToParent();
    },
    deleteCoordinate(index) {
      this.coordinates.splice(index, 1);
      this.sendInfoToParent();
    },
    sendInfoToParent() {
      const payload = {
        ...this.contact,
        name: this.name,
        coordinates: this.coordinates,
      };
      this.$emit('update:modelValue', payload);
    },
    updateItemOrder() {
      this.coordinates = this.coordinates.map((coordinate, index) => ({
        ...coordinate,
        position: index,
      }));
      this.sendInfoToParent();
    },
  },
};
</script>

<style lang="scss" scoped>
.name-container {
  display: flex;
  align-items: center;
}

.name-container label {
  padding-right: 20px;
}

.address-container {
  margin-top: 20px;
  margin-bottom: 20px;
}

.btn-coordinate {
  margin-top: 20px;
}

.coordinate {
  margin-bottom: 10px;
  width: 97%;
}

.coordinate-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
