<template>
  <div>
    <create-platform-modal
      :show="newPlatformModal"
      :current-operation="currentPlatformOperation"
      :platform="platform"
      @close="closePlatformModal"
    />
    <div class="row">
      <div class="col-md-12">
        <crud-list
          ref="crudList"
          title="Liste des plateformes"
          :fetch-data="fetchPlatforms"
          @create="showNewPlatformModal"
          @row-clicked="rowClicked"
          :defaultSort="{ prop: 'name', order: 'ascending' }"
          @sort-change="sortChange"
        >
          <button-column
            type="edit"
            button-title="Modifier la plateforme"
            :show-on-mobile="true"
            :update-function="updatePlatform"
          />
          <el-table-column
            label="Nom"
            min-width="250"
            prop="name"
            sortable="custom"
          >
            <template #default="slotProps">
              <div class="table-items-list-item">
                {{ slotProps.row.name }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Responsable" min-width="170">
            <template #default="slotProps">
              <div class="table-items-list-item">
                <span v-if="slotProps.row.responsible">
                  {{
                    `${slotProps.row.responsible.firstname} ${slotProps.row.responsible.lastname}`
                  }}
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="Hébergement"
            min-width="180"
            prop="hosting"
            sortable="custom"
          >
            <template #default="slotProps">
              <div class="table-items-list-item">
                {{ slotProps.row.hosting }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="Développement & Maintenance"
            min-width="190"
            prop="developerAndMaintainer"
            sortable="custom"
          >
            <template #default="slotProps">
              <div class="table-items-list-item">
                {{ slotProps.row.developerAndMaintainer }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="Gestion des contenus"
            min-width="250"
            prop="contentManager"
            sortable="custom"
          >
            <template #default="slotProps">
              <div class="table-items-list-item">
                <span
                  v-for="user in slotProps.row.contentManagers"
                  :key="user.id"
                  :class="{ inactif: !user.isActive }"
                  class="user"
                >
                  <router-link
                    :to="{
                      name: 'CollaboratorDetails',
                      params: { id: user.id },
                    }"
                  >
                    <el-tooltip
                      v-if="!user.isActive"
                      content="Inactif"
                      placement="bottom"
                    >
                      <i class="fa fa-user-times"></i>
                    </el-tooltip>
                    {{ user.firstname }} {{ user.lastname }}
                  </router-link>
                  <br />
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="Description"
            min-width="250"
            prop="description"
          >
            <template #default="slotProps">
              <div class="table-items-list-item">
                {{ slotProps.row.description }}
              </div>
            </template>
          </el-table-column>
          <button-column
            type="edit"
            button-title="Modifier la plateforme"
            :show-on-mobile="false"
            :update-function="updatePlatform"
          />
        </crud-list>
      </div>
    </div>
  </div>
</template>

<script>
import { ElTooltip as Tooltip } from 'element-plus';
import CrudList from './UIComponents/CrudList.vue';
import ButtonColumn from './UIComponents/ButtonColumn.vue';
import CreatePlatformModal from './Modals/CreatePlatformModal.vue';

export default {
  components: {
    CrudList,
    ButtonColumn,
    CreatePlatformModal,
    [Tooltip.name]: Tooltip,
  },
  data() {
    return {
      newPlatformModal: false,
      currentPlatformOperation: 'create',
      platform: {},
      sort: '&sort=name,asc',
    };
  },
  methods: {
    fetchPlatforms(pageNumber, pageSize) {
      return this.$http
        .get(
          `${
            process.env.VUE_APP_INTRANET_API_URL
          }/platforms?projection=platformSummary&page=${
            pageNumber - 1
          }&size=${pageSize}${this.sort}`,
        )
        .then((response) => ({
          records: response.data._embedded.platforms
            ? response.data._embedded.platforms
            : [],
          page: response.data.page ? response.data.page : 0,
        }));
    },
    showNewPlatformModal() {
      this.currentPlatformOperation = 'create';
      this.platform = {};
      this.newPlatformModal = true;
    },
    closePlatformModal() {
      this.newPlatformModal = false;
      // Refresh the crud-list component's data.
      this.$refs.crudList.fetch();
    },
    rowClicked(row) {
      let url = row.website;

      if (!url.match(/^https?:\/\//i)) {
        url = `http://${url}`;
      }

      window.open(url, '_blank');
    },
    updatePlatform(row, event) {
      // Prevent the opening of the link since the user clicked on the platform row to update it.
      event.stopPropagation();

      this.currentPlatformOperation = 'edit';
      this.platform = {
        ...row,
        responsibleId: row.responsible.id,
        contentManagersId: row.contentManagers.map(
          (contentManager) => contentManager.id,
        ),
      };
      this.newPlatformModal = true;
    },
    sortChange({ column, prop, order }) {
      const orderString = order === 'ascending' ? 'asc' : 'desc';
      this.sort = `&sort=${prop},${orderString}`;
      this.$refs.crudList.fetch();
    },
  },
};
</script>
