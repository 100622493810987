<template>
  <div>
    <details-modal
      :show="show"
      :current-operation="currentOperation"
      :loading="loading"
      @close="closeModal"
      @cancel="closeModal"
      @create="createDirectory"
      @update="updateDirectory"
      @delete="deleteDirectory"
    >
      <div v-show="loading">
        <Loader />
      </div>
      <div v-show="!loading">
        <Form :validation-schema="validationSchema" validate-on-mount>
          <div class="form-group">
            <label class="control-label">*Chemin</label>
            <Field
              name="path"
              v-model="directory.path"
              v-slot="{ field, errorMessage }"
            >
              <input v-bind="field" class="form-control" type="text" />
              <small v-show="errorMessage" class="text-danger">
                Le chemin est requis.
              </small>
            </Field>
          </div>
          <div>
            <label class="control-label">Accès</label>
            <div v-for="(access, index) in directory.access" :key="index">
              <UserAccess
                :accessRight="access.accessRight"
                :collaborator="access.collaborator"
                :position="index"
                :collaboratorsName="allCollaboratorsName"
                @collaboratorChange="collaboratorChange"
                @accessChange="accessChange"
                @deleteAccess="deleteAccess"
              />
            </div>
            <div>
              <p-button type="light" round @click="addNewAccess">
                Ajouter
              </p-button>
            </div>
          </div>
          <p><label>* champs obligatoires</label></p>
        </Form>
        <p v-if="errorMessage" class="error-message-in-modal">
          {{ errorMessage }}
        </p>
      </div>
    </details-modal>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import { Form, Field } from 'vee-validate';
import * as yup from 'yup';
import MixinFetchData from '@/mixins/mixinFetchData';
import DetailsModal from './DetailsModal.vue';
import UserAccess from '../UIComponents/UserAccess.vue';
import Loader from '../UIComponents/Loader.vue';

export default {
  components: {
    DetailsModal,
    UserAccess,
    Loader,
    Form,
    Field,
  },
  mixins: [MixinFetchData],
  props: ['show', 'currentOperation', 'directoryProps'],
  data() {
    return {
      directory: {
        path: null,
        access: [
          {
            accessRight: '',
            collaborator: null,
          },
        ],
      },
      errorMessage: '',
      validationSchema: {
        path: yup.string().required(),
      },
      loading: false,
    };
  },
  watch: {
    show: {
      handler(newVal) {
        if (newVal) {
          this.fetchData();
        }
      },
    },
    directoryProps: {
      handler(newVal) {
        if (newVal) {
          this.directory = {
            ...newVal,
            access: newVal.access.map((access) => ({ ...access })),
          };
        }
      },
    },
  },
  methods: {
    async createDirectory() {
      // Validate each field with vee-validate, and post the new directory.
      const validationSchemaObject = yup.object(this.validationSchema);
      const isDirectoryValid = await validationSchemaObject.isValid(
        this.directory,
      );

      if (isDirectoryValid) {
        this.errorMessage = null;
        this.loading = false;
        this.$http
          .post(`${process.env.VUE_APP_INTRANET_API_URL}/commands/directory/`, {
            path: this.directory.path,
            access: this.directory.access.map((access) => ({
              collaboratorId: access.collaborator.id,
              accessRight: access.accessRight,
            })),
          })
          .then((response) => {
            this.closeModal();
          })
          .catch((error) => {
            this.errorMessage = 'Une erreur est survenue lors de la création du dossier, veuillez réessayer ou contacter le webmaster.';
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.errorMessage = 'Un ou plusieurs champs sont invalides.';
      }
    },
    async updateDirectory() {
      const validationSchemaObject = yup.object(this.validationSchema);
      const isDirectoryValid = await validationSchemaObject.isValid(
        this.directory,
      );

      if (isDirectoryValid) {
        this.errorMessage = null;

        this.loading = true;

        this.$http
          .patch(
            `${process.env.VUE_APP_INTRANET_API_URL}/commands/directory/${this.directory.id}`,
            {
              path: this.directory.path,
              access: this.directory.access.map((access) => ({
                id: access.id,
                collaboratorId: access.collaborator.id,
                accessRight: access.accessRight,
              })),
            },
          )
          .then(() => {
            this.closeModal();
          })
          .catch((error) => {
            this.errorMessage = 'Une erreur est survenue lors de la modification du dossier, veuillez réessayer ou contacter le webmaster.';
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.errorMessage = 'Un ou plusieurs champs sont invalides.';
      }
    },
    deleteDirectory() {
      this.errorMessage = null;

      Swal.fire({
        title: 'Êtes-vous sûr de vouloir supprimer ce dossier ?',
        text: "Il ne sera pas possible d'annuler cette suppression !",
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill',
        },
        confirmButtonText: 'Oui, effacer',
        cancelButtonText: 'Non, conserver',
        buttonsStyling: false,
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.$http
              .delete(
                `${process.env.VUE_APP_INTRANET_API_URL}/commands/directory/${this.directory.id}`,
              )
              .then(() => {
                this.closeModal();
              })
              .catch((error) => {
                this.errorMessage = 'Une erreur est survenue lors de la suppression du véhicule, veuillez réessayer ou contacter le webmaster.';
              });
          }
        })
        .catch(() => {});
    },
    closeModal() {
      this.$emit('close');
      this.resetData();
      this.errorMessage = '';
    },
    resetData() {
      this.directory = {
        path: null,
        access: [
          {
            accessRight: '',
            collaborator: null,
          },
        ],
      };
    },
    fetchData() {
      this.loading = true;
      // These functions are coming from the "mixinFetchData" mixin.
      this.fetchAllCollaboratorsNames().then(() => {
        this.loading = false;
      });
    },
    accessChange({ value, position }) {
      const { access } = this.directory;
      access[position].accessRight = value;

      this.directory.access = access;
    },
    collaboratorChange({ value, position }) {
      const { access } = this.directory;
      access[position].collaborator = value;

      this.directory.access = access;
    },
    addNewAccess() {
      const { access } = this.directory;
      access.push({
        accessRight: '',
        collaborator: null,
      });

      this.directory.access = access;
    },
    deleteAccess(position) {
      const { access } = this.directory;
      access.splice(position, 1);

      this.directory.access = access;
    },
  },
};
</script>
