<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header text-center">
            <h5 class="title">
              {{ galleryItem.title }}
            </h5>
          </div>
          <div class="card-body text-center">
            <vue-picture-swipe :items="imagesData"></vue-picture-swipe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePictureSwipe from 'vue3-picture-swipe';
import MixinFetchData from '@/mixins/mixinFetchData';

export default {
  components: {
    VuePictureSwipe,
  },
  mixins: [MixinFetchData],
  data() {
    return {
      galleryItem: {},
      imagesData: [],
    };
  },
  created() {
    this.fetchGalleryItem(this.$route.params.id);
  },
  methods: {
    fetchGalleryItem(id) {
      return this.$http
        .get(`${process.env.VUE_APP_INTRANET_API_URL}/gallery_items/${id}`)
        .then((payload) => {
          this.galleryItem = payload.data;

          this.galleryItem.imagesPaths.forEach((imagePath) => {
            this.fetchImageAsBlob('images/gallery/', imagePath)
              .then((blobImage) => {
                if (blobImage) {
                  // Create a temporary URL, which redirects to the blob object containing the image.
                  // The URL lifetime is tied to the document in the window on which it was created.
                  const urlCreatedFromBlob = URL.createObjectURL(blobImage);

                  const tmpImg = document.createElement('img');
                  tmpImg.src = urlCreatedFromBlob;
                  tmpImg.onload = () => {
                    this.imagesData.push({
                      src: urlCreatedFromBlob,
                      thumbnail: urlCreatedFromBlob,
                      w: tmpImg.width,
                      h: tmpImg.height,
                    });
                  };
                }
              })
              .catch((error) => {
                this.$notify({
                  title: 'Error',
                  text: 'Une erreur est survenue lors du chargement des images, veuillez réessayer ou contacter le webmaster.',
                  type: 'danger',
                });
              });
          });
        })
        .catch((error) => {
          this.$notify({
            title: 'Error',
            text: 'Une erreur est survenue lors du chargement des données, veuillez réessayer ou contacter le webmaster.',
            type: 'danger',
          });
        });
    },
  },
};
</script>

<style lang="scss">
$image-margin: 5px;

.my-gallery .gallery-thumbnail img {
  width: 175px;
  height: 175px;
  cursor: pointer;
  margin: $image-margin;
  border-radius: 3px;
  border: 1px solid lightgray;
  object-fit: contain;
}

@media screen and (max-width: $mobile-max-width) {
  .my-gallery .gallery-thumbnail img {
    height: auto;
    margin: $image-margin 0;
    width: 100%;
  }
}
</style>
