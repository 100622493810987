export default {
  data() {
    return {
      slides: [],
      services: [],
      collaboratorsName: [],
      allCollaboratorsName: [],
      functions: [],
    };
  },
  methods: {
    fetchSlides() {
      this.$http
        .get(`${process.env.VUE_APP_INTRANET_API_URL}/slides`)
        .then((response) => {
          this.slides = response.data._embedded.slides
            ? response.data._embedded.slides
            : [];
        });
    },
    fetchServices() {
      return this.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/services/search/findAllOrderByAcronym`,
        )
        .then((payload) => {
          this.services = payload.data._embedded.services;
        })
        .catch((error) => {
          this.$notify({
            title: 'Erreur',
            text: 'Une erreur est survenue lors du chargement des données, veuillez réessayer ou contacter le webmaster',
            type: 'danger',
          });
        });
    },
    fetchFunctions() {
      return this.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/functions/search/findAllOrderByName`,
        )
        .then((payload) => {
          this.functions = payload.data._embedded.functions;
        })
        .catch((error) => {
          this.$notify({
            title: 'Erreur',
            text: 'Une erreur est survenue lors du chargement des données, veuillez réessayer ou contacter le webmaster',
            type: 'danger',
          });
        });
    },
    /**
     * Fetch all the collaborators last and first names except the collaborator
     * whose ID is given. If no ID is given, fetch all the collaborators.
     * @param {*} exceptCollaboratorId the user that won't be fetched
     */
    fetchCollaboratorsNames(exceptCollaboratorId) {
      let url = `${process.env.VUE_APP_INTRANET_API_URL}/collaborators/search/`;

      if (exceptCollaboratorId) {
        url += `findByIdNotOrderByLastAndFirstname?collaboratorId=${exceptCollaboratorId}&`;
      } else {
        url += 'findAllByIsActiveOrderByLastnameAscFirstnameAsc?';
      }

      url += 'projection=collaboratorName&isActive=true';

      return this.$http
        .get(url)
        .then((payload) => {
          this.collaboratorsName = payload.data._embedded.collaborators;
        })
        .catch((error) => {
          this.$notify({
            title: 'Erreur',
            text: 'Une erreur est survenue lors du chargement des données, veuillez réessayer ou contacter le webmaster',
            type: 'danger',
          });
        });
    },
    fetchAllCollaboratorsNames() {
      const url = `${process.env.VUE_APP_INTRANET_API_URL}/collaborators/search/findAllOrderByLastAndFirstname?projection=collaboratorName`;

      return this.$http
        .get(url)
        .then((payload) => {
          this.allCollaboratorsName = payload.data._embedded.collaborators;
        })
        .catch((error) => {
          this.$notify({
            title: 'Erreur',
            text: 'Une erreur est survenue lors du chargement des données, veuillez réessayer ou contacter le webmaster',
            type: 'danger',
          });
        });
    },
    fetchImageAsBlob(imagePath, imageName) {
      if (imageName === null) {
        return this.fetchImageAsBlob(imagePath, 'default.png');
      }
      // Fetch the image as a blob object, then return it. If an error is thrown, fetch the default image.
      return this.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/commands/documents?path=${imagePath}&name=${imageName}`,
          {
            responseType: 'blob',
          },
        )
        .then((response) => response.data)
        .catch((error) => {
          if (imageName !== 'default.png') {
            return this.fetchImageAsBlob(imagePath, 'default.png');
          }
          return null;
        });
    },
    downloadDocument(documentPath, documentName, event) {
      // Prevent the default behaviour of the link in order to not replace the url by '#'.
      event.preventDefault();
      // Stop the propagation of the link event so the user is not redirected on the collaborator
      // page.
      event.stopPropagation();

      return this.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/commands/documents?path=${documentPath}&name=${documentName}`,
          {
            responseType: 'blob',
          },
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', documentName);
          document.body.appendChild(link);
          link.click();
        });
    },
    resetData() {
      this.slides = [];
      this.services = [];
      this.collaboratorsName = [];
    },
  },
};
