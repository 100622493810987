<template>
  <div>
    <create-document-modal
      :show="newDocumentModal"
      :category="category"
      :totalDocumentInCategory="totalDocumentInCategory"
      @close="closeDocumentModal"
    />
    <update-document-modal
      :show="updateDocumentModal"
      :category="category"
      :documentProps="selectedDocument"
      :totalDocumentInCategory="totalDocumentInCategory"
      @close="closeUpdateDocumentModal"
    />
    <p v-if="errorMessage" class="error-message">
      {{ errorMessage }}
    </p>

    <div class="row documents-list-container">
      <div class="col-md-12">
        <el-table-draggable handle=".handle" @drop="onDrop">
          <crud-list
            ref="crudList"
            :fetch-data="fetchDocuments"
            :title="title"
            @create="showNewDocumentModal"
            @row-clicked="rowClicked"
            @sort-change="sortChange"
          >
            <button-column
              type="update"
              button-title="Modifier le document"
              :show-on-mobile="true"
              :update-function="showUpdateDocumentModal"
            />
            <button-column
              type="shared"
              button-title="Partager le document"
              :show-on-mobile="true"
              :update-function="sharedDocument"
            />
            <el-table-column label="Titre" min-width="220" prop="title">
              <template #default="slotProps">
                <div class="table-items-list-item">
                  {{ slotProps.row.title }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              v-if="documentsHasTag"
              label="Tag"
              min-width="155"
              prop="categorie"
            >
              <template #default="slotProps">
                <div class="table-items-list-item">
                  {{ slotProps.row.tag ? slotProps.row.tag.name : '-' }}
                </div>
              </template>
            </el-table-column>
            <button-column
              type="update"
              button-title="Modifier le document"
              :show-on-mobile="false"
              :update-function="showUpdateDocumentModal"
            />
            <button-column
              type="shared"
              button-title="Partager le document"
              :show-on-mobile="false"
              :update-function="sharedDocument"
              :admin="false"
            />
            <el-table-column
              :width="75"
              prop="deplacement"
              @click.stop
              v-if="isAdmin && isOnDesktop"
            >
              <button
                class="btn btn-icon btn-round handle"
                type="button"
                title="changeDocumentPosition"
                @click.prevent.stop
              >
                <i class="fa fa-arrows"></i>
              </button>
            </el-table-column>
          </crud-list>
        </el-table-draggable>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import ROLES from '@/services/Roles';
import CrudList from './CrudList.vue';
import ElTableDraggable from '../../UIComponents/ElTableDraggable.vue';
import ButtonColumn from './ButtonColumn.vue';
import CreateDocumentModal from '../Modals/CreateDocumentModal.vue';
import UpdateDocumentModal from '../Modals/UpdateDocumentModal.vue';

export default {
  components: {
    CrudList,
    ButtonColumn,
    CreateDocumentModal,
    UpdateDocumentModal,
    ElTableDraggable,
  },
  props: ['title', 'category'],
  data() {
    return {
      documentsHasTag: false,
      newDocumentModal: false,
      updateDocumentModal: false,
      selectedDocument: null,
      errorMessage: null,
      documents: [],
      page: null,
      sort: '&sort=position,asc',
      mobileWindowMaxWidth: 991,
      windowInnerWidth: window.innerWidth,
    };
  },
  created() {
    window.addEventListener('resize', this.resize);
  },
  unmounted() {
    window.removeEventListener('resize', this.resize);
  },
  computed: {
    totalDocumentInCategory() {
      return this.page ? this.page.totalElements : 0;
    },
    isAdmin() {
      return (
        this.$store.getters['account/roles'].includes(ROLES.ADMINISTRATOR)
        || this.$store.getters['account/roles'].includes(ROLES.POWER_USER)
      );
    },
    isOnDesktop() {
      return this.windowInnerWidth > this.mobileWindowMaxWidth;
    },
  },
  methods: {
    fetchDocuments(pageNumber, pageSize) {
      return this.$http
        .get(
          `${
            process.env.VUE_APP_INTRANET_API_URL
          }/documents/search/byCategory?categoryName=${
            this.category
          }&projection=documentSummary&page=${pageNumber - 1}&size=${pageSize}${
            this.sort
          }`,
        )
        .then((response) => {
          // Check if at least one document has a tag in order to display or not the "Tag" column in the crud list.
          this.documentsHasTag = response.data._embedded.documents.filter((d) => d.tag).length > 0;
          this.page = response.data.page ? response.data.page : null;
          this.documents = response.data._embedded.documents
            ? response.data._embedded.documents
            : [];

          return {
            records: response.data._embedded.documents
              ? response.data._embedded.documents
              : [],
            page: response.data.page ? response.data.page : 0,
          };
        });
    },
    rowClicked(row) {
      if (row.isExternal) {
        window.open(row.path, '_blank');
        return null;
      }
      return this.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/commands/documents?path=docs/&name=${row.path}`,
          {
            responseType: 'blob',
          },
        )
        .then((response) => {
          // Download the document by first creating a temporary link tag, which redirect to a temporary URL that contains the blob file.
          // Then we simulate a click on it in order to download the file.
          const documentBlob = response.data;
          const urlCreatedFromBlob = window.URL.createObjectURL(documentBlob);
          const tempLinkTag = document.createElement('a');
          tempLinkTag.href = urlCreatedFromBlob;
          tempLinkTag.setAttribute('download', row.path);
          document.body.appendChild(tempLinkTag);
          tempLinkTag.click();
          tempLinkTag.remove();
        });
    },
    deleteDocument(row, event) {
      // Prevent the opening of the link sicne the user clicked on the document row to delete it.
      event.stopPropagation();

      Swal.fire({
        title: 'Êtes-vous sûr de vouloir supprimer ce document ?',
        text: "Il ne sera pas possible d'annuler cette suppression !",
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill',
        },
        confirmButtonText: 'Oui, effacer',
        cancelButtonText: 'Non, conserver',
        buttonsStyling: false,
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.$http
              .delete(
                `${process.env.VUE_APP_INTRANET_API_URL}/commands/documents/${row.id}`,
              )
              .then(() => {
                // Refresh the crud-list component's data.
                this.crudListFetch();
              })
              .catch((error) => {
                this.errorMessage = error.response.data.message;
              });
          }
        })
        .catch(() => {});
    },
    showNewDocumentModal() {
      this.newDocumentModal = true;
    },
    closeDocumentModal() {
      this.newDocumentModal = false;
      // Refresh the crud-list component's data.
      this.crudListFetch();
    },
    showUpdateDocumentModal(row, event) {
      event.stopPropagation();

      this.selectedDocument = {
        ...row,
      };
      this.updateDocumentModal = true;
    },
    closeUpdateDocumentModal() {
      this.updateDocumentModal = false;
      // Refresh the crud-list component's data.
      this.crudListFetch();
    },
    sortChange({ column, prop, order }) {
      const orderString = order === 'ascending' ? 'asc' : 'desc';
      this.sort = `&sort=${prop},${orderString}`;
      this.crudListFetch();
    },
    sharedDocument(row, event) {
      event.stopPropagation();
      navigator.clipboard.writeText(
        `${process.env.VUE_APP_INTRANET_WEBAPP}/documents/${row.id}`,
      );
      this.$notify({
        title: 'Partager',
        text: 'Le lien de partage a été copié avec succès dans votre presse-papier.',
        type: 'success',
      });
    },
    onDrop({ newIndex, oldIndex }) {
      if (oldIndex !== undefined && newIndex !== undefined) {
        const document = this.documents[oldIndex];
        this.updateDocumentPosition(document, newIndex);
      }
    },
    updateDocumentPosition(document, newPosition) {
      const payload = {
        ...document,
        position: newPosition,
        tagId: document.tag ? document.tag.id : null,
      };
      this.$http
        .patch(
          `${process.env.VUE_APP_INTRANET_API_URL}/commands/documents/${document.id}`,
          payload,
        )
        .then(() => this.crudListFetch());
    },
    crudListFetch() {
      this.$refs.crudList.fetch();
    },
    resize() {
      this.windowInnerWidth = window.innerWidth;
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: $mobile-max-width) {
  .documents-list-container {
    margin-left: -30px;
    margin-right: -30px;
  }
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
