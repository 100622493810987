export default {
  data() {
    return {
      imageTooBig: false,
    };
  },
  methods: {
    processImage(event) {
      if (event.target.files[0]) {
        // Image size cannot be greater than 5MB.
        if (event.target.files[0].size > 1024 * 1024 * 5) {
          this.imageTooBig = true;
          // Reset the file input.
          document.getElementById('image').value = '';
        } else {
          this.imageTooBig = false;
          [this.collaborator.newImage] = event.target.files;
        }
      }
    },
  },
};
