import { app } from '../../main';

export default {
  namespaced: true,
  state: {
    collaborators: [],
    filterService: null,
    filterFunction: null,
    search: '',
    sort: {
      prop: 'lastname',
      order: 'ascending',
    },
    isActive: true,
    page: {
      number: 1,
      totalElements: 0,
      size: 30,
      totalPages: 1,
    },
  },
  mutations: {
    setCollaborators(state, collbaorators) {
      state.collaborators = collbaorators;
    },
    setFilterService(state, filterService) {
      state.filterService = filterService;
    },
    setFilterFunction(state, filterFunction) {
      state.filterFunction = filterFunction;
    },
    setSearch(state, search) {
      state.search = search;
    },
    setSort(state, sort) {
      state.sort = sort;
    },
    setPage(state, page) {
      state.page = page;
    },
    setPageSize(state, pageSize) {
      state.page.size = pageSize;
    },
    clearFilterService(state) {
      state.filterService = null;
    },
    clearFilterFunction(state) {
      state.filterFunction = null;
    },
    clearSearch(state) {
      state.search = '';
    },
    toggleIsActive(state) {
      state.isActive = !state.isActive;
    },
  },
  actions: {
    init({ commit, dispatch }) {},
    async getPrevCollaborator({ state, dispatch }, collaboratorId) {
      const nbCollaborators = state.collaborators.length;
      let pageSize = state.page.size;
      const pageNumber = state.page.number;

      if (nbCollaborators === 0) {
        await dispatch('fetchAllCollaboratorsId');
        pageSize = state.collaborators.length;
      }

      const collaboratorIndex = state.collaborators
        .map((collaborator) => collaborator.id)
        .indexOf(collaboratorId);

      // Check if collaborator in current page
      if (collaboratorIndex > 0) {
        return {
          id: state.collaborators[collaboratorIndex - 1].id,
          isOnNewPage: false,
        };
      }

      // check if not on the first page
      if (pageNumber > 1) {
        const { collaborators } = await dispatch(
          'fetchCollaboratorsPageRequest',
          {
            pageNumber: pageNumber - 1,
            pageSize,
          },
        );
        return {
          id: collaborators[pageSize - 1].id,
          isOnNewPage: true,
        };
      }

      // the collaborator is the first of the list
      return {
        id: collaboratorId,
        isOnNewPage: false,
      };
    },
    async getNextCollaborator({ state, dispatch }, collaboratorId) {
      const nbCollaborators = state.collaborators.length;
      let pageSize = state.page.size > nbCollaborators ? nbCollaborators : state.page.size;
      const pageNumber = state.page.number;

      if (nbCollaborators === 0) {
        await dispatch('fetchAllCollaboratorsId');
        pageSize = state.collaborators.length;
      }

      const collaboratorIndex = state.collaborators
        .map((collaborator) => collaborator.id)
        .indexOf(collaboratorId);

      // Check if collaborator in current page
      if (collaboratorIndex < pageSize - 1) {
        return {
          id: state.collaborators[collaboratorIndex + 1].id,
          isOnNewPage: false,
        };
      }

      // check if not on the last page
      if (pageNumber < state.page.totalPages) {
        const { collaborators } = await dispatch(
          'fetchCollaboratorsPageRequest',
          {
            pageNumber: pageNumber + 1,
            pageSize,
          },
        );
        return {
          id: collaborators[0].id,
          isOnNewPage: true,
        };
      }

      // the collaborator is the last of the list
      return {
        id: collaboratorId,
        isOnNewPage: false,
      };
    },
    async fetchCollaboratorsPage(
      { commit, dispatch },
      { pageNumber, pageSize },
    ) {
      const { collaborators, page } = await dispatch(
        'fetchCollaboratorsPageRequest',
        {
          pageNumber,
          pageSize,
        },
      );
      commit('setCollaborators', collaborators);
      commit('setPage', page);
    },
    fetchCollaboratorsPageRequest(
      { state, getters, dispatch },
      { pageNumber, pageSize },
    ) {
      const collaborators = [];
      let page = {};
      let url;

      if (state.search.length <= 1) {
        url = `${process.env.VUE_APP_INTRANET_API_URL}/collaborators/search/searchAllCollaborators?`
          + `projection=collaboratorSummary&isActive=${state.isActive}&page=${
            pageNumber - 1
          }&size=${pageSize}${getters.sortRequest}${
            getters.filterServiceRequest
          }${getters.filterFunctionRequest}`;
      } else {
        url = `${process.env.VUE_APP_INTRANET_API_URL}/collaborators/search/searchCollaborators?`
          + `projection=collaboratorSummary&isActive=${state.isActive}&page=${
            pageNumber - 1
          }&size=${pageSize}${getters.searchRequest}${
            getters.filterServiceRequest
          }${getters.filterFunctionRequest}`;
      }

      return app.config.globalProperties.$http
        .get(url)
        .then(async (collaboratorsResponse) => {
          const fetchedCollaborators = collaboratorsResponse.data._embedded.collaborators;
          if (fetchedCollaborators) {
            const blobImagesPromises = [];

            // For each collaborator fetch its image as a BLOB.
            fetchedCollaborators.forEach((collaborator) => {
              blobImagesPromises.push(
                dispatch(
                  'image/fetchImageAsBlob',
                  {
                    imagePath: 'images/collaborators/',
                    imageName: collaborator.image,
                  },
                  { root: true },
                ),
              );
            });

            await Promise.all(blobImagesPromises).then((blobImages) => {
              // For each fetched BLOB image, assign it to the right collaborator.
              blobImages.forEach((blobImage, index) => {
                if (blobImage) {
                  // Create a temporary URL, which redirects to the blob object containing the image.
                  // The URL lifetime is tied to the document in the window on which it was created.
                  const urlCreatedFromBlob = URL.createObjectURL(blobImage);

                  collaborators.push({
                    ...fetchedCollaborators[index],
                    imageUrl: urlCreatedFromBlob,
                  });
                }
              });
            });
            page = {
              ...collaboratorsResponse.data.page,
              number: collaboratorsResponse.data.page?.number + 1,
            };
          }
          return {
            collaborators,
            page,
          };
        })
        .catch(() => {
          app.config.globalProperties.$notify({
            title: 'Error',
            message:
              'Une erreur est survenue lors de la récupération des données, veuillez réessayer ou contacter le support.',
            type: 'danger',
          });
        });
    },
    fetchAllCollaboratorsId({ commit }) {
      return app.config.globalProperties.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/collaborators/search/findAllByIsActiveOrderByLastnameAscFirstnameAsc?isActive=true&projection=collaboratorId&sort=lastname,asc&sort=firstname,asc`,
        )
        .then((response) => {
          const collaborators = response.data._embedded.collaborators
            ? response.data._embedded.collaborators
            : [];
          commit('setCollaborators', collaborators);
          commit('setPageSize', collaborators.length);
        })
        .catch(() => {
          app.config.globalProperties.$notify({
            title: 'Error',
            message:
              'Une erreur est survenue lors de la récupération des données, veuillez réessayer ou contacter le support.',
            type: 'danger',
          });
        });
    },
  },
  getters: {
    page: (state) => state.page,
    collaborators: (state) => state.collaborators,
    search: (state) => state.search,
    filterFunction: (state) => (state.filterFunction ? [state.filterFunction] : []),
    filterService: (state) => (state.filterService ? [state.filterService] : []),
    sort: (state) => state.sort,
    isActive: (state) => state.isActive,
    sortRequest: (state) => (state.sort.prop
      ? `&sort=${state.sort.prop},${
        state.sort.order === 'ascending' ? 'asc' : 'desc'
      }`
      : ''),
    searchRequest: (state) => (state.search && state.search !== '' ? `&search=${state.search}` : ''),
    filterFunctionRequest: (state) => (state.filterFunction ? `&filterFunction=${state.filterFunction}` : ''),
    filterServiceRequest: (state) => (state.filterService ? `&filterService=${state.filterService}` : ''),
  },
};
