<template>
  <div>
    <create-collaborator-modal
      :show="newCollaboratorModal"
      @close="closeCollaboratorModal"
    />
    <choose-function-collaborator-modal
      :show="chooseFunctionModal"
      @close="closeChooseFunctionModal"
      @choose="exportCollaboratorsByFunctionToXlsx"
    />
    <create-collaborators-file-modal
      :show="openModalNewCollaboratorsFile"
      @close="() => (openModalNewCollaboratorsFile = false)"
    />

    <div class="row buttons-container">
      <drop-down>
        <template #title="slotProps">
          <p-button
            round
            block
            :aria-expanded="slotProps['is-open']"
            class="dropdown-toggle dropdown-export"
          >
            Exporter en format excel
          </p-button>
        </template>
        <div class="dropdown-header">Type d'export</div>
        <span class="dropdown-item" @click="exportCollaboratorsToXlsx"
          >Liste des collaborateurs</span
        >
        <span class="dropdown-item" @click="showChooseFunctionModal"
          >Liste des collaborateurs par fonction</span
        >
        <span class="dropdown-item" @click="exportPhoneToXlsx"
          >Liste des numéros de téléphone</span
        >
        <span class="dropdown-item" @click="exportCollaboratorsScheduleToXlsx"
          >Liste des horaires des collaborateurs</span
        >
      </drop-down>

      <div class="mx-3 row pdf-secretariat-container">
        <button
          class="btn btn-round mr-1 btn-pdf-secretariat"
          type="button"
          @click="downloadCollaboratorsFile"
        >
          Collaborateurs-trices au Secrétariat général
        </button>
        <button
          v-if="isAdmin"
          class="btn btn-icon btn-round"
          type="button"
          title="addCollaboratorsFile"
          @click="() => (openModalNewCollaboratorsFile = true)"
        >
          <i class="fa fa-pencil-square-o" />
        </button>
      </div>
      <div class="text-info-container my-3">
        <div>
          <span class="collaborators-schedule"
            >Pour les horaires, se référer aux calendriers Outlook
            respectifs</span
          >
        </div>
        <div class="ml-3 mt-2">
          <el-switch
            :model-value="!isActive"
            active-color="#13ce66"
            @change="showInactifChange"
          >
          </el-switch>
          <span class="ml-1">Voir inactifs</span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <crud-list
          ref="crudList"
          :fetch-data="fetchCollaborators"
          title="Collaborateurs"
          @create="showNewCollaboratorModal"
          @row-clicked="rowClicked"
          :defaultSort="sort"
          @sort-change="sortChange"
          :showSearch="true"
          @search="searchUser"
          :searchValue="search"
          @filter-change="filterChange"
          :pageNumber="page.number"
          :page-size="pageSize"
        >
          <el-table-column label="" min-width="130">
            <template #default="slotProps">
              <div class="table-items-list-item collaborator-image-container">
                <img
                  :src="slotProps.row.imageUrl"
                  :alt="slotProps.row.initials"
                />
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="Nom"
            min-width="130"
            prop="lastname"
            sortable="custom"
          >
            <template #default="slotProps">
              <div class="table-items-list-item">
                {{ `${slotProps.row.firstname} ${slotProps.row.lastname}` }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="Initiales"
            min-width="130"
            prop="initials"
            sortable="custom"
          >
            <template #default="slotProps">
              <div class="table-items-list-item">
                {{ slotProps.row.initials }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="E-mail"
            min-width="180"
            prop="email"
            sortable="custom"
          >
            <template #default="slotProps">
              <div class="table-items-list-item">
                {{ slotProps.row.email ? slotProps.row.email : '-' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="Téléphone"
            min-width="150"
            prop="internalphone"
            sortable="custom"
          >
            <template #default="slotProps">
              <div class="table-items-list-item">
                <div
                  v-if="
                    slotProps.row.internalphone || slotProps.row.privatephone
                  "
                >
                  {{ slotProps.row.internalphone }}<br />
                  {{ slotProps.row.privatephone }}
                </div>
                <div
                  v-if="
                    !slotProps.row.internalphone && !slotProps.row.privatephone
                  "
                >
                  -
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="Service(s)"
            min-width="140"
            column-key="services"
            :filters="servicesPossibleToFilters"
            :filter-multiple="false"
            :filtered-value="filterService"
            filter-placement="bottom"
            ref="serviceColumn"
          >
            <template #default="slotProps">
              <div class="table-items-list-item">
                <div
                  v-for="service in slotProps.row.services"
                  :key="service.acronym"
                >
                  {{ service.acronym }}
                </div>
                <div v-if="slotProps.row.services.length === 0">-</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="Fonction"
            min-width="180"
            column-key="functions"
            :filters="functionsPossibleToFilters"
            :filter-multiple="false"
            filter-placement="bottom"
            ref="functionsColumn"
            :filtered-value="filterFunction"
          >
            <template #default="slotProps">
              <div class="table-items-list-item">
                {{ slotProps.row.function ? slotProps.row.function.name : '-' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="" min-width="110">
            <template #default="slotProps">
              <div
                v-if="slotProps.row.specifications"
                class="table-items-list-item"
              >
                <a
                  href="#"
                  @click="
                    specificationsClick(slotProps.row.specifications, $event)
                  "
                  >Cahier des charges</a
                >
              </div>
            </template>
          </el-table-column>
        </crud-list>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ElSwitch } from 'element-plus';
import XLSX from 'xlsx';
import MixinFetchData from '@/mixins/mixinFetchData';
import ROLES from '@/services/Roles';
import CrudList from './UIComponents/CrudList.vue';
import CreateCollaboratorModal from './Modals/CreateCollaboratorModal.vue';
import CreateCollaboratorsFileModal from './Modals/CreateCollaboratorsFileModal.vue';
import ChooseFunctionCollaboratorModal from './Modals/ChooseFunctionCollaboratorModal.vue';
import DropDown from '../UIComponents/Dropdown.vue';

export default {
  components: {
    CrudList,
    CreateCollaboratorModal,
    CreateCollaboratorsFileModal,
    ChooseFunctionCollaboratorModal,
    DropDown,
    [ElSwitch.name]: ElSwitch,
  },
  mixins: [MixinFetchData],
  data() {
    return {
      pageSize: 10,
      newCollaboratorModal: false,
      chooseFunctionModal: false,
      imagesPath: `${process.env.VUE_APP_INTRANET_API_URL}/images/collaborators`,
      specificationsPath: `${process.env.VUE_APP_INTRANET_API_URL}/specifications`,
      servicesPossibleToFilters: [],
      functionsPossibleToFilters: [],
      openModalNewCollaboratorsFile: false,
    };
  },
  mounted() {
    this.fetchServicesFilters();
    this.fetchFunctionsFilters();
  },
  computed: {
    ...mapGetters('collaborators', [
      'page',
      'collaborators',
      'filterService',
      'filterFunction',
      'isActive',
      'sort',
      'search',
    ]),
    isAdmin() {
      return (
        this.$store.getters['account/roles'].includes(ROLES.ADMINISTRATOR)
        || this.$store.getters['account/roles'].includes(ROLES.POWER_USER)
      );
    },
  },
  methods: {
    rowClicked(row) {
      this.$router.push({
        name: 'CollaboratorDetails',
        params: { id: row.id },
      });
    },
    /* FETCHS */
    async fetchCollaborators(pageNumber, pageSize) {
      return this.$store
        .dispatch('collaborators/fetchCollaboratorsPage', {
          pageNumber,
          pageSize,
        })
        .then((payload) => ({
          records: this.collaborators,
          page: this.page,
        }));
    },
    async fetchServicesFilters() {
      await this.fetchServices();
      this.servicesPossibleToFilters = this.services.map((service) => ({
        text: service.acronym,
        value: service.acronym,
      }));
    },
    async fetchFunctionsFilters() {
      await this.fetchFunctions();
      this.functionsPossibleToFilters = this.functions.map((functionWork) => ({
        text: functionWork.name,
        value: functionWork.name,
      }));
    },
    fetchAllCollaborators() {
      return this.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/collaborators/search/findAllByIsActiveOrderByLastnameAscFirstnameAsc?isActive=true&projection=collaboratorDetails&sort=lastname,asc&sort=firstname,asc`,
        )
        .then((response) => ({
          collaborators: response.data._embedded.collaborators
            ? response.data._embedded.collaborators
            : [],
        }))
        .catch(() => {
          this.$notify({
            title: 'Error',
            text: 'Une erreur est survenue lors de la récupération des données, veuillez réessayer ou contacter le webmaster.',
            type: 'danger',
          });
        });
    },
    fetchAllPhonesCollaborators() {
      return this.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/collaborators/search/findAllByIsActiveOrderByLastnameAscFirstnameAsc?isActive=true&projection=collaboratorPhone&sort=lastname,asc&sort=firstname,asc`,
        )
        .then((response) => ({
          collaborators: response.data._embedded.collaborators
            ? response.data._embedded.collaborators
            : [],
        }))
        .catch(() => {
          this.$notify({
            title: 'Error',
            text: 'Une erreur est survenue lors de la récupération des données, veuillez réessayer ou contacter le webmaster.',
            type: 'danger',
          });
        });
    },
    fetchAllCollaboratorsByFunction(functionName) {
      return this.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/collaborators/search/findAllByFunctionId?function=${functionName}&projection=collaboratorDetails`,
        )
        .then((response) => ({
          collaborators: response.data._embedded.collaborators
            ? response.data._embedded.collaborators
            : [],
        }))
        .catch(() => {
          this.$notify({
            title: 'Error',
            text: 'Une erreur est survenue lors de la récupération des données, veuillez réessayer ou contacter le webmaster.',
            type: 'danger',
          });
        });
    },
    fetchAllCollaboratorsSchedule() {
      return this.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/collaborators/search/findByScheduleNotNull?projection=collaboratorSchedule`,
        )
        .then((response) => ({
          collaborators: response.data._embedded.collaborators
            ? response.data._embedded.collaborators
            : [],
        }))
        .catch(() => {
          this.$notify({
            title: 'Error',
            text: 'Une erreur est survenue lors de la récupération des données, veuillez réessayer ou contacter le webmaster.',
            type: 'danger',
          });
        });
    },
    /* MODAL */
    showNewCollaboratorModal() {
      this.newCollaboratorModal = true;
    },
    closeCollaboratorModal() {
      this.newCollaboratorModal = false;
    },
    showChooseFunctionModal() {
      this.chooseFunctionModal = true;
    },
    closeChooseFunctionModal() {
      this.chooseFunctionModal = false;
    },
    specificationsClick(specifications, event) {
      this.downloadDocument('specifications/', specifications, event);
    },
    /* Export to XLSX */
    exportCollaboratorsToXlsx() {
      const wsData = [
        [
          'Initiales',
          'Nom',
          'Prénom',
          'Service',
          'E-mail',
          'Téléphone Interne',
          'Téléphone Privé',
          'Fonction',
          'Domaine',
        ],
      ];

      this.fetchAllCollaborators().then((res) => {
        res.collaborators.forEach((c) => {
          wsData.push([
            c.initials,
            c.lastname,
            c.firstname,
            c.services.map((s) => s.acronym).join(', '),
            c.email,
            c.internalphone,
            c.privatephone,
            c.function ? c.function.name : '',
            c.activities,
          ]);
        });

        const ws = XLSX.utils.aoa_to_sheet(wsData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'ListeCollaborateurs');
        XLSX.writeFile(wb, 'liste_collaborateurs.xlsx');
        this.$notify({
          title: 'Téléchargement',
          text: 'Téléchargement du fichier contenant les collaborateurs',
          type: 'success',
        });
      });
    },
    exportPhoneToXlsx() {
      const wsData = [
        ['Nom', 'Prénom', 'Téléphone Interne', 'Téléphone Privé'],
      ];

      this.fetchAllPhonesCollaborators().then((res) => {
        res.collaborators.forEach((c) => {
          wsData.push([
            c.lastname,
            c.firstname,
            c.internalphone,
            c.privatephone,
          ]);
        });

        const ws = XLSX.utils.aoa_to_sheet(wsData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'ListeTelephonesCollaborateurs');
        XLSX.writeFile(wb, 'liste_telephone_collaborateurs.xlsx');
        this.$notify({
          title: 'Téléchargement',
          text: 'Téléchargement du fichier contenant les téléphones des collaborateurs',
          type: 'success',
        });
      });
    },
    exportCollaboratorsByFunctionToXlsx(functionName) {
      const wsData = [
        [
          'Initiales',
          'Nom',
          'Prénom',
          'Service',
          'E-mail',
          'Téléphone Interne',
          'Téléphone Privé',
          'Fonction',
          'Domaine',
        ],
      ];

      this.fetchAllCollaboratorsByFunction(functionName).then((res) => {
        res.collaborators.forEach((c) => {
          wsData.push([
            c.initials,
            c.lastname,
            c.firstname,
            c.services.map((s) => s.acronym).join(', '),
            c.email,
            c.internalphone,
            c.privatephone,
            c.function.name,
            c.activities,
          ]);
        });

        const ws = XLSX.utils.aoa_to_sheet(wsData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'ListeCollaborateurs');
        XLSX.writeFile(wb, 'liste_collaborateurs.xlsx');
        this.$notify({
          title: 'Téléchargement',
          text: 'Téléchargement du fichier contenant les collaborateurs ayant la fonction choisie',
          type: 'success',
        });
      });
    },
    exportCollaboratorsScheduleToXlsx() {
      const wsData = [
        [
          'Nom',
          'Prénom',
          'Téléphone interne',
          'Lundi matin',
          'Lundi après-midi',
          'Mardi matin',
          'Mardi après-midi',
          'Mercredi matin',
          'Mercredi après-midi',
          'Jeudi matin',
          'Jeudi après-midi',
          'Vendredi matin',
          'Vendredi après-midi',
        ],
      ];

      this.fetchAllCollaboratorsSchedule().then((res) => {
        const merge = []; // Array of merge cells
        let nbRow = 0;

        res.collaborators.forEach((c) => {
          wsData.push([
            c.lastname,
            c.firstname,
            c.internalphone,
            this.hoursToString(
              c.schedule.mondayMorningStart,
              c.schedule.mondayMorningEnd,
            ),
            this.hoursToString(
              c.schedule.mondayAfternoonStart,
              c.schedule.mondayAfternoonEnd,
            ),
            this.hoursToString(
              c.schedule.tuesdayMorningStart,
              c.schedule.tuesdayMorningEnd,
            ),
            this.hoursToString(
              c.schedule.tuesdayAfternoonStart,
              c.schedule.tuesdayAfternoonEnd,
            ),
            this.hoursToString(
              c.schedule.wednesdayMorningStart,
              c.schedule.wednesdayMorningEnd,
            ),
            this.hoursToString(
              c.schedule.wednesdayAfternoonStart,
              c.schedule.wednesdayAfternoonEnd,
            ),
            this.hoursToString(
              c.schedule.thursdayMorningStart,
              c.schedule.thursdayMorningEnd,
            ),
            this.hoursToString(
              c.schedule.thursdayAfternoonStart,
              c.schedule.thursdayAfternoonEnd,
            ),
            this.hoursToString(
              c.schedule.fridayMorningStart,
              c.schedule.fridayMorningEnd,
            ),
            this.hoursToString(
              c.schedule.fridayAfternoonStart,
              c.schedule.fridayAfternoonEnd,
            ),
          ]);
          nbRow++;

          if (
            c.schedule.mondayMorningComment
            || c.schedule.mondayAfternoonComment
            || c.schedule.tuesdayMorningComment
            || c.schedule.tuesdayAfternoonComment
            || c.schedule.wednesdayMorningComment
            || c.schedule.wednesdayAfternoonSComment
            || c.schedule.thursdayMorningComment
            || c.schedule.thursdayAfternoonComment
            || c.schedule.fridayMorningComment
            || c.schedule.fridayAfternoonComment
          ) {
            wsData.push([
              '',
              '',
              '',
              c.schedule.mondayMorningComment,
              c.schedule.mondayAfternoonComment,
              c.schedule.tuesdayMorningComment,
              c.schedule.tuesdayAfternoonComment,
              c.schedule.wednesdayMorningComment,
              c.schedule.wednesdayAfternoonComment,
              c.schedule.thursdayMorningComment,
              c.schedule.thursdayAfternoonComment,
              c.schedule.fridayMorningComment,
              c.schedule.fridayAfternoonComment,
            ]);
            merge.push({
              s: {
                r: nbRow,
                c: 0,
              },
              e: {
                r: nbRow + 1,
                c: 0,
              },
            });
            merge.push({
              s: {
                r: nbRow,
                c: 1,
              },
              e: {
                r: nbRow + 1,
                c: 1,
              },
            });
            merge.push({
              s: {
                r: nbRow,
                c: 2,
              },
              e: {
                r: nbRow + 1,
                c: 2,
              },
            });
            nbRow++;
          }
        });

        const ws = XLSX.utils.aoa_to_sheet(wsData);
        ws['!merges'] = merge;
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Horaires');
        XLSX.writeFile(wb, 'liste_horaires.xlsx');
        this.$notify({
          title: 'Téléchargement',
          text: 'Téléchargement du fichier contenant les collaborateurs ayant la fonction choisie',
          type: 'success',
        });
      });
    },
    hoursToString(start, end) {
      if (start == null || end == null) {
        return 'Congé';
      }
      return start.concat(' à ').concat(end);
    },
    sortChange({ column, prop, order }) {
      this.$store.commit('collaborators/setSort', {
        prop,
        order,
      });
      this.crudListFetch();
    },
    crudListFetch() {
      this.$refs.crudList?.fetch();
    },
    filterChange(filters) {
      if (filters.services) {
        if (filters.services.length > 0) {
          const servFilter = filters.services.length > 0 ? filters.services[0] : null;
          this.$store.commit('collaborators/setFilterService', servFilter);
        } else {
          this.$store.commit('collaborators/clearFilterService');
        }
      }
      if (filters.functions) {
        if (filters.functions.length > 0) {
          const servFilter = filters.functions.length > 0 ? filters.functions[0] : null;
          this.$store.commit('collaborators/setFilterFunction', servFilter);
        } else {
          this.$store.commit('collaborators/clearFilterFunction');
        }
      }
      this.crudListFetch();
    },
    searchUser(search) {
      if (search && search !== '' && search.length >= 2) {
        this.$store.commit('collaborators/setSearch', search);
      } else {
        this.$store.commit('collaborators/clearSearch');
      }
      this.crudListFetch();
    },
    downloadCollaboratorsFile() {
      return this.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/commands/collaboratorsFile`,
          {
            responseType: 'blob',
          },
        )
        .then((response) => {
          // Download the document by first creating a temporary link tag, which redirect to a temporary URL that contains the blob file.
          // Then we simulate a click on it in order to download the file.
          const documentBlob = response.data;
          const urlCreatedFromBlob = window.URL.createObjectURL(documentBlob);
          const tempLinkTag = document.createElement('a');
          tempLinkTag.href = urlCreatedFromBlob;
          tempLinkTag.setAttribute(
            'download',
            'collaborateurs-trices_SG-CIIP.pdf',
          );
          document.body.appendChild(tempLinkTag);
          tempLinkTag.click();
          tempLinkTag.remove();
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            this.$notify({
              title: 'Pas de fichier enregistré',
              text: 'Aucun fichier ne fut ajouter par les administrateurs',
              type: 'warning',
            });
          } else {
            this.$notify({
              title: 'Erreur',
              text: 'Une erreur est survenu sur le serveur. Veuillez contacter le support.',
              type: 'danger',
            });
          }
        });
    },
    showInactifChange() {
      this.$store.commit('collaborators/toggleIsActive');
      this.crudListFetch();
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons-container {
  align-items: center;
}

.dropdown-export {
  width: auto;
  margin-left: $mobile-button-margin-x;
}

.text-info-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.collaborator-image-container {
  width: 100px;
  height: 100px;

  img {
    margin-left: 50%;
    margin-top: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
}

.activities-column-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.collaborators-schedule {
  margin-left: 1em;
  font-style: italic;
}

@media screen and (max-width: $mobile-max-width) {
  .collaborators-schedule {
    margin: 0 1em;
    text-align: center;
  }

  .text-info-container {
    margin-bottom: 0.5em;
  }

  .dropdown {
    width: 100%;

    .dropdown-export {
      width: calc(100% - 2 * #{$mobile-button-margin-x});
    }
  }
  .btn-pdf-secretariat {
    width: calc(100% - 2 * #{$mobile-button-margin-x} - 48px);
  }
  .pdf-secretariat-container {
    width: calc(100% - 2 * #{$mobile-button-margin-x});
  }
}
</style>

<style lang="scss">
@media screen and (max-width: $mobile-max-width) {
  .dropdown-menu {
    left: 35px;
  }
}
</style>
