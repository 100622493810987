<template>
  <div>
    <div v-if="loading">
      <Loader />
    </div>
    <div v-else>
      <div class="row">
        <div class="top-buttons action-container col-md-12">
          <button
            v-if="isAdmin"
            type="submit"
            :class="buttonClassObject"
            class="btn btn-fill btn-wd"
            @click.prevent="editButtonClicked"
          >
            <a v-if="editingDisabled">Editer</a>
            <a v-else>Enregister</a>
          </button>
          <button
            v-show="!editingDisabled"
            type="warning"
            class="btn btn-warning btn-fill btn-wd"
            @click.prevent="cancelButtonClicked"
          >
            Annuler
          </button>
          <button
            v-show="!editingDisabled"
            type="danger"
            class="btn btn-danger btn-fill btn-wd"
            @click.prevent="deleteButtonClicked"
          >
            Effacer
          </button>
          <div class="navigation-container">
            <button
              class="btn btn-icon btn-round mr-1"
              type="button"
              title="prevCollaborator"
              :disabled="!hasPrevCollaborator"
              @click.prevent="goPrevCollaborator"
            >
              <i class="fa fa-chevron-left" />
            </button>
            <button
              class="btn btn-icon btn-round ml-1"
              type="button"
              :disabled="!hasNextCollaborator"
              title="nextCollaborator"
              @click.prevent="goNextCollaborator"
            >
              <i class="fa fa-chevron-right" />
            </button>
          </div>
          <p class="error-message" v-if="errorMessage">
            {{ errorMessage }}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 action-container mt-2 mb-3">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item to="/collaborators/"
              >Collaborateurs
            </el-breadcrumb-item>
            <el-breadcrumb-item>
              {{ collaborator.firstname }} {{ collaborator.lastname }}
            </el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5 class="title">Données du collaborateur</h5>
            </div>
            <div class="card-body">
              <form>
                <div class="row">
                  <div class="col-md-6">
                    <div>
                      <img
                        id="collaboratorImage"
                        class="collaborator-image"
                        :alt="collaborator.initials"
                        :src="collaboratorImgSrc"
                      />
                    </div>
                    <div v-if="!editingDisabled">
                      <label class="control-label">Image</label><br />
                      <input
                        id="image"
                        type="file"
                        name="image"
                        accept="image/png, image/jpeg"
                        @change="processImage($event)"
                      /><br />
                      <small v-show="imageTooBig" class="text-danger">
                        La taille de l'image ne peut pas dépasser 5MB, veuillez
                        réessayer.
                      </small>
                    </div>
                    <div v-if="collaborator.specifications">
                      <a href="#" @click="specificationsClick"
                        >Extrait de cahier des charges</a
                      >
                    </div>
                    <div v-if="!editingDisabled">
                      <label class="control-label">Cahier des charges</label
                      ><br />
                      <input
                        id="specifications"
                        type="file"
                        name="specifications"
                        accept="application/pdf"
                        @change="setSpecifications($event)"
                      />
                    </div>
                    <div>
                      <fg-input
                        :model-value="collaborator.lastname"
                        :disabled="editingDisabled"
                        type="text"
                        label="Nom"
                        @update:modelValue="lastnameInputChange($event)"
                      />
                    </div>
                    <div>
                      <fg-input
                        v-model="collaborator.firstname"
                        :disabled="editingDisabled"
                        type="text"
                        label="Prénom"
                      />
                    </div>
                    <div>
                      <fg-input
                        v-model="collaborator.initials"
                        :disabled="editingDisabled"
                        type="text"
                        label="Initiales"
                      />
                    </div>
                    <div>
                      <fg-input
                        v-model="collaborator.email"
                        :disabled="editingDisabled"
                        type="text"
                        label="E-mail"
                      />
                    </div>
                    <div>
                      <fg-input
                        v-model="collaborator.internalphone"
                        :disabled="editingDisabled"
                        type="text"
                        label="Téléphone interne"
                      />
                    </div>
                    <div>
                      <fg-input
                        v-model="collaborator.privatephone"
                        :disabled="editingDisabled"
                        type="text"
                        label="Téléphone privé"
                      />
                    </div>
                    <div>
                      <label for="birthdayDate">Date de naissance</label>
                      <el-date-picker
                        id="birthdayDate"
                        v-model="collaborator.birthdayDate"
                        :disabled="editingDisabled"
                        class="birthday-input"
                        type="date"
                        placeholder="Choisissez un jour"
                      />
                    </div>
                    <div>
                      <label for="activities">Domaine(s) d'activité(s)</label>
                      <textarea
                        id="activities"
                        v-model="collaborator.activities"
                        :disabled="editingDisabled"
                        class="form-control"
                        rows="3"
                      />
                    </div>
                    <div>
                      <fg-input
                        v-model="collaborator.rate"
                        :disabled="editingDisabled"
                        type="number"
                        label="Taux (%)"
                      />
                    </div>
                    <div>
                      <p-checkbox
                        v-model="collaborator.isActive"
                        inline
                        :disabled="editingDisabled"
                      >
                        Actif
                      </p-checkbox>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div>
                      <label for="select-upper-hierarchy"
                        >Supérieur(s) hiérarchique(s)</label
                      >
                      <el-select
                        id="select-upper-hierarchy"
                        v-model="collaborator.upperHierarchyIds"
                        :disabled="editingDisabled"
                        filterable
                        clearable
                        class="select-info col-md-12 select-one-line-per-item"
                        size="large"
                        placeholder="Sélectionner"
                        :multiple="true"
                      >
                        <el-option
                          v-for="c in collaboratorsName"
                          :key="c.id"
                          :label="`${c.lastname} ${c.firstname}`"
                          :value="c.id"
                        />
                      </el-select>
                    </div>
                    <div>
                      <label for="select-function">Fonction</label>
                      <el-select
                        id="select-function"
                        v-model="collaborator.functionId"
                        :disabled="editingDisabled"
                        filterable
                        clearable
                        class="select-info col-md-12 select-one-line-per-item"
                        size="large"
                        placeholder="Sélectionner"
                      >
                        <el-option
                          v-for="f in functions"
                          :key="f.id"
                          :label="f.name"
                          :value="f.id"
                        />
                      </el-select>
                    </div>
                    <div>
                      <label for="select-services">Service(s)</label>
                      <el-select
                        id="select-services"
                        v-model="collaborator.servicesIds"
                        :disabled="editingDisabled"
                        filterable
                        class="select-info col-md-12 select-one-line-per-item"
                        size="large"
                        placeholder="Sélectionner"
                        :multiple="true"
                      >
                        <el-option
                          v-for="service in services"
                          :key="service.id"
                          :label="service.acronym"
                          :value="service.id"
                        />
                      </el-select>
                    </div>
                    <div>
                      <label for="select-materials">Matériel(-aux)</label>
                      <el-select
                        id="select-materials"
                        v-model="collaborator.materialsIds"
                        :disabled="editingDisabled"
                        filterable
                        class="select-info col-md-12 select-one-line-per-item"
                        size="large"
                        placeholder="Sélectionner"
                        :multiple="true"
                      >
                        <el-option-group
                          v-for="group in materials"
                          :key="group.label"
                          :label="group.label"
                        >
                          <el-option
                            v-for="material in group.options"
                            :key="material.id"
                            :label="material.identifier"
                            :value="material.id"
                          />
                        </el-option-group>
                      </el-select>
                    </div>
                    <div>
                      <label for="select-applications">Application(s)</label>
                      <el-select
                        v-model="collaborator.applicationsIds"
                        :disabled="editingDisabled"
                        filterable
                        class="select-info col-md-12 select-one-line-per-item"
                        size="large"
                        placeholder="Sélectionner"
                        :multiple="true"
                      >
                        <el-option
                          v-for="application in applications"
                          :key="application.id"
                          :label="application.name"
                          :value="application.id"
                        />
                      </el-select>
                    </div>
                    <div>
                      <label for="select-letterbox">Boîte(s) aux lettres</label>
                      <div v-if="collaborator.letterboxAccess.length === 0">
                        -
                      </div>
                      <div
                        v-else
                        v-for="(access, index) in collaborator.letterboxAccess"
                        :key="index"
                      >
                        <LetterboxAccess
                          :accessRight="access.accessRight"
                          :letterbox="access.letterbox"
                          :allLetterbox="allLetterbox"
                          :position="index"
                          :disabled="editingDisabled"
                          @letterboxChange="letterboxChange"
                          @accessChange="accessLetterboxChange"
                          @deleteAccess="deleteLetterboxAccess"
                        />
                      </div>
                      <div>
                        <p-button
                          v-if="!editingDisabled"
                          type="light"
                          round
                          @click="addLetterboxAccess"
                        >
                          Ajouter
                        </p-button>
                      </div>
                    </div>
                    <div>
                      <label for="select-directory"
                        >Liste des accès au(x) dossier(s)</label
                      >
                      <div v-if="collaborator.directoriesAccess.length === 0">
                        -
                      </div>
                      <div
                        v-else
                        v-for="(
                          access, index
                        ) in collaborator.directoriesAccess"
                        :key="index"
                      >
                        <DirectoryAccess
                          :accessRight="access.accessRight"
                          :directory="access.directory"
                          :directories="directories"
                          :position="index"
                          :disabled="editingDisabled"
                          @directoryChange="directoryChange"
                          @accessChange="accessDirectoryChange"
                          @deleteAccess="deleteDirectoryAccess"
                        />
                      </div>
                      <div>
                        <p-button
                          v-if="!editingDisabled"
                          type="light"
                          round
                          @click="addDirectoryAccess"
                        >
                          Ajouter
                        </p-button>
                      </div>
                    </div>
                    <div>
                      <label for="select-distribution-list"
                        >Liste(s) de distribution</label
                      >
                      <div v-if="collaborator.distributionLists.length === 0">
                        -
                      </div>
                      <div
                        v-for="(
                          distributionList, index
                        ) in collaborator.distributionLists"
                        :key="index"
                      >
                        <DistributionListSelect
                          :distributionList="distributionList"
                          :position="index"
                          :distributionLists="distributionLists"
                          :disabled="editingDisabled"
                          @distributionListChange="distributionListChange"
                          @deleteDistributionList="deleteDistributionList"
                        />
                      </div>
                      <div>
                        <p-button
                          v-if="!editingDisabled"
                          type="light"
                          round
                          @click="addDistributionList"
                        >
                          Ajouter
                        </p-button>
                      </div>
                    </div>
                    <div>
                      <crud-select-car
                        :disabled="editingDisabled"
                        v-model="collaborator.carsIds"
                        label="Voiture(s)"
                        :multiple="true"
                        :set-selected-record-id="setSelectedCarId"
                        :fetch-records="fetchCars"
                        :create-record="createCar"
                      />
                    </div>
                    <div>
                      <label>Horaire</label>
                      <schedule-manager
                        :editingDisabled="editingDisabled"
                        :scheduleProps="collaborator.schedule"
                        :isNotRegisterProps="scheduleIsNotRegister"
                        @scheduleChange="scheduleChange"
                        @addNewSchedule="addNewSchedule"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  ElDatePicker,
  ElOptionGroup,
  ElBreadcrumb,
  ElBreadcrumbItem,
} from 'element-plus';
import Swal from 'sweetalert2';
import FormData from 'form-data';
import ScheduleService from '@/services/ScheduleService';
import MixinFetchData from '@/mixins/mixinFetchData';
import MixinProcessImage from '@/mixins/mixinProcessImage';
import MATERIALS_TYPES_ARRAY from '@/enums/MaterialsTypes';
import ROLES from '@/services/Roles';
import ScheduleManager from './UIComponents/SchedulesManager.vue';
import CrudSelectCar from './UIComponents/CrudSelectCar.vue';
import Loader from './UIComponents/Loader.vue';
import DirectoryAccess from './UIComponents/DirectoryAccess.vue';
import LetterboxAccess from './UIComponents/LetterboxAccess.vue';
import DistributionListSelect from './UIComponents/DistributionListSelect.vue';

export default {
  components: {
    [ElDatePicker.name]: ElDatePicker,
    [ElOptionGroup.name]: ElOptionGroup,
    ScheduleManager,
    CrudSelectCar,
    Loader,
    [ElBreadcrumb.name]: ElBreadcrumb,
    [ElBreadcrumbItem.name]: ElBreadcrumbItem,
    DirectoryAccess,
    LetterboxAccess,
    DistributionListSelect,
  },
  mixins: [MixinFetchData, MixinProcessImage],
  data() {
    return {
      loading: false,
      collaborator: {},
      collaboratorId: 0,
      materials: [],
      applications: [],
      allLetterbox: [],
      directories: [],
      distributionLists: [],
      editingDisabled: true,
      errorMessage: '',
      imagesPath: `${process.env.VUE_APP_INTRANET_API_URL}/images/collaborators`,
      specificationsPath: `${process.env.VUE_APP_INTRANET_API_URL}/specifications`,
      defaultImage: 'default.png',
      scheduleIsNotRegister: true,
      collaboratorImgSrc: null,
      nextCollaborator: null,
      prevCollaborator: null,
    };
  },
  computed: {
    ...mapGetters('collaborators', ['page']),
    buttonClassObject() {
      return {
        'btn-warning': this.editingDisabled,
        'btn-success': !this.editingDisabled,
      };
    },
    isAdmin() {
      return (
        this.$store.getters['account/roles'].includes(ROLES.ADMINISTRATOR)
        || this.$store.getters['account/roles'].includes(ROLES.POWER_USER)
      );
    },
    hasNextCollaborator() {
      return this.nextCollaborator
        ? this.nextCollaborator.id !== this.collaboratorId
        : false;
    },
    hasPrevCollaborator() {
      return this.prevCollaborator
        ? this.prevCollaborator.id !== this.collaboratorId
        : false;
    },
  },
  watch: {
    // call again the method if the route changes
    $route: 'initData',
  },
  created() {
    this.initData();
  },
  methods: {
    async initData() {
      this.collaboratorId = parseInt(this.$route.params.id, 10);
      this.fetchData();
      this.$store
        .dispatch('collaborators/getNextCollaborator', this.collaboratorId)
        .then((nextCollab) => {
          this.nextCollaborator = nextCollab;
        });
      this.$store
        .dispatch('collaborators/getPrevCollaborator', this.collaboratorId)
        .then((prevCollab) => {
          this.prevCollaborator = prevCollab;
        });
    },
    fetchData() {
      this.loading = true;
      this.fetchCollaboratorById(this.collaboratorId).then(() => {
        const fetchImg = this.fetchImageAsBlob(
          'images/collaborators/',
          this.collaborator.image,
        ).then((blobImage) => {
          if (blobImage) {
            // Create a temporary URL, which redirects to the blob object containing the image.
            // The URL lifetime is tied to the document in the window on which it was created.
            const urlCreatedFromBlob = URL.createObjectURL(blobImage);
            this.collaboratorImgSrc = urlCreatedFromBlob;
          }
        });

        const fetchMaterials = this.fetchNotAttributedMaterials(
          this.collaboratorId,
        );
        const fetchFunctions = this.fetchFunctions();
        const fetchApplications = this.fetchApplications();

        // These functions are coming from the "mixinFetchData" mixin.
        const fetchCollaboratorsNames = this.fetchCollaboratorsNames(
          this.collaboratorId,
        );
        const fetchServices = this.fetchServices();

        const fetchDirectories = this.fetchDirectories();
        const fetchLetterbox = this.fetchLetterbox();
        const fetchDistributionLists = this.fetchDistributionLists();

        Promise.all([
          fetchImg,
          fetchMaterials,
          fetchFunctions,
          fetchApplications,
          fetchCollaboratorsNames,
          fetchServices,
          fetchDirectories,
          fetchDistributionLists,
          fetchLetterbox,
        ]).finally(() => {
          this.loading = false;
        });
      });
    },
    lastnameInputChange(lastname) {
      this.collaborator.lastname = lastname.toUpperCase();
    },
    fetchNotAttributedMaterials(collaboratorId) {
      return this.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/materials/search/notAttributedAndSelfMaterials?collaboratorId=${collaboratorId}`,
        )
        .then((payload) => {
          this.materials = payload.data._embedded.materials
            ? this.formatMaterials(payload.data._embedded.materials)
            : null;
        })
        .catch((error) => {
          this.errorMessage = 'Une erreur est survenue lors du chargement des données, veuillez réessayer ou contacter le webmaster.';
        });
    },
    fetchCollaboratorCars(collaboratorId) {
      return this.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/cars/search/findByOwner?collaboratorId=${collaboratorId}`,
        )
        .then((payload) => {
          this.collaborator.carsIds = payload.data._embedded.cars.map(
            (car) => car.id,
          );
        })
        .catch((error) => {
          this.errorMessage = 'Une erreur est survenue lors du chargement des données, veuillez réessayer ou contacter le webmaster.';
        });
    },
    fetchCollaboratorMaterials(collaboratorId) {
      return this.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/materials/search/findByOwner?collaboratorId=${collaboratorId}`,
        )
        .then((payload) => {
          this.collaborator.materialsIds = payload.data._embedded.materials.map(
            (material) => material.id,
          );
        })
        .catch((error) => {
          this.errorMessage = 'Une erreur est survenue lors du chargement des données, veuillez réessayer ou contacter le webmaster.';
        });
    },
    fetchCars() {
      return this.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/cars/search/findAll?sort=licensePlateNumber,asc`,
        )
        .then((response) => ({
          records: response.data._embedded.cars
            ? response.data._embedded.cars.map((car) => ({
              id: car.id,
              value: car.licensePlateNumber,
            }))
            : [],
        }));
    },
    createCar(car) {
      return this.$http
        .post(`${process.env.VUE_APP_INTRANET_API_URL}/commands/cars/`, {
          licensePlateNumber: car.licensePlate,
          collaboratorsIds: car.ownersIds,
        })
        .catch((error) => {
          this.errorMessage = 'Une erreur est survenue durant la création du véhicule, veuillez réessayer ou contacter le support.';
        });
    },
    setSelectedCarId(carIds) {
      this.collaborator.carsIds = carIds;
    },
    fetchApplications() {
      return this.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/applications/search/findAll?sort=name,asc`,
        )
        .then((payload) => {
          this.applications = payload.data._embedded.applications;
        })
        .catch((error) => {
          this.errorMessage = 'Une erreur est survenue lors du chargement des données, veuillez réessayer ou contacter le webmaster.';
        });
    },
    fetchFunctions() {
      return this.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/functions/search/findAllOrderByName`,
        )
        .then((payload) => {
          this.functions = payload.data._embedded.functions;
        })
        .catch((error) => {
          this.errorMessage = 'Une erreur est survenue lors du chargement des données, veuillez réessayer ou contacter le webmaster.';
        });
    },
    fetchCollaboratorById(id) {
      return this.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/collaborators/${id}?projection=collaboratorDetails`,
        )
        .then((payload) => {
          this.collaborator = {
            ...payload.data,
            carsIds: [],
            materialsIds: [],
            upperHierarchyIds: payload.data.upperHierarchy.map((uh) => uh.id),
            functionId: payload.data.function ? payload.data.function.id : null,
            servicesIds: payload.data.services.map((service) => service.id),
            applicationsIds: payload.data.applications.map(
              (application) => application.id,
            ),
            schedule: this.convertScheduleFromJson(payload.data.schedule),
          };
          this.fetchCollaboratorCars(this.collaboratorId); // Get collaborators cars
          this.fetchCollaboratorMaterials(this.collaboratorId);
        })
        .catch((error) => {
          this.errorMessage = 'Une erreur est survenue lors du chargement des données, veuillez réessayer ou contacter le webmaster.';
        });
    },
    fetchDirectories() {
      return this.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/directories/search/findAll?projection=directoryProjection`,
        )
        .then((payload) => {
          this.directories = payload.data._embedded.directories;
        })
        .catch((error) => {
          this.errorMessage = 'Une erreur est survenue lors du chargement des données, veuillez réessayer ou contacter le webmaster.';
        });
    },
    fetchLetterbox() {
      return this.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/letterbox/search/findAll?projection=letterboxProjection`,
        )
        .then((payload) => {
          this.allLetterbox = payload.data._embedded.letterbox;
        })
        .catch((error) => {
          this.errorMessage = 'Une erreur est survenue lors du chargement des données, veuillez réessayer ou contacter le webmaster.';
        });
    },
    fetchDistributionLists() {
      return this.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/distributionList/search/findAll?projection=distributionListProjection`,
        )
        .then((payload) => {
          this.distributionLists = payload.data._embedded.distributionList;
        })
        .catch((error) => {
          this.errorMessage = 'Une erreur est survenue lors du chargement des données, veuillez réessayer ou contacter le webmaster.';
        });
    },
    formatMaterials(materials) {
      return MATERIALS_TYPES_ARRAY.map((materialType) => {
        const options = materials.filter((m) => m.type === materialType.value);
        return {
          label: materialType.name,
          options,
        };
      });
    },
    editButtonClicked() {
      if (!this.editingDisabled) {
        this.updateCollaborator();
      } else {
        this.editingDisabled = false;
      }
    },
    deleteButtonClicked() {
      Swal.fire({
        title: 'Êtes-vous sûr de vouloir supprimer ce collaborateur ?',
        text: "Il ne sera pas possible d'annuler cette suppression !",
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill',
        },
        confirmButtonText: 'Oui, effacer',
        cancelButtonText: 'Non, conserver',
        buttonsStyling: false,
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.deleteCollaborator();
          }
        })
        .catch(() => {});
    },
    cancelButtonClicked() {
      this.editingDisabled = true;
      this.fetchCollaboratorById(this.collaboratorId);
    },
    updateCollaborator() {
      this.errorMessage = '';

      if (
        this.collaborator.schedule
        && this.scheduleIsNotRegister === false
        && this.collaborator.schedule.id
      ) {
        this.updateSchedule(); // update the schedule
        this.requestUpdateCollaborator();
      } else if (
        this.collaborator.schedule
        && this.scheduleIsNotRegister === false
        && this.collaborator.schedule.id === null
      ) {
        this.createSchedule().then(() => this.requestUpdateCollaborator());
      } else {
        this.requestUpdateCollaborator();
      }
    },
    requestUpdateCollaborator() {
      const formData = new FormData();
      formData.append('image', this.collaborator.newImage);
      formData.append('specifications', this.collaborator.newSpecifications);
      // Add the properties as application/json content type in order to unserialize them in the server.
      formData.append(
        'properties',
        new Blob(
          [
            JSON.stringify({
              lastname: this.collaborator.lastname,
              firstname: this.collaborator.firstname,
              initials: this.collaborator.initials,
              email: this.collaborator.email,
              birthdayDate: this.collaborator.birthdayDate,
              internalphone: this.collaborator.internalphone,
              privatephone: this.collaborator.privatephone,
              activities: this.collaborator.activities,
              rate: this.collaborator.rate,
              isActive: this.collaborator.isActive,
              functionId: this.collaborator.functionId,
              upperHierarchyIds: this.collaborator.upperHierarchyIds,
              servicesIds: this.collaborator.servicesIds,
              materialsIds: this.collaborator.materialsIds,
              applicationsIds: this.collaborator.applicationsIds,
              carsIds: this.collaborator.carsIds,
              scheduleId: this.collaborator.schedule.id,
              directoriesAccess: this.collaborator.directoriesAccess.map(
                (access) => ({
                  id: access.id,
                  collaboratorId: this.collaborator.id,
                  accessRight: access.accessRight,
                  directoryId: access.directory.id,
                }),
              ),
              letterboxAccess: this.collaborator.letterboxAccess.map(
                (access) => ({
                  id: access.id,
                  collaboratorId: this.collaborator.id,
                  accessRight: access.accessRight,
                  letterboxId: access.letterbox.id,
                }),
              ),
              distributionListsIds: this.collaborator.distributionLists.map(
                (dl) => dl.id,
              ),
            }),
          ],
          {
            type: 'application/json',
          },
        ),
      );

      this.$http
        .put(
          `${process.env.VUE_APP_INTRANET_API_URL}/commands/collaborators/${this.collaborator.id}`,
          formData,
        )
        .then(() => {
          this.fetchCollaboratorById(this.collaborator.id);
          this.errorMessage = '';
          this.editingDisabled = true;
        })
        .catch((error) => {
          this.errorMessage = "Une erreur est survenue lors de l'édition des données du collaborateur, veuillez réessayer ou contacter le webmaster.";
        });
    },
    deleteCollaborator() {
      this.$http
        .delete(
          `${process.env.VUE_APP_INTRANET_API_URL}/commands/collaborators/${this.collaborator.id}`,
        )
        .then(() => {
          this.errorMessage = '';
          this.$router.push({
            name: 'Collaborators',
          });
        })
        .catch(() => {
          this.errorMessage = "Une erreur s'est produite lors de la suppression du collaborateur, veuillez réessayer.";
        });
    },
    setSpecifications(event) {
      if (event.target.files[0]) {
        [this.collaborator.newSpecifications] = event.target.files;
      }
    },
    specificationsClick(event) {
      this.downloadDocument(
        'specifications/',
        this.collaborator.specifications,
        event,
      );
    },
    convertScheduleFromJson(schedule) {
      if (schedule === null) {
        this.scheduleIsNotRegister = true;
        return ScheduleService.initSchedule();
      }
      this.scheduleIsNotRegister = false;
      return ScheduleService.convertScheduleFromJson(schedule);
    },
    addNewSchedule() {
      this.scheduleIsNotRegister = false;
    },
    createSchedule() {
      if (this.collaborator.schedule) {
        return ScheduleService.createSchedule(this.collaborator.schedule)
          .then((res) => {
            if (res.data) {
              this.collaborator.schedule.id = res.data.id;
            }
          })
          .catch((error) => {
            this.errorMessage = "Une erreur est survenue lors de la création de l'horaire "
              + ', veuillez réessayer ou contacter le webmaster.';
          });
      }
      return null;
    },
    updateSchedule() {
      if (this.collaborator.schedule && this.collaborator.schedule.id) {
        return ScheduleService.updateSchedule(this.collaborator.schedule).catch(
          (error) => {
            this.errorMessage = "Une erreur est survenue lors de la création de l'horaire "
              + ', veuillez réessayer ou contacter le webmaster.';
          },
        );
      }
      return null;
    },
    scheduleChange(schedule) {
      this.collaborator.schedule = schedule;
    },
    letterboxChange({ value, position }) {
      const { letterboxAccess } = this.collaborator;
      letterboxAccess[position].letterbox = value;

      this.collaborator.letterboxAccess = letterboxAccess;
    },
    accessLetterboxChange({ value, position }) {
      const { letterboxAccess } = this.collaborator;
      letterboxAccess[position].accessRight = value;

      this.collaborator.letterboxAccess = letterboxAccess;
    },
    deleteLetterboxAccess(position) {
      const { letterboxAccess } = this.collaborator;
      letterboxAccess.splice(position, 1);

      this.collaborator.letterboxAccess = letterboxAccess;
    },
    addLetterboxAccess() {
      this.collaborator.letterboxAccess.push({
        accessRight: '',
        letterbox: {},
      });
    },
    directoryChange({ value, position }) {
      const { directoriesAccess } = this.collaborator;
      directoriesAccess[position].directory = value;

      this.collaborator.directoriesAccess = directoriesAccess;
    },
    accessDirectoryChange({ value, position }) {
      const { directoriesAccess } = this.collaborator;
      directoriesAccess[position].accessRight = value;

      this.collaborator.directoriesAccess = directoriesAccess;
    },
    deleteDirectoryAccess(position) {
      const { directoriesAccess } = this.collaborator;
      directoriesAccess.splice(position, 1);

      this.collaborator.directoriesAccess = directoriesAccess;
    },
    addDirectoryAccess() {
      this.collaborator.directoriesAccess.push({
        accessRight: '',
        directory: {},
      });
    },
    distributionListChange({ value, position }) {
      this.collaborator.distributionLists[position] = value;
    },
    deleteDistributionList(position) {
      const { distributionLists } = this.collaborator;
      distributionLists.splice(position, 1);

      this.collaborator.distributionLists = distributionLists;
    },
    addDistributionList() {
      this.collaborator.distributionLists.push({});
    },
    async goPrevCollaborator() {
      if (this.prevCollaborator.isOnNewPage) {
        await this.$store.dispatch('collaborators/fetchCollaboratorsPage', {
          pageNumber: this.page.number - 1,
          pageSize: this.page.size,
        });
      }
      this.$router.push(`/collaborators/${this.prevCollaborator.id}`);
    },
    async goNextCollaborator() {
      if (this.nextCollaborator.isOnNewPage) {
        await this.$store.dispatch('collaborators/fetchCollaboratorsPage', {
          pageNumber: this.page.number + 1,
          pageSize: this.page.size,
        });
      }
      this.$router.push(`/collaborators/${this.nextCollaborator.id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.top-buttons {
  display: flex;
  margin-top: -30px;
  margin-bottom: 0px;
}

.navigation-container {
  margin: 0 1rem;
}

.collaborator-image {
  max-height: 40vh;
}

@media screen and (max-width: $mobile-max-width) {
  .top-buttons {
    flex-direction: column;
  }
  .action-container {
    margin: 0.5em $mobile-button-margin-x;

    button {
      margin: 0.25em 0;
    }
  }
  .navigation-container {
    margin-top: 0.25rem;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }
}
</style>

<style lang="scss">
.birthday-input.is-disabled input,
.birthday-input.is-disabled i {
  background-color: #e3e3e3 !important;
  color: #66615b !important;
}

.loading-container {
  width: 100%;
  height: 100vh;
}
</style>
