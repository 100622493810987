<template>
  <div>
    <details-modal
      :show="show"
      current-operation="create"
      :loading="loading"
      @close="closeModal"
      @cancel="closeModal"
      @create="createRecord"
    >
      <Form :validation-schema="validationSchema" validate-on-mount>
        <p-checkbox v-model="document.isExternal">
          Le document est un lien externe
        </p-checkbox>
        <div v-if="!document.isExternal">
          <label class="control-label">*Fichier</label>
          <p>
            <input
              id="file"
              type="file"
              name="file"
              @change="setFile($event)"
            /><br />
          </p>
        </div>
        <div v-if="document.isExternal" class="form-group">
          <label class="control-label">*Lien</label>
          <Field
            name="link"
            v-model="document.link"
            v-slot="{ field, errorMessage }"
          >
            <input
              v-bind="field"
              class="form-control"
              type="text"
              placeholder="https://annuaire.ciip.ch/doc.pdf"
            />
            <small v-show="errorMessage" class="text-danger">
              Le lien est requis.
            </small>
          </Field>
        </div>
        <div class="form-group">
          <label class="control-label">*Titre</label>
          <Field
            name="title"
            v-model="document.title"
            v-slot="{ field, errorMessage }"
          >
            <input v-bind="field" class="form-control" type="text" />
            <small v-show="errorMessage" class="text-danger">
              Le titre est requis.
            </small>
          </Field>
        </div>
        <p>
          <label for="select-function">*Catégorie</label>
          <el-select
            id="select-category"
            v-model="document.categoryId"
            class="select-info col-md-12 select-one-line-per-item"
            size="large"
            placeholder="Sélectionner"
            @change="setSelectedCategory"
            filterable
          >
            <el-option
              v-for="category in categories"
              :key="category.id"
              :label="category.name"
              :value="category.id"
            />
          </el-select>
        </p>
        <tag-select
          :tagId="document.tagId"
          :categoryId="document.categoryId"
          @setSelectedTagId="setSelectedTagId"
        />
        <div class="form-group">
          <label class="control-label"
            >Position (max: {{ maxPositionDocumentInCateogry }})</label
          >
          <el-input-number
            v-model="document.position"
            name="position"
            :min="1"
            :max="maxPositionDocumentInCateogry"
          />
        </div>
        <p><label>* champs obligatoires</label></p>
      </Form>
      <p v-if="errorMessage" class="error-message-in-modal">
        {{ errorMessage }}
      </p>
    </details-modal>
  </div>
</template>

<script>
import { ElInputNumber as InputNumber } from 'element-plus';
import { Form, Field } from 'vee-validate';
import * as yup from 'yup';
import DetailsModal from './DetailsModal.vue';
import TagSelect from '../UIComponents/TagSelect.vue';

export default {
  components: {
    DetailsModal,
    TagSelect,
    [InputNumber.name]: InputNumber,
    Form,
    Field,
  },
  props: ['show', 'category'],
  data() {
    return {
      document: {
        isExternal: false,
        categoryId: null,
        tagId: null,
        position: 1,
      },
      totalDocumentsInCategory: 0,
      errorMessage: '',
      categories: [],
      validationSchema: {
        title: yup.string().required(),
      },
      loading: false,
    };
  },
  computed: {
    maxPositionDocumentInCateogry() {
      return this.totalDocumentsInCategory + 1;
    },
  },
  watch: {
    show: {
      handler(newVal) {
        if (newVal) {
          this.fetchData();
        }
      },
    },
    'document.isExternal': {
      handler(newVal) {
        if (newVal) {
          this.validationSchema = {
            link: yup.string().required(),
            title: yup.string().required(),
          };
        } else {
          this.validationSchema = {
            title: yup.string().required(),
          };
        }
      },
    },
  },
  mounted() {},
  methods: {
    async createRecord() {
      // Validate each field with vee-validate, and post the new document.
      const validationSchemaObject = yup.object(this.validationSchema);
      const isDocumentValid = await validationSchemaObject.isValid(
        this.document,
      );

      if (isDocumentValid) {
        this.errorMessage = null;

        const formData = new FormData();

        if (this.document.file) {
          formData.append('file', this.document.file);
        }

        // Add the properties as application/json content type in order to unserialize them in the server.
        formData.append(
          'properties',
          new Blob(
            [
              JSON.stringify({
                isExternal: this.document.isExternal,
                path: this.document.link,
                title: this.document.title,
                categoryId: this.document.categoryId,
                tagId: this.document.tagId,
                position: this.document.position - 1,
              }),
            ],
            {
              type: 'application/json',
            },
          ),
        );

        this.loading = true;

        this.$http
          .post(
            `${process.env.VUE_APP_INTRANET_API_URL}/commands/documents/`,
            formData,
          )
          .then(() => {
            this.closeModal();
          })
          .catch((error) => {
            this.errorMessage = 'Une erreur est survenue lors du chargement des données, veuillez réessayer ou contacter le webmaster.';
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.errorMessage = 'Un ou plusieurs champs sont invalides.';
      }
    },
    closeModal() {
      this.$emit('close');
      this.document.isExternal = false;
      this.document.link = null;
      this.document.title = null;
      this.document.tagId = null;
      this.document.position = 1;
      this.categories = [];
      this.document.isExternal = false;
      this.errorMessage = '';

      // Reset the file input.
      if (document.getElementById('file')) {
        document.getElementById('file').value = '';
      }
    },
    fetchNumberOfDocuments() {
      return this.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/documents/search/countByCategoryId?categoryId=${this.document.categoryId}`,
        )
        .then((response) => {
          this.totalDocumentsInCategory = response.data;
        });
    },
    fetchAllCategories() {
      return this.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/documentsCategories?sort=name,asc`,
        )
        .then((response) => {
          this.categories = response.data._embedded.documentsCategories;
        })
        .catch((error) => {
          this.errorMessage = 'Une erreur est survenue lors du chargement des données, veuillez réessayer ou contacter le webmaster.';
        });
    },
    fetchData() {
      this.loading = true;
      this.fetchAllCategories()
        .then(() => {
          // Select the category of the current page in the categories list.
          this.document.categoryId = this.categories.filter(
            (c) => c.name === this.category,
          )[0].id;
        })
        .then(() => this.fetchNumberOfDocuments())
        .finally(() => {
          this.loading = false;
        });
    },
    setFile(event) {
      if (event.target.files[0]) {
        [this.document.file] = event.target.files;
      }
    },
    setSelectedTagId(tagId) {
      this.document.tagId = tagId;
    },
    setSelectedCategory(category) {
      this.document.position = 1;
      this.document.category = category;
      this.fetchNumberOfDocuments();
    },
  },
};
</script>
