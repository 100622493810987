<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div>
    <details-modal
      :show="show"
      :current-operation="currentOperation"
      :loading="loading"
      @close="closeModal"
      @cancel="closeModal"
      @create="createRecord"
      @update="updateRecord"
      @delete="deleteRecord"
    >
      <Form :validation-schema="validationSchema" validate-on-mount>
        <div class="form-group">
          <label class="control-label">*Nom</label>
          <Field
            name="name"
            v-model="platform.name"
            v-slot="{ field, errorMessage }"
          >
            <input v-bind="field" class="form-control" type="text" />
            <small v-show="errorMessage" class="text-danger">
              Le nom est requis.
            </small>
          </Field>
        </div>
        <p>
          <label for="select-function">*Responsable</label>
          <el-select
            id="select-function"
            v-model="platform.responsibleId"
            class="select-info col-md-12 select-one-line-per-item"
            size="large"
            name="responsibleId"
            placeholder="Sélectionner"
          >
            <el-option
              v-for="c in collaboratorsName"
              :key="c.id"
              :label="`${c.lastname} ${c.firstname}`"
              :value="c.id"
            />
          </el-select>
          <Field
            name="responsibleId"
            v-model="platform.responsibleId"
            v-slot="{ errorMessage }"
          >
            <small v-show="errorMessage" class="text-danger">
              Le responsable est requis.
            </small>
          </Field>
        </p>
        <div class="form-group">
          <label class="control-label">*Hébergement</label>
          <Field
            name="hosting"
            v-model="platform.hosting"
            v-slot="{ field, errorMessage }"
          >
            <input v-bind="field" class="form-control" type="text" />
            <small v-show="errorMessage" class="text-danger">
              L'hébergement est requis.
            </small>
          </Field>
        </div>
        <div class="form-group">
          <label class="control-label">*Développement & Maintenance</label>
          <Field
            name="developerAndMaintainer"
            v-model="platform.developerAndMaintainer"
            v-slot="{ field, errorMessage }"
          >
            <input v-bind="field" class="form-control" type="text" />
            <small v-show="errorMessage" class="text-danger">
              Ce champ est requis.
            </small>
          </Field>
        </div>
        <p>
          <label class="control-label">*Gestion des contenus</label>
          <el-select
            id="select-content-manager"
            v-model="platform.contentManagersId"
            class="select-info col-md-12 select-one-line-per-item"
            size="large"
            name="users"
            placeholder="Sélectionner"
            filterable
            clearable
            :multiple="true"
          >
            <el-option
              v-for="c in allCollaboratorsName"
              :key="c.id"
              :label="`${c.lastname} ${c.firstname}`"
              :value="c.id"
            >
              <el-tooltip
                v-if="!c.isActive"
                content="Inactif"
                placement="bottom"
              >
                <i class="fa fa-user-times"></i>
              </el-tooltip>
              <span> {{ c.lastname }} {{ c.firstname }}</span>
            </el-option>
          </el-select>
          <Field
            name="users"
            v-model="platform.contentManagersId"
            v-slot="{ errorMessage }"
          >
            <small v-show="errorMessage" class="text-danger">
              Ce champ est requis.
            </small>
          </Field>
        </p>
        <div class="form-group">
          <label class="control-label">*Site web</label>
          <Field
            name="website"
            v-model="platform.website"
            v-slot="{ field, errorMessage }"
          >
            <input
              v-bind="field"
              class="form-control"
              type="text"
              placeholder="https://www.ciip.ch"
            />
            <small v-show="errorMessage" class="text-danger">
              Le site web est requis.
            </small>
          </Field>
        </div>
        <div class="form-group">
          <label class="control-label">Description</label>
          <input
            v-model="platform.description"
            class="form-control"
            type="text"
            name="description"
          />
        </div>
        <p><label>* champs obligatoires</label></p>
      </Form>
      <p v-if="errorMessage" class="error-message-in-modal">
        {{ errorMessage }}
      </p>
    </details-modal>
  </div>
</template>

<script>
import { ElTooltip as Tooltip } from 'element-plus';
import { Form, Field } from 'vee-validate';
import Swal from 'sweetalert2';
import * as yup from 'yup';
import MixinFetchData from '@/mixins/mixinFetchData';
import DetailsModal from './DetailsModal.vue';

export default {
  components: {
    DetailsModal,
    [Tooltip.name]: Tooltip,
    Form,
    Field,
  },
  mixins: [MixinFetchData],
  props: ['show', 'currentOperation', 'platform'],
  data() {
    return {
      errorMessage: '',
      validationSchema: {
        name: yup.string().required(),
        responsibleId: yup.string().required(),
        hosting: yup.string().required(),
        developerAndMaintainer: yup.string().required(),
        contentManagersId: yup.array().of(yup.number()).required().min(1),
        website: yup.string().required(),
      },
      loading: false,
    };
  },
  watch: {
    show: {
      handler(newVal) {
        if (newVal) {
          this.fetchCollaboratorsNames();
          this.fetchAllCollaboratorsNames();
        }
      },
    },
  },
  methods: {
    async createRecord() {
      // Validate each field with vee-validate, and post the new platform.
      const validationSchemaObject = yup.object(this.validationSchema);
      const isPlatformValid = await validationSchemaObject.isValid(
        this.platform,
      );

      if (isPlatformValid) {
        this.errorMessage = null;

        this.loading = true;

        this.$http
          .post(`${process.env.VUE_APP_INTRANET_API_URL}/commands/platforms/`, {
            ...this.platform,
          })
          .then(() => {
            this.closeModal();
          })
          .catch((error) => {
            this.errorMessage = "Une erreur est survenue lors de l'ajout de la plateforme, veuillez réessayer ou contacter le webmaster.";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.errorMessage = 'Un ou plusieurs champs sont invalides.';
      }
    },
    async updateRecord() {
      // Validate each field with vee-validate, and update the platform.
      const validationSchemaObject = yup.object(this.validationSchema);
      const isPlatformValid = await validationSchemaObject.isValid(
        this.platform,
      );

      if (isPlatformValid) {
        this.errorMessage = null;

        this.loading = true;

        this.$http
          .patch(
            `${process.env.VUE_APP_INTRANET_API_URL}/commands/platforms/${this.platform.id}`,
            {
              ...this.platform,
            },
          )
          .then(() => {
            this.closeModal();
          })
          .catch((error) => {
            this.errorMessage = 'Une erreur est survenue lors de la modification de la plateforme, veuillez réessayer ou contacter le webmaster.';
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.errorMessage = 'Un ou plusieurs champs sont invalides.';
      }
    },
    deleteRecord() {
      this.errorMessage = null;

      Swal.fire({
        title: 'Êtes-vous sûr de vouloir supprimer cette plateforme ?',
        text: "Il ne sera pas possible d'annuler cette suppression !",
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill',
        },
        confirmButtonText: 'Oui, effacer',
        cancelButtonText: 'Non, conserver',
        buttonsStyling: false,
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.$http
              .delete(
                `${process.env.VUE_APP_INTRANET_API_URL}/platforms/${this.platform.id}`,
              )
              .then(() => {
                this.closeModal();
              })
              .catch((error) => {
                this.errorMessage = 'Une erreur est survenue lors de la suppression de la plateforme, veuillez réessayer ou contacter le webmaster.';
              });
          }
        })
        .catch(() => {});
    },
    closeModal() {
      this.$emit('close');
      this.resetData();
      this.errorMessage = '';
    },
  },
};
</script>
