<template>
  <documents-crud-list
    title="Ressources humaines"
    category="Ressources humaines"
  />
</template>

<script>
import DocumentsCrudList from './UIComponents/DocumentsCrudList.vue';

export default {
  components: {
    DocumentsCrudList,
  },
};
</script>
