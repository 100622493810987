<template>
  <div>
    <button class="btn btn-export" @click="exportDistributionListToXlsx">
      Exporter la liste au format Excel
    </button>
    <div class="row">
      <div class="col-md-12">
        <distribution-modal
          :show="showModal"
          :distributionListProps="distributionListSelected"
          :currentOperation="currentOperation"
          @close="closeModal"
        />
        <crud-list
          ref="crudList"
          :fetch-data="fetchDistributionList"
          title="Listes de distribution"
          @create="createDistributionList"
          @row-clicked="openDistributionListMails"
        >
          <button-column
            type="edit"
            button-title="Modifier la liste de distribution"
            :show-on-mobile="true"
            :update-function="updateDistributionList"
          />
          <el-table-column label="Nom" min-width="200" prop="name">
            <template #default="slotProps">
              <div class="table-items-list-item">
                {{ `${slotProps.row.name}` }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Utilisateur(s)" min-width="180">
            <template #default="slotProps">
              <div class="table-items-list-item">
                <span
                  v-for="(user, index) in slotProps.row.collaborators"
                  :key="user.id"
                  :class="{ inactif: !user.isActive }"
                  class="user"
                >
                  <router-link
                    :to="{
                      name: 'CollaboratorDetails',
                      params: { id: user.id },
                    }"
                  >
                    <el-tooltip
                      v-if="!user.isActive"
                      content="Inactif"
                      placement="bottom"
                    >
                      <i class="fa fa-user-times"></i>
                    </el-tooltip>
                    {{ user.firstname }} {{ user.lastname }}
                    {{
                      index === slotProps.row.collaborators.length - 1
                        ? ''
                        : ','
                    }}
                  </router-link>
                </span>
              </div>
            </template>
          </el-table-column>
          <button-column
            type="edit"
            button-title="Modifier la liste de distribution"
            :show-on-mobile="false"
            :update-function="updateDistributionList"
          />
          <el-table-column width="75">
            <div class="table-items-list-item">
              <button
                class="btn btn-icon btn-round"
                type="button"
                title="Envoyer l'e-mail"
                @click="openDistributionListMails"
              >
                <i class="fa fa-envelope"></i>
              </button>
            </div>
          </el-table-column>
        </crud-list>
      </div>
    </div>
  </div>
</template>

<script>
import { ElTooltip } from 'element-plus';
import XLSX from 'xlsx';
import CrudList from './UIComponents/CrudList.vue';
import ButtonColumn from './UIComponents/ButtonColumn.vue';
import DistributionModal from './Modals/DistributionModal.vue';

export default {
  components: {
    CrudList,
    ButtonColumn,
    DistributionModal,
    [ElTooltip.name]: ElTooltip,
  },
  data() {
    return {
      showModal: false,
      distributionListSelected: null,
      currentOperation: 'create',
    };
  },
  methods: {
    fetchDistributionList(pageNumber, pageSize) {
      return this.$http
        .get(
          `${
            process.env.VUE_APP_INTRANET_API_URL
          }/distributionList/search/findAllPage?projection=distributionListProjection&page=${
            pageNumber - 1
          }&size=${pageSize}`,
        )
        .then((response) => {
          const { distributionList } = response.data._embedded;
          return {
            records: distributionList,
            page: response.data.page ? response.data.page : 0,
          };
        });
    },
    fetchAllDistributionList() {
      return this.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/distributionList/search/findAll?projection=distributionListProjection`,
        )
        .then((response) => response.data._embedded.distributionList);
    },
    updateDistributionList(row, event) {
      event.stopPropagation();

      this.currentOperation = 'edit';
      this.showModal = true;

      this.distributionListSelected = {
        ...row,
        collaboratorsId: row.collaborators.map(
          (collaborator) => collaborator.id,
        ),
      };
    },
    createDistributionList() {
      this.currentOperation = 'create';
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.$refs.crudList.fetch();
    },
    openDistributionListMails(row) {
      window.open(
        `mailto:${row.collaborators
          .filter((collaborator) => collaborator.isActive)
          .map((collaborator) => collaborator.email)
          .join(';')}`,
        '_blank', // <- This is what makes it open in a new window.
      );
    },
    exportDistributionListToXlsx() {
      const wsData = [
        [
          'Nom de la liste de distribution',
          'Collaborateurs',
          'E-mails des collaborateurs',
        ],
      ];

      this.fetchAllDistributionList().then((distributionList) => {
        distributionList.forEach((c) => {
          const collaborators = c.collaborators
            .map(
              (collaborator) => `${collaborator.firstname} ${collaborator.lastname}`,
            )
            .join(', ');
          const emails = c.collaborators
            .map((collaborator) => collaborator.email)
            .join('; ');
          wsData.push([c.name, collaborators, emails]);
        });

        const ws = XLSX.utils.aoa_to_sheet(wsData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'ListesDeDistribution');
        XLSX.writeFile(wb, 'ciip_listes_de_distribution.xlsx');
        this.$notify({
          title: 'Téléchargement',
          text: 'Téléchargement du fichier contenant les listes de distribution',
          type: 'success',
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.access-right {
  font-size: 0.8em;
  font-style: italic;
}

.user a {
  color: inherit !important;
}

.inactif {
  color: rgb(169, 50, 50);
}
</style>
