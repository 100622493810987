<template>
  <el-table-column
    v-if="isAllowedIfAdminComponent && showComponent"
    label=""
    :width="showOnMobile ? 62 : 75"
  >
    <template #default="slotProps">
      <div class="table-items-list-item">
        <button
          class="btn btn-icon btn-round"
          v-bind:class="{ 'btn-danger': type === deleteType }"
          type="button"
          :title="buttonTitle"
          @click="updateFunction(slotProps.row, $event)"
        >
          <i class="fa" :class="icon" />
        </button>
      </div>
    </template>
  </el-table-column>
</template>

<script>
import ROLES from '@/services/Roles';

export default {
  name: 'ButtonColumn',
  props: {
    type: String, // Either 'edit' or 'delete' or shared, change the button color and the icon.
    buttonTitle: String,
    showOnMobile: Boolean, // If true show the component on mobile only, if false show it on desktop only.
    updateFunction: Function,
    admin: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  data() {
    return {
      mobileWindowMaxWidth: 991,
      deleteType: 'delete',
      editType: 'edit',
      sharedType: 'shared',
      windowInnerWidth: window.innerWidth,
    };
  },
  created() {
    window.addEventListener('resize', this.resize);
  },
  unmounted() {
    window.removeEventListener('resize', this.resize);
  },
  computed: {
    // Based on the showOnMobile prop and the size of the window, return true if the component has to be shown of false otherwise.
    showComponent() {
      const isWebsiteOnMobileMode = this.windowInnerWidth <= this.mobileWindowMaxWidth;
      return (
        (isWebsiteOnMobileMode && this.showOnMobile)
        || (!isWebsiteOnMobileMode && !this.showOnMobile)
      );
    },
    isAllowedIfAdminComponent() {
      // If component admin, check if user have the correct rights
      if (this.admin) {
        return this.isAdmin;
      }
      // Composant public so always show
      return true;
    },
    isAdmin() {
      return (
        this.$store.getters['account/roles'].includes(ROLES.ADMINISTRATOR)
        || this.$store.getters['account/roles'].includes(ROLES.POWER_USER)
      );
    },
    icon() {
      let icon;
      switch (this.type) {
        case this.deleteType:
          icon = 'fa-trash';
          break;
        case this.sharedType:
          icon = 'fa-share-alt';
          break;
        default:
          icon = 'fa-pencil-square-o'; // default edit
      }
      return icon;
    },
  },
  methods: {
    resize() {
      this.windowInnerWidth = window.innerWidth;
    },
  },
};
</script>
