<template>
  <modal
    :show="show"
    footer-classes="justify-content-center"
    type="notice"
    @close="close"
  >
    <template #header>
      <h5 class="modal-title">Quoi de neuf ?</h5>
    </template>
    <rich-text-editor v-model="whatsUpMessageValue" />
    <p v-if="errorMessage" class="error-message-in-modal">
      {{ errorMessage }}
    </p>
    <template #footer>
      <div class="justify-content-center d-flex">
        <p-button type="success" round @click="updateWhatsUpMessage()">
          Mettre à jour
        </p-button>
        <span>&nbsp;</span>
        <p-button type="light" round @click="$emit('close')">
          Annuler
        </p-button>
      </div>
    </template>
  </modal>
</template>

<script>
import { Modal } from '../../UIComponents';
import RichTextEditor from '../UIComponents/richTextEditor/RichTextEditor.vue';

export default {
  components: {
    RichTextEditor,
    Modal,
  },
  props: ['show', 'whatsUpMessage'],
  data() {
    return {
      whatsUpMessageValue: null,
      errorMessage: null,
    };
  },
  watch: {
    whatsUpMessage: {
      handler(newVal) {
        this.whatsUpMessageValue = newVal;
      },
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    updateWhatsUpMessage() {
      this.errorMessage = null;

      this.$http
        .patch(`${process.env.VUE_APP_INTRANET_API_URL}/homePage/1`, {
          whatsUpMessage: this.whatsUpMessageValue,
        })
        .then(() => {
          this.close();
        })
        .catch((error) => {
          this.errorMessage = error.response.data.message;
        });
    },
  },
};
</script>
