<template>
  <div
    class="sidebar"
    :data-color="backgroundColor"
    :data-active-color="activeColor"
  >
    <div class="logo">
      <router-link class="simple-text logo-mini" to="/">
        <div class="logo-img">
          <img :src="logo" alt="logo intranet" />
        </div>
      </router-link>
      <router-link class="simple-text logo-normal" to="/">
        {{ title }}
      </router-link>
    </div>

    <perfect-scrollbar>
      <slot />
      <ul class="nav">
        <slot name="links">
          <sidebar-item
            v-for="(link, index) in links"
            :key="link.name + index"
            :link="link"
          >
            <template v-if="link.children" #default>
              <sidebar-item
                v-for="(subLink, index) in link.children"
                :key="subLink.name + index"
                :link="subLink"
              />
            </template>
          </sidebar-item>
        </slot>
      </ul>
    </perfect-scrollbar>
  </div>
</template>

<script>
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';
import ROLES from '@/services/Roles';
import SidebarItem from '@/components/UIComponents/SidebarPlugin/SidebarItem.vue';

export default {
  components: {
    SidebarItem,
    PerfectScrollbar,
  },
  props: {
    title: {
      type: String,
      default: 'Vue PDP Pro_X',
      description: 'Sidebar title',
    },
    backgroundColor: {
      type: String,
      default: 'black',
      validator: (value) => {
        const acceptedValues = ['white', 'brown', 'black'];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: 'Sidebar background color (white|brown|black)',
    },
    activeColor: {
      type: String,
      default: 'success',
      validator: (value) => {
        const acceptedValues = [
          'primary',
          'info',
          'success',
          'warning',
          'danger',
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
      description:
        'Sidebar active text color (primary|info|success|warning|danger)',
    },
    logo: {
      type: String,
      default: 'static/img/vue-logo.png',
      description: 'Sidebar Logo',
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
      description:
        'Sidebar links. Can be also provided as children components (sidebar-item)',
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  beforeUnmount() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
  computed: {
    links() {
      // is admin not defined or if defined and isAdmin, show link
      return this.sidebarLinks.filter(
        (link) => !link.admin || (link.admin && this.isAdmin),
      );
    },
    isAdmin() {
      return (
        this.$store.getters['account/roles'].includes(ROLES.ADMINISTRATOR)
        || this.$store.getters['account/roles'].includes(ROLES.POWER_USER)
      );
    },
  },
};
</script>

<style src="vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css"></style>

<style lang="scss">
@media (min-width: 992px) {
  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }
}

.ps {
  height: calc(100vh - 75px);
  z-index: 4;
}
</style>
