<template>
  <div v-if="editor">
    <div class="menubar">
      <el-tooltip content="Annuler" :open-delay="1000" placement="top">
        <button
          class="menubar__button"
          @click="editor.chain().undo().run()"
          :disabled="!editor.can().undo()"
        >
          <i class="fa fa-undo" />
        </button>
      </el-tooltip>

      <el-tooltip content="Rétablir" :open-delay="1000" placement="top">
        <button
          class="menubar__button"
          @click="editor.chain().redo().run()"
          :disabled="!editor.can().redo()"
        >
          <i class="fa fa-repeat" />
        </button>
      </el-tooltip>

      <el-tooltip content="Gras" :open-delay="1000" placement="top">
        <button
          class="menubar__button"
          :class="{ 'is-active': editor.isActive('bold') }"
          @click="editor.chain().focus().toggleBold().run()"
        >
          <i class="fa fa-bold" />
        </button>
      </el-tooltip>

      <el-tooltip content="Italique" :open-delay="1000" placement="top">
        <button
          class="menubar__button"
          :class="{ 'is-active': editor.isActive('italic') }"
          @click="editor.chain().focus().toggleItalic().run()"
        >
          <i class="fa fa-italic" />
        </button>
      </el-tooltip>

      <el-tooltip content="Souligné" :open-delay="1000" placement="top">
        <button
          class="menubar__button"
          :class="{ 'is-active': editor.isActive('underline') }"
          @click="editor.chain().focus().toggleUnderline().run()"
        >
          <i class="fa fa-underline" />
        </button>
      </el-tooltip>

      <el-tooltip content="Barré" :open-delay="1000" placement="top">
        <button
          class="menubar__button"
          :class="{ 'is-active': editor.isActive('strike') }"
          @click="editor.chain().focus().toggleStrike().run()"
        >
          <i class="fa fa-strikethrough" />
        </button>
      </el-tooltip>

      <el-tooltip
        content="Couleur de police"
        :open-delay="1000"
        placement="top"
      >
        <label for="text-color">
          <i
            :style="{
              color: selectedTextColor,
            }"
            class="menubar__button fa fa-tint"
          />
          <input
            class="input-color"
            type="color"
            id="text-color"
            @input="editor.chain().focus().setColor($event.target.value).run()"
            :value="editor.getAttributes('textStyle').color"
          />
        </label>
      </el-tooltip>

      <el-tooltip content="Liste numérotée" :open-delay="1000" placement="top">
        <button
          class="menubar__button"
          :class="{ 'is-active': editor.isActive('orderedList') }"
          @click="editor.chain().focus().toggleOrderedList().run()"
        >
          <i class="fa fa-list-ol" />
        </button>
      </el-tooltip>

      <el-tooltip content="Liste à puces" :open-delay="1000" placement="top">
        <button
          class="menubar__button"
          :class="{ 'is-active': editor.isActive('bulletList') }"
          @click="editor.chain().focus().toggleBulletList().run()"
        >
          <i class="fa fa-list-ul" />
        </button>
      </el-tooltip>

      <el-tooltip content="Aligner à gauche" :open-delay="1000" placement="top">
        <button
          class="menubar__button"
          :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }"
          @click="editor.chain().focus().setTextAlign('left').run()"
        >
          <i class="fa fa-align-left" />
        </button>
      </el-tooltip>

      <el-tooltip
        content="Aligner au center"
        :open-delay="1000"
        placement="top"
      >
        <button
          class="menubar__button"
          :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }"
          @click="editor.chain().focus().setTextAlign('center').run()"
        >
          <i class="fa fa-align-center" />
        </button>
      </el-tooltip>

      <el-tooltip content="Aligner à droite" :open-delay="1000" placement="top">
        <button
          class="menubar__button"
          :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }"
          @click="editor.commands.setTextAlign('right')"
        >
          <i class="fa fa-align-right" />
        </button>
      </el-tooltip>

      <el-tooltip content="Justifier" :open-delay="1000" placement="top">
        <button
          class="menubar__button"
          :class="{ 'is-active': editor.isActive({ textAlign: 'justify' }) }"
          @click="editor.chain().focus().setTextAlign('justify').run()"
        >
          <i class="fa fa-align-justify" />
        </button>
      </el-tooltip>

      <el-tooltip content="Lien hypertexte" :open-delay="1000" placement="top">
        <button
          class="menubar__button"
          :class="{ 'is-active': editor.isActive('link') }"
          @click="setLink"
        >
          <i class="fa fa-link" />
        </button>
      </el-tooltip>

      <el-tooltip content="Indice" :open-delay="1000" placement="top">
        <button
          class="menubar__button"
          :class="{ 'is-active': editor.isActive('subscript') }"
          @click="editor.chain().focus().toggleSubscript().run()"
        >
          <i class="fa fa-subscript" />
        </button>
      </el-tooltip>

      <el-tooltip content="Exposant" :open-delay="1000" placement="top">
        <button
          class="menubar__button"
          :class="{ 'is-active': editor.isActive('superscript') }"
          @click="editor.chain().focus().toggleSuperscript().run()"
        >
          <i class="fa fa-superscript" />
        </button>
      </el-tooltip>
    </div>
    <editor-content class="editor-content" :editor="editor" />
  </div>
</template>

<script>
import { ElTooltip } from 'element-plus';
import { Editor, EditorContent } from '@tiptap/vue-3';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import Underline from '@tiptap/extension-underline';
import { Color } from '@tiptap/extension-color';
import TextStyle from '@tiptap/extension-text-style';
import TextAlign from '@tiptap/extension-text-align';
import Link from '@tiptap/extension-link';
import Superscript from '@tiptap/extension-superscript';
import Subscript from '@tiptap/extension-subscript';

export default {
  components: {
    ElTooltip,
    EditorContent,
  },
  props: {
    modelValue: {
      type: String,
    },
  },
  data() {
    return {
      editor: null,
    };
  },
  watch: {
    modelValue(value) {
      // HTML
      const isSame = this.editor.getHTML() === value;

      if (isSame) {
        return;
      }

      this.editor.commands.setContent(value, false);
    },
  },
  mounted() {
    this.editor = new Editor({
      content: this.modelValue,
      extensions: [
        StarterKit,
        Placeholder.configure({
          placeholder: 'Insérez du contenu...',
        }),
        Underline,
        Color,
        TextStyle,
        TextAlign.configure({
          types: ['paragraph'],
        }),
        Link.configure({
          openOnClick: false,
        }),
        Superscript,
        Subscript,
      ],
      onUpdate: () => {
        const json = this.editor.getJSON().content;
        let content = this.editor.getHTML();

        // Remove the empty <p> tags when the user erased all the text (in order to be able to properly validate the input).
        // eslint-disable-next-line no-prototype-builtins
        if (Array.isArray(json) && json.length === 1 && !json[0].hasOwnProperty('content')) {
          content = null;
        }

        this.$emit('update:modelValue', content);
      },
    });
  },
  beforeUnmount() {
    this.editor.destroy();
  },
  computed: {
    selectedTextColor() {
      return this.editor.getAttributes('textStyle').color
        ? this.editor.getAttributes('textStyle').color
        : '#000000';
    },
  },
  methods: {
    setLink() {
      const previousUrl = this.editor.getAttributes('link').href;
      // eslint-disable-next-line no-alert
      const url = window.prompt('URL', previousUrl);

      // cancelled
      if (url === null) {
        return;
      }

      // empty
      if (url === '') {
        this.editor.chain().focus().extendMarkRange('link').unsetLink()
          .run();

        return;
      }

      // update link
      this.editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url })
        .run();
    },
  },
};
</script>

<style lang="scss">
$color-black: #000000;
$color-light-grey: #f1f1f1;

.input-color {
  display: none;
}

/* Placeholder (at the top) */
.ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}

/* Menu bar */
.menubar {
  transition: visibility 0.2s 0.4s, opacity 0.2s 0.4s;

  background-color: $color-light-grey;
  border-top: 1px solid #dadada;
  border-left: 1px solid #dadada;
  border-radius: 4px 4px 0 0;

  button.glyphicon-button:focus,
  button.glyphicon-button:active:focus,
  button.glyphicon-button.active:focus,
  button.glyphicon-button.focus,
  button.glyphicon-button:active.focus,
  button.glyphicon-button.active.focus {
    outline: none !important;
  }

  .table-tools {
    i {
      color: blue;
    }
  }

  &.is-hidden {
    visibility: hidden;
    opacity: 0;
  }

  &.is-focused {
    visibility: visible;
    opacity: 1;
    transition: visibility 0.2s, opacity 0.2s;
  }

  &__button {
    font-weight: bold;
    display: inline-flex;
    background: transparent;
    border: 0;
    color: $color-black;
    border-radius: 50%;
    cursor: pointer;
    padding: 6px;
    margin: 2px;
    text-align: center;

    i {
      font-size: 15px;
    }

    &:hover:not(:disabled) {
      background-color: rgba($color-black, 0.05);
    }

    &.is-active {
      background-color: rgba($color-black, 0.1);
    }

    &:disabled {
      color: #c3c3c3;
      cursor: no-drop;
    }
  }

  span#{&}__button {
    font-size: 13.3333px;
  }
}

/* Content */
.editor-content {
  border: 1px solid #dadada;
  border-radius: 0 0 4px 4px;
  padding: 10px;

  &.disabled {
    background-color: #d3d3d3;
    border-radius: 4px;
    color: #66615b;
    cursor: no-drop;

    th {
      background-color: #c3c3c3;
    }
  }

  p:last-of-type {
    margin-bottom: 0;
  }
}

.ProseMirror {
  a {
    color: #06c;
    text-decoration: underline;
  }
}
</style>
