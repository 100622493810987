<template>
  <div>
    <documents-crud-list title="Mobilité" category="Mobilité" />
  </div>
</template>

<script>
import DocumentsCrudList from './UIComponents/DocumentsCrudList.vue';

export default {
  components: {
    DocumentsCrudList,
  },
};
</script>
