<template>
  <documents-crud-list
    title="Statuts - règlements institutionnels"
    category="Statuts et règlements"
  />
</template>

<script>
import DocumentsCrudList from './UIComponents/DocumentsCrudList.vue';

export default {
  components: {
    DocumentsCrudList,
  },
};
</script>
