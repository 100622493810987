<template>
  <div>
    <h1>Modifier les contacts</h1>
    <el-button type="success" class="btn-save" @click="() => save()"
      >Sauvegarder
    </el-button>

    <draggable
      v-model="contacts"
      group="contacts"
      handle=".handle-coord"
      item-key="id"
      :sort="true"
      @end="updateItemOrder"
    >
      <template #item="{ element, index }">
        <div>
          <div class="contact-form-container">
            <contact-form
              class="contact-form"
              :contact="element"
              @update:modelValue="(value) => contactChange(value, index)"
              @delete="() => deleteContact(index)"
            />
            <i class="fa fa-arrows handle-coord"></i>
          </div>
          <el-divider />
        </div>
      </template>
    </draggable>
    <el-button type="primary" :icon="ElIconPlus" @click="addNewLine"
      >Ajouter nouveau contact
    </el-button>
  </div>
</template>

<script>
import { Plus as ElIconPlus } from '@element-plus/icons-vue';
import { ElButton, ElDivider } from 'element-plus';
import draggable from 'vuedraggable';
import ContactForm from '@/components/ApplicationViews/UIComponents/ContactForm.vue';

export default {
  data() {
    return {
      contacts: [],
      ElIconPlus,
      myArray: [
        {
          id: 1,
          name: 'Test 1',
        },
        {
          id: 2,
          name: 'Test 2',
        },
      ],
    };
  },
  components: {
    [ElButton.name]: ElButton,
    [ElDivider.name]: ElDivider,
    ContactForm,
    draggable,
  },
  mounted() {
    this.fetchContacts();
  },
  methods: {
    fetchContacts() {
      this.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/contacts/search/findAll?projection=contactDetails`,
        )
        .then((payload) => {
          this.contacts = this.formatContact(payload.data._embedded.contacts);
          if (this.contacts.length === 0) {
            this.addNewLine();
          }
        })
        .catch((error) => {});
    },
    formatContact(contacts) {
      return contacts.map((contact) => ({
        ...contact,
        coordinates: contact.coordinates.sort(
          (a, b) => a.position - b.position,
        ),
        isNew: false,
      }));
    },
    save() {
      const editPromises = this.contacts.map((contact) => (contact.isNew ? this.addContact(contact) : this.editContact(contact)));
      Promise.all(editPromises)
        .then(() => {
          this.$notify({
            title: 'Sauvegarde',
            text: 'Les contacts ont été sauvegardés avec succès',
            type: 'success',
          });
        })
        .catch((error) => {
          this.$notify({
            title: 'Erreur',
            text: "Une erreur est survenue lors de l'ajout d'un contact, veuillez réessayer ou contacter le support.",
            type: 'danger',
          });
        });
    },
    addContact(contact) {
      return this.$http
        .post(
          `${process.env.VUE_APP_INTRANET_API_URL}/commands/contacts/`,
          contact,
        )
        .then(() => {
          this.fetchContacts();
        });
    },
    editContact(contact) {
      return this.$http
        .patch(
          `${process.env.VUE_APP_INTRANET_API_URL}/commands/contacts/${contact.id}`,
          contact,
        )
        .then(() => {
          this.fetchContacts();
        });
    },
    deleteContact(index) {
      const contactToDelete = this.contacts[index];
      this.contacts.splice(index, 1);

      if (contactToDelete.isNew) {
        this.$notify({
          title: 'Suppression',
          text: 'Contact supprimé',
          type: 'success',
        });
      } else {
        this.$http
          .delete(
            `${process.env.VUE_APP_INTRANET_API_URL}/commands/contacts/${contactToDelete.id}`,
          )
          .then(() => {
            this.$notify({
              title: 'Suppression',
              text: 'Contact supprimé',
              type: 'success',
            });
          })
          .catch((error) => {
            this.$notify({
              title: 'Erreur',
              text: 'Une erreur est survenue lors de la suppression, veuillez ressayer ou contacter le support.',
              type: 'success',
            });
          });
      }
    },
    contactChange(value, index) {
      this.contacts[index] = value;
    },
    addNewLine() {
      const emptyContact = {
        name: '',
        isNew: true,
        position: this.contacts.length,
        coordinates: [
          {
            type: null,
            content: '',
            position: 0,
          },
        ],
      };
      this.contacts.push(emptyContact);
    },
    updateItemOrder() {
      this.contacts = this.contacts.map((contact, index) => ({
        ...contact,
        position: index,
      }));
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-save {
  margin-bottom: 20px;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.contact-form-container {
  display: flex;
  justify-content: space-between;
}

.contact-form {
  width: 97%;
}
</style>
