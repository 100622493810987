<template>
  <div>
    <details-modal
      :show="show"
      current-operation="create"
      :loading="loading"
      @close="closeModal"
      @cancel="closeModal"
      @create="createRecord"
    >
      <Form :validation-schema="validationSchema" validate-on-mount>
        <label class="control-label">*Images</label>
        <p>
          <input
            id="images"
            name="images"
            type="file"
            accept="image/png, image/jpeg"
            multiple
            @change="processImages($event)"
          /><br />
          <Field
            name="images"
            v-model="galleryItem.images"
            v-slot="{ errorMessage }"
          >
            <small v-show="errorMessage" class="text-danger">
              Veuillez sélectionner une ou plusieurs images.
            </small>
          </Field>
        </p>
        <div class="form-group">
          <label class="control-label">*Titre</label>
          <Field
            name="title"
            v-model="galleryItem.title"
            v-slot="{ field, errorMessage }"
          >
            <input v-bind="field" class="form-control" type="text" />
            <small v-show="errorMessage" class="text-danger">
              Le titre est requis.
            </small>
          </Field>
        </div>
        <p>
          <label for="date">*Date</label>
          <el-date-picker
            v-model="galleryItem.date"
            id="date"
            name="date"
            type="date"
            class="gallery-item-date"
            placeholder="Choisissez un jour"
          />
          <Field
            name="date"
            v-model="galleryItem.date"
            v-slot="{ errorMessage }"
          >
            <small v-show="errorMessage" class="text-danger">
              La date est requise.
            </small>
          </Field>
        </p>
        <p><label>* champs obligatoires</label></p>
      </Form>
      <p v-if="errorMessage" class="error-message-in-modal">
        {{ errorMessage }}
      </p>
    </details-modal>
  </div>
</template>

<script>
import { ElDatePicker as DatePicker } from 'element-plus';
import { Form, Field } from 'vee-validate';
import * as yup from 'yup';
import FormData from 'form-data';
import DetailsModal from './DetailsModal.vue';

export default {
  components: {
    DetailsModal,
    [DatePicker.name]: DatePicker,
    Form,
    Field,
  },
  props: ['show'],
  data() {
    return {
      errorMessage: '',
      galleryItem: {},
      validationSchema: {
        images: yup.array().required().min(1),
        title: yup.string().required(),
        date: yup.string().required(),
      },
      loading: false,
    };
  },
  methods: {
    async createRecord() {
      // Validate each field with vee-validate, and post the new gallery item.
      const validationSchemaObject = yup.object(this.validationSchema);
      const isGalleryItemValid = await validationSchemaObject.isValid(
        this.galleryItem,
      );

      if (isGalleryItemValid) {
        this.errorMessage = null;

        const formData = new FormData();

        // Add each image in order to form a server-interpretable array of images.
        this.galleryItem.images.forEach((image) => {
          formData.append('images', image);
        });

        // Add the properties as application/json content type in order to unserialize them in the server.
        formData.append(
          'properties',
          new Blob(
            [
              JSON.stringify({
                title: this.galleryItem.title,
                date: this.galleryItem.date,
              }),
            ],
            {
              type: 'application/json',
            },
          ),
        );

        this.loading = true;

        this.$http
          .post(
            `${process.env.VUE_APP_INTRANET_API_URL}/commands/galleryItems/`,
            formData,
          )
          .then((response) => {
            this.closeModal();

            // Wait for the DOM to properly update (which means we are waiting for the modal to close) before redirecting the user to the new-created
            // gallery item's page. We have to process this way in order to prevent the scrollbar to disapear after the creation.
            this.$nextTick(() => {
              const newGalleryItemId = response.headers.location.substr(
                response.headers.location.lastIndexOf('/') + 1,
              );
              this.$router.push({
                name: 'GalleryItem',
                params: { id: newGalleryItemId },
              });
            });
          })
          .catch((error) => {
            this.errorMessage = "Une erreur est survenue lors de l'ajout de l'élément, veuillez réessayer ou contacter le webmaster.";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.errorMessage = 'Un ou plusieurs champs sont invalides.';
      }
    },
    closeModal() {
      this.$emit('close');
      this.errorMessage = '';
    },
    processImages(event) {
      if (event.target.files[0]) {
        this.galleryItem.images = Array.from(event.target.files);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.gallery-item-date {
  margin-bottom: 0;
}
</style>
