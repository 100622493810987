<template>
  <div>
    <div class="card">
      <div class="card-header">
        <el-row>
          <el-col :span="23">
            <el-row>
              <el-col :lg="8" :md="12" :sm="12" :xs="24">
                <h5 class="title">
                  {{ title }}
                </h5>
              </el-col>
              <el-col :lg="16" :md="12" :sm="12" :xs="24">
                <el-input
                  v-if="showSearch"
                  placeholder="Rechercher"
                  v-model="search"
                  clearable
                >
                </el-input>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="1">
            <button
              v-if="!creationDisabled && isAllowedToCreate"
              id="btn-add"
              type="button"
              class="btn btn-icon btn-round btn-success"
              @click="create"
            >
              <i class="fa fa-plus" />
            </button>
          </el-col>
        </el-row>
      </div>
      <div class="card-body table-responsive table-full-width">
        <el-table
          :data="data"
          :default-sort="defaultSort"
          class="mydatalist"
          @row-click="rowClicked"
          @sort-change="sortChange"
          stripe
          @filter-change="(filters) => $emit('filter-change', filters)"
        >
          <slot />
        </el-table>
        <div v-if="loading" class="crud-list-loader">
          <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 pagination-info" v-if="pagination.total">
        <p class="category">Total: {{ pagination.total }} éléments</p>
      </div>
      <div class="col-sm-6">
        <p-pagination
          v-if="pagination.total > 1"
          v-model="pagination.currentPage"
          :per-page="pageSize || pagination.perPage"
          :total="pagination.total"
          class="pull-right"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { toRaw } from 'vue';
import { ElInput as Input, ElCol as Col, ElRow as Row } from 'element-plus';
import ROLES from '@/services/Roles';
import PPagination from '../../UIComponents/Pagination.vue';

export default {
  components: {
    PPagination,
    [Input.name]: Input,
    [Row.name]: Row,
    [Col.name]: Col,
  },
  props: {
    title: String,
    fetchData: Function,
    creationDisabled: Boolean,
    defaultSort: Object,
    createAllowedUser: {
      type: Array,
      default: () => [ROLES.ADMINISTRATOR, ROLES.POWER_USER],
    },
    hideCreateButton: {
      type: Boolean,
      default: false,
    },
    showSearch: {
      type: Boolean,
      default: false,
    },
    searchValue: {
      type: String,
      default: '',
      required: false,
    },
    pageNumber: {
      type: Number,
      default: null,
      required: false,
    },
    pageSize: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      loading: false,
      data: [],
      pagination: {
        perPage: 30,
        currentPage: 1,
        perPageOptions: [5, 10, 30, 50],
        total: 0,
      },
      search: '',
      typingTimer: null,
    };
  },
  computed: {
    isAllowedToCreate() {
      if (this.hideCreateButton) {
        return false;
      }

      if (!this.createAllowedUser) {
        return false;
      }
      const userRoles = this.$store.getters['account/roles'];
      return (
        userRoles.filter((role) => this.createAllowedUser.includes(toRaw(role)))
          .length > 0
      );
    },
  },
  watch: {
    'pagination.currentPage': {
      handler() {
        this.fetch();
      },
    },
    search: {
      handler(newVal) {
        // Wait for the user to end typing before emitting the search event in order to avoid excessive calls to server.
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(() => {
          this.$emit('search', this.search);
        }, 500);
      },
    },
  },
  created() {
    if (this.pageNumber && this.pageNumber > 1) {
      this.pagination.currentPage = this.pageNumber;
      this.$notify({
        title: 'Pagination',
        text: `Vous êtes sur la page ${this.pagination.currentPage}`,
        type: 'info',
      });
    }
    this.search = this.searchValue;
    this.fetch();
  },
  methods: {
    rowClicked(event) {
      this.$emit('row-clicked', event);
    },
    sortChange(column, prop, order) {
      this.$emit('sort-change', column, prop, order);
    },
    fetch(searchData) {
      this.loading = true;
      this.fetchData(
        this.pagination.currentPage,
        this.pageSize || this.pagination.perPage,
        searchData,
      ).then((response) => {
        this.loading = false;
        this.data = response.records;
        this.pagination.total = response.page.totalElements;
      });
    },
    create() {
      this.$emit('create');
    },
  },
};
</script>

<style lang="scss" scoped>
.mydatalist > tr {
  cursor: pointer;
}

#btn-add {
  position: absolute;
  right: 10px;
  margin: 0px;
}

.lds-ring {
  display: inline-block;
  position: absolute;
  width: 80px;
  height: 80px;
  left: 50%;
  top: 20%;
  transform: translateX(-50%);
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.crud-list-loader {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 12px;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 99999;
}
</style>
