<template>
  <modal
    :show="show"
    footer-classes="justify-content-center"
    type="notice"
    @close="close"
  >
    <template #header>
      <h5 class="modal-title">Détails</h5>
    </template>
    <slot />
    <template #footer>
      <div class="justify-content-center d-flex">
        <p-button
          v-if="currentOperation === 'edit'"
          type="danger"
          round
          @click="$emit('delete')"
          :disabled="loading"
        >
          Effacer
        </p-button>
        <span v-if="currentOperation === 'edit'">&nbsp;</span>
        <p-button
          v-if="currentOperation === 'edit'"
          type="success"
          round
          @click="$emit('update')"
          :disabled="loading"
        >
          Mettre à jour
        </p-button>
        <span v-if="currentOperation === 'edit'">&nbsp;</span>
        <p-button
          v-if="currentOperation === 'create'"
          type="success"
          round
          @click="$emit('create')"
          :disabled="loading"
        >
          Créer
        </p-button>
        <span v-if="currentOperation === 'create'">&nbsp;</span>
        <p-button type="light" round @click="$emit('cancel')">
          Annuler
        </p-button>
      </div>
    </template>
  </modal>
</template>

<script>
import { Modal } from '../../UIComponents';

export default {
  name: 'DetailsModal',
  components: {
    Modal,
  },
  props: ['show', 'currentOperation', 'loading'],
  mounted() {
    window.addEventListener('keyup', (event) => {
      if (event.keyCode === 13 && this.show) {
        if (this.currentOperation === 'create') {
          this.$emit('create');
        } else if (this.currentOperation === 'edit') {
          this.$emit('update');
        }
      }
    });
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>
