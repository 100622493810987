<template>
  <div>
    <div class="tag-container">
      <label for="crud-tag-car">{{ label }}</label>
      <div class="d-flex">
        <el-select
          :disabled="disabled"
          id="crud-tag-car"
          class="select-info select-one-line-per-item crud-tag"
          size="large"
          placeholder="Sélectionner"
          name="recordValue"
          :modelValue="modelValue"
          :multiple="multiple"
          @update:modelValue="$emit('update:modelValue', $event)"
          filterable
          clearable
        >
          <el-option
            v-for="record in records"
            :key="record.id"
            :label="record.value"
            :value="record.id"
          />
        </el-select>
        <button
          v-if="isAdmin"
          class="btn btn-icon btn-round btn-success btn-crud-select"
          type="button"
          title="Ajouter un élément"
          :disabled="isAddDisabled"
          @click="showRecordCreationFunction"
        >
          <i class="fa fa-plus" />
        </button>
      </div>
      <div
        v-if="showRecordCreation"
        class="d-flex flex-wrap crud-select-input-container"
      >
        <div class="d-flex flex-no-wrap crud-select-input-row">
          <input
            v-model="newCar.licensePlate"
            class="form-control"
            type="text"
            name="newCar"
            placeholder="N° de plaque"
          />
          <button
            class="btn btn-icon btn-round btn-danger btn-crud-select"
            type="button"
            title="Annuler"
            @click="hideRecordCreation"
          >
            <i class="fa fa-times" />
          </button>
          <button
            class="btn btn-icon btn-round btn-success btn-crud-select"
            type="button"
            title="Ajouter l'élément"
            @click="createRecordFunction"
          >
            <i class="fa fa-check" />
          </button>
        </div>
      </div>
      <small v-show="required && recordValue.invalid" class="text-danger">
        Le type est requis.
      </small>
      <div v-if="errorMessage" class="error-message">
        {{ errorMessage }}
      </div>
    </div>
  </div>
</template>

<script>
import ROLES from '@/services/Roles';

export default {
  props: [
    'modelValue',
    'disabled',
    'label',
    'multiple',
    'required',
    'setSelectedRecordId',
    'fetchRecords',
    'createRecord',
  ],
  data() {
    return {
      showRecordCreation: false,
      records: [],
      newCar: {
        licensePlate: null,
      },
      errorMessage: null,
      tmp: null,
    };
  },
  computed: {
    isAdmin() {
      return (
        this.$store.getters['account/roles'].includes(ROLES.ADMINISTRATOR)
        || this.$store.getters['account/roles'].includes(ROLES.POWER_USER)
      );
    },
    isAddDisabled() {
      return this.showRecordCreation || this.disabled;
    },
    emptyValue() {
      return this.multiple ? [] : null;
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      if (!this.modelValue) {
        this.setSelectedRecordId(this.emptyValue);
      }

      return this.fetchRecords().then((response) => {
        this.records = response.records;
      });
    },
    showRecordCreationFunction() {
      this.errorMessage = null;
      this.showRecordCreation = true;
    },
    hideRecordCreation() {
      this.errorMessage = null;
      this.showRecordCreation = false;
    },
    createRecordFunction() {
      this.errorMessage = null;

      if (this.newCar) {
        const selectionBeforeFetch = this.modelValue;

        return this.createRecord(this.newCar)
          .then(() => {
            this.fetch().then(() => {
              // Select the new-created element in the elements list.
              const newInsertedId = this.records.filter(
                (r) => r.value === this.newCar.licensePlate,
              )[0].id;
              this.setSelectedRecordId(
                this.multiple
                  ? selectionBeforeFetch.concat([newInsertedId])
                  : newInsertedId,
              );
              this.hideRecordCreation();
              this.newCar = null;
            });
          })
          .catch((error) => {
            this.errorMessage = error.response.data.message;
          });
      }
      this.errorMessage = "Veuillez entrer une valeur pour l'élément.";
      return null;
    },
  },
};
</script>
