<template>
  <div>
    <modal
      :show="show"
      footer-classes="justify-content-center"
      type="notice"
      @close="closeModal"
    >
      <template #header>
        <h5 class="modal-title">Choisissez la fonction à exporter</h5>
      </template>
      <form>
        <div class="form-group">
          <label class="control-label">Fonctions</label>
          <el-radio-group v-model="functionChoose">
            <div v-for="collabFunction in functions" :key="collabFunction.id">
              <el-radio :label="collabFunction.id" class="span-function"
                >{{ `${collabFunction.value}` }}
              </el-radio>
            </div>
          </el-radio-group>
        </div>
      </form>
      <p v-if="errorMessage" class="error-message-in-modal">
        {{ errorMessage }}
      </p>
      <template #footer>
        <div class="justify-content-center d-flex">
          <p-button type="success" round @click="choose">
            Sélectionner
          </p-button>
          <span>&nbsp;</span>
          <p-button type="light" round @click="$emit('close')">
            Annuler
          </p-button>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import { ElRadioGroup as RadioGroup, ElRadio as Radio } from 'element-plus';
import { Modal } from '../../UIComponents';

export default {
  components: {
    Modal,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
  },
  props: ['show', 'fonction'],
  data() {
    return {
      functions: [],
      functionChoose: null,
      errorMessage: '',
      modelValidations: {
        name: {
          required: true,
        },
        capacity: {
          required: true,
        },
      },
    };
  },
  watch: {
    show: {
      handler(newVal) {
        if (newVal) {
          this.fetchFunctions().then((response) => {
            this.functions = response.records;
          });
        }
      },
    },
  },
  methods: {
    fetchFunctions() {
      return this.$http
        .get(
          `${process.env.VUE_APP_INTRANET_API_URL}/functions/search/findAllOrderByName`,
        )
        .then((response) => ({
          records: response.data._embedded.functions
            ? response.data._embedded.functions.map((type) => ({
              id: type.id,
              value: type.name,
            }))
            : [],
        }))
        .catch((error) => {
          this.$notify({
            title: 'Error',
            text: 'Une erreur est survenue lors de la récupération des données des fonctions, veuillez réessayer ou contacter le webmaster.',
            type: 'danger',
          });
        });
    },
    choose() {
      if (this.functionChoose) {
        this.$emit('choose', this.functionChoose);
        this.closeModal();
      } else {
        this.errorMessage = 'Veuillez choisir une function ou annuler';
      }
    },
    closeModal() {
      this.$emit('close');
      this.errorMessage = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.span-function {
  max-width: 275px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (min-width: 576px) {
  .span-function {
    max-width: 400px;
  }
}
</style>
