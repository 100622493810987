<template>
  <dashboard-content />
</template>

<script>
import DashboardContent from './Content.vue';

export default {
  components: {
    DashboardContent,
  },
};
</script>

<style lang="scss"></style>
